import axios from "axios";
import { analyticsUrl } from "../../config/settings";
import {
  addScreens,
  addScreensId,
  addBaby,
  addClickId,
  addHomeClickId,
  addAbTests,
  addPopups,
} from "../../components/analytics/helper";

// USERS
export const getUserAll = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_USER_ALL_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/user/all/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          dispatch({ type: "GET_USER_ALL", data: todos });
          clearInterval(interval);
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const getUserWeeks = () => (dispatch) => {
  dispatch({ type: "GET_USER_WEEKS_LOADING" });
  axios
    .get(
      analyticsUrl + "/user/weeks",

      {
        headers: {
          "Cache-Control": "no-cache",
          Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
        },
      }
    )
    .then((response) => {
      dispatch({ type: "GET_USER_WEEKS", data: response.data });
    })
    .catch(function (error) {
      dispatch({ type: "ERROR", err: error });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
      return [];
    });
};

// CONTENT / SCREENS
export const setLoadingFalse = () => (dispatch) => {
  dispatch({ type: "SET_LOADING_FALSE" });
};

export const getScreenMain = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_SCREEN_MAIN_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/screen/main/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addScreens(todos).then((item) => {
            dispatch({ type: "GET_SCREEN_MAIN", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const getScreenHome = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_SCREEN_HOME_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/screen/home/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addBaby(todos).then((item) => {
            dispatch({ type: "GET_SCREEN_HOME", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const getScreenTools = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_SCREEN_TOOLS_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/screen/tool/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addScreens(todos).then((item) => {
            dispatch({ type: "GET_SCREEN_TOOLS", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const getScreenTodo = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_SCREEN_TODO_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/screen/todo/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addScreensId(todos).then((item) => {
            dispatch({ type: "GET_SCREEN_TODO", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const getScreenTodoItem = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_SCREEN_TODO_ITEM_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/screen/todoitem/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addScreensId(todos).then((item) => {
            dispatch({ type: "GET_SCREEN_TODO_ITEM", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const getScreenKnowledge = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_SCREEN_KNOWLEDGE_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/screen/knowledge/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addScreensId(todos).then((item) => {
            dispatch({ type: "GET_SCREEN_KNOWLEDGE", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const getScreenArticle = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_SCREEN_ARTICLE_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/screen/knowledge/article/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
          // categoryId
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addScreensId(todos).then((item) => {
            dispatch({ type: "GET_SCREEN_ARTICLE", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

// CONTENT / CLICKS
export const setClickLoadingFalse = () => (dispatch) => {
  dispatch({ type: "SET_CLICK_LOADING_FALSE" });
};

export const getClickCoupons = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_CLICK_COUPON_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/click/coupon/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addClickId(todos).then((item) => {
            dispatch({ type: "GET_CLICK_COUPON", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const getClickTodos = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_CLICK_TODO_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/click/todo/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addClickId(todos).then((item) => {
            dispatch({ type: "GET_CLICK_TODO", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const getClickHome = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_CLICK_HOME_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/click/home/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addHomeClickId(todos).then((item) => {
            dispatch({ type: "GET_CLICK_HOME", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const getClickKnowledge = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_CLICK_KNOWLEDGE_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/click/knowledge/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addClickId(todos).then((item) => {
            dispatch({ type: "GET_CLICK_KNOWLEDGE", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const getClickArticle = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_CLICK_ARTICLE_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/click/knowledge/article/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addClickId(todos).then((item) => {
            dispatch({ type: "GET_CLICK_ARTICLE", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const getClickPhotographer = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_CLICK_PHOTOGRAPHER_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/click/photographer/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addClickId(todos).then((item) => {
            dispatch({ type: "GET_CLICK_PHOTOGRAPHER", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const getClickRecipe = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_CLICK_RECIPE_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/click/recipe/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addClickId(todos).then((item) => {
            dispatch({ type: "GET_CLICK_RECIPE", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

// CONTENT / ABTEST
export const GetAbTests = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_ABTESTS_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/abtest/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          addAbTests(todos).then((item) => {
            dispatch({ type: "GET_ABTESTS", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

// CONTENT / POPUP
export const GetPopupAnalytics = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "GET_POPUP_ANALYTICS_LOADING" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
      .post(
        analyticsUrl + "/content/popup/query",
        {
          from: from,
          to: to + 1,
          limit: limit,
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          dispatch({
            type: "GET_POPUP_DATE_ANALYTICS",
            data: JSON.stringify(todos),
          });
          const screens = [...todos];
          addPopups(screens).then((item) => {
            dispatch({ type: "GET_POPUP_ANALYTICS", data: item });
            clearInterval(interval);
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

// COMPANY
export const getCompanyAnalytics =
  (companyname, startDate, endDate) => async (dispatch) => {
    dispatch({ type: "GET_COMPANY_ANALYTICS_LOADING" });
    await axios
      .post(
        analyticsUrl + "/company/coupon/query",
        {
          from: startDate,
          to: endDate + 1,
          companyname: companyname,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        addClickId(response.data).then((item) => {
          const coupon = item;

          axios
            .post(
              analyticsUrl + "/company/todo/query",
              {
                from: startDate,
                to: endDate + 1,
                companyname: companyname,
              },
              {
                headers: {
                  "Cache-Control": "no-cache",
                  Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
                },
              }
            )
            .then((response) => {
              addClickId(response.data).then((item) => {
                const todo = item;

                axios
                  .post(
                    analyticsUrl + "/company/home/query",
                    {
                      from: startDate,
                      to: endDate + 1,
                      companyname: companyname,
                    },
                    {
                      headers: {
                        "Cache-Control": "no-cache",
                        Authorization:
                          "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
                      },
                    }
                  )
                  .then((response) => {
                    addClickId(response.data).then((item) => {
                      const home = item;

                      axios
                        .post(
                          analyticsUrl + "/company/knowledge/query",
                          {
                            from: startDate,
                            to: endDate + 1,
                            companyname: companyname,
                          },
                          {
                            headers: {
                              "Cache-Control": "no-cache",
                              Authorization:
                                "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
                            },
                          }
                        )
                        .then((response) => {
                          addClickId(response.data).then((item) => {
                            const knowledge = item;

                            axios
                              .post(
                                analyticsUrl + "/company/article/query",
                                {
                                  from: startDate,
                                  to: endDate + 1,
                                  companyname: companyname,
                                },
                                {
                                  headers: {
                                    "Cache-Control": "no-cache",
                                    Authorization:
                                      "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
                                  },
                                }
                              )
                              .then((response) => {
                                addClickId(response.data).then((item) => {
                                  const article = item;

                                  const company = {
                                    coupons: coupon,
                                    todo: todo,
                                    home: home,
                                    knowledge: knowledge,
                                    article: article,
                                  };
                                  axios
                                    .post(
                                      analyticsUrl +
                                        "/content/screen/main/query",
                                      {
                                        from: startDate,
                                        to: endDate + 1,
                                        limit: 1000,
                                        skip: 0,
                                      },
                                      {
                                        headers: {
                                          "Cache-Control": "no-cache",
                                          Authorization:
                                            "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
                                        },
                                      }
                                    )
                                    .then((response) => {
                                      addScreens(response.data).then((item) => {
                                        company.impmain = item;

                                        axios
                                          .post(
                                            analyticsUrl +
                                              "/content/screen/todo/query",
                                            {
                                              from: startDate,
                                              to: endDate + 1,
                                              limit: 1000,
                                              skip: 0,
                                            },
                                            {
                                              headers: {
                                                "Cache-Control": "no-cache",
                                                Authorization:
                                                  "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
                                              },
                                            }
                                          )
                                          .then((response) => {
                                            addScreensId(response.data).then(
                                              (item) => {
                                                company.imptodo = item;

                                                axios
                                                  .post(
                                                    analyticsUrl +
                                                      "/content/screen/knowledge/query",
                                                    {
                                                      from: startDate,
                                                      to: endDate + 1,
                                                      limit: 1000,
                                                      skip: 0,
                                                    },
                                                    {
                                                      headers: {
                                                        "Cache-Control":
                                                          "no-cache",
                                                        Authorization:
                                                          "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
                                                      },
                                                    }
                                                  )
                                                  .then((response) => {
                                                    addScreensId(
                                                      response.data
                                                    ).then((item) => {
                                                      company.impknowledge =
                                                        item;

                                                      axios
                                                        .post(
                                                          analyticsUrl +
                                                            "/content/screen/knowledge/article/query",
                                                          {
                                                            from: startDate,
                                                            to: endDate + 1,
                                                            limit: 1000,
                                                            skip: 0,
                                                          },
                                                          {
                                                            headers: {
                                                              "Cache-Control":
                                                                "no-cache",
                                                              Authorization:
                                                                "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
                                                            },
                                                          }
                                                        )
                                                        .then((response) => {
                                                          addScreensId(
                                                            response.data
                                                          ).then((item) => {
                                                            company.imparticle =
                                                              item;

                                                            axios
                                                              .post(
                                                                analyticsUrl +
                                                                  "/content/screen/home/query",
                                                                {
                                                                  from: startDate,
                                                                  to:
                                                                    endDate + 1,
                                                                  limit: 1000,
                                                                  skip: 0,
                                                                },
                                                                {
                                                                  headers: {
                                                                    "Cache-Control":
                                                                      "no-cache",
                                                                    Authorization:
                                                                      "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
                                                                  },
                                                                }
                                                              )
                                                              .then(
                                                                (response) => {
                                                                  addBaby(
                                                                    response.data
                                                                  ).then(
                                                                    (item) => {
                                                                      company.imphome =
                                                                        item;
                                                                      const finalCompany =
                                                                        {};
                                                                      finalCompany.coupons =
                                                                        company.coupons.screens.forEach(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            (item.impression =
                                                                              company.impmain.screens[0].pressed)
                                                                        );

                                                                      finalCompany.coupons =
                                                                        addImpressionToCoupons(
                                                                          company
                                                                        );
                                                                      finalCompany.todo =
                                                                        addImpressionToTodo(
                                                                          company
                                                                        );
                                                                      finalCompany.home =
                                                                        addImpressionToHome(
                                                                          company
                                                                        );
                                                                      finalCompany.knowledge =
                                                                        addImpressionToKnowledge(
                                                                          company
                                                                        );
                                                                      finalCompany.article =
                                                                        addImpressionToArticle(
                                                                          company
                                                                        );
                                                                      console.log(
                                                                        finalCompany
                                                                      );
                                                                      dispatch({
                                                                        type: "GET_COMPANY_ANALYTICS",
                                                                        data: finalCompany,
                                                                      });
                                                                    }
                                                                  );
                                                                }
                                                              )
                                                              .catch(function (
                                                                error
                                                              ) {
                                                                return error;
                                                              });
                                                          });
                                                        })
                                                        .catch(function (
                                                          error
                                                        ) {
                                                          return error;
                                                        });
                                                    });
                                                  })
                                                  .catch(function (error) {
                                                    return error;
                                                  });
                                              }
                                            );
                                          })
                                          .catch(function (error) {
                                            return error;
                                          });
                                      });
                                    })
                                    .catch(function (error) {
                                      return error;
                                    });
                                });
                              })
                              .catch(function (error) {
                                return error;
                              });
                          });
                        })
                        .catch(function (error) {
                          return error;
                        });
                    });
                  })
                  .catch(function (error) {
                    return error;
                  });
              });
            })
            .catch(function (error) {
              return error;
            });
        });
      })
      .catch(function (error) {
        return error;
      });
  };

const addImpressionToCoupons = (company) => {
  const shouldBeReturned = [];
  shouldBeReturned.push(...company.coupons.screens);
  company.impmain.screens.forEach((item) => {
    if (item.screen === "coupon") {
      shouldBeReturned.forEach((elem) => {
        elem.impression = item.pressed;
      });
    }
  });
  return shouldBeReturned;
};

const addImpressionToTodo = (company) => {
  const shouldBeReturned = [];
  shouldBeReturned.push(...company.todo.screens);

  shouldBeReturned.forEach((elem) => {
    company.imptodo.screens.forEach((item) => {
      if (elem.categoryId === item.screenId) {
        elem.impression = item.pressed;
      }
    });
  });

  return shouldBeReturned;
};

const addImpressionToHome = (company) => {
  const shouldBeReturned = [];
  shouldBeReturned.push(...company.home.screens);
  shouldBeReturned.forEach((elem) => {
    company.imphome.screens.forEach((item) => {
      if (elem.week === item.week && elem.type === item.type) {
        elem.impression = item.pressed;
      }
    });
  });

  return shouldBeReturned;
};

const addImpressionToKnowledge = (company) => {
  const shouldBeReturned = [];
  shouldBeReturned.push(...company.knowledge.screens);

  shouldBeReturned.forEach((elem) => {
    company.impknowledge.screens.forEach((item) => {
      if (elem.screenId === item.screenId) {
        elem.impression = item.pressed;
      }
    });
  });

  return shouldBeReturned;
};

const addImpressionToArticle = (company) => {
  const shouldBeReturned = [];
  shouldBeReturned.push(...company.article.screens);

  shouldBeReturned.forEach((elem) => {
    company.imparticle.screens.forEach((item) => {
      if (elem.screenId === item.screenId) {
        elem.impression = item.pressed;
      }
    });
  });

  return shouldBeReturned;
};

export const getCompanyList = () => (dispatch) => {
  dispatch({ type: "GET_COMPANY_LIST_LOADING" });
  axios
    .get(
      analyticsUrl + "/company/list",

      {
        headers: {
          "Cache-Control": "no-cache",
          Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
        },
      }
    )
    .then((response) => {
      const data = response.data.map((item) => {
        return { label: item.company, value: item.company };
      });
      dispatch({ type: "GET_COMPANY_LIST", data: data });
    })
    .catch(function (error) {
      dispatch({ type: "ERROR", err: error });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
      return [];
    });
};

export const createNewCompany = (company) => (dispatch) => {
  dispatch({ type: "CREATE_COMPANY_LIST_LOADING" });
  axios
    .post(
      analyticsUrl + "/company/list",
      {
        company: company,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
        },
      }
    )
    .then((response) => {
      alert("Created new company");
      dispatch(getCompanyList());
    })
    .catch(function (error) {
      dispatch({ type: "ERROR", err: error });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
      return [];
    });
};

export const setCompanyLoadedFalse = () => (dispatch) => {
  dispatch({ type: "COMPANY_LOADED_FALSE" });
};
