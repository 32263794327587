/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getScreenMain,
  setLoadingFalse,
} from "../../../redux/actions/analytics";
import { formatDate } from "../helper";
import HomeScreen from "./HomeScreen";
import ToolScreen from "./ToolScreen";
import TodoScreen from "./TodoScreen";
import KnowledgeScreen from "./KnowledgeScreen";
import DateSelector from "../../DateSelector";
import Dropdown from "../../Dropdown";
import Spinner from "../../Spinner";

const ScreenAnalytics = () => {
  const dispatch = useDispatch();
  const mainloading = useSelector(
    (state) => state.analytics.screenmain_loading
  );
  const main = useSelector((state) => state.analytics.screenmain);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedScreen, setSelectedScreen] = useState("");

  const mainscreenoptions = [
    { value: "", label: "All" },
    { value: "home", label: "Home" },
    { value: "todo", label: "ToDo" },
    { value: "tools", label: "Tools" },
    { value: "knowledge", label: "Knowledge" },
  ];

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && selectedScreen === "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getScreenMain(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, [startDate, endDate]);

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />
      <div className="w-1/2">
        <Dropdown
          value={selectedScreen}
          options={mainscreenoptions}
          onChange={(value) => {
            setSelectedScreen(value.value);
            dispatch(setLoadingFalse());
          }}
        />
      </div>
      {selectedScreen === "" && (
        <div>
          {mainloading && <Spinner />}
          {!mainloading && startDate !== "" && main.length !== 0 && (
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Screen</th>
                    <th>View</th>
                    <th>Percentage (view / all view)</th>
                    <th>Uniq</th>
                  </tr>
                </thead>
                <tbody>
                  {main.screens.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.screen}</td>
                        <td>{item.pressed}</td>
                        <td>{item.percentage}</td>
                        <td>{item.userid.length}</td>
                      </tr>
                    );
                  })}
                  <tr className="font-bold">
                    <td>SUM</td>
                    <td>{main.total}</td>
                    <td />
                    <td>{main.uniq}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
      {selectedScreen === "home" && (
        <HomeScreen startDate={startDate} endDate={endDate} />
      )}
      {selectedScreen === "tools" && (
        <ToolScreen startDate={startDate} endDate={endDate} />
      )}
      {selectedScreen === "todo" && (
        <TodoScreen startDate={startDate} endDate={endDate} />
      )}
      {selectedScreen === "knowledge" && (
        <KnowledgeScreen startDate={startDate} endDate={endDate} />
      )}
    </div>
  );
};

export default ScreenAnalytics;
