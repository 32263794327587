export const initialState = {
  pregUsers: [],
  allRealPeople: [],
  activity: [],
  deals: [],
  deals_sum: [],
  deals_seven: [],
  deals_thirty: [],
  screens: [],
  screens_sum: [],
  screens_seven: [],
  screens_thirty: [],
  screens_todo: [],
  screens_todo_sum: [],
  screens_todo_seven: [],
  screens_todo_thirty: [],
  screens_know: [],
  screens_know_sum: [],
  screens_know_seven: [],
  screens_know_thirty: [],
  articles: [],
  articles_loading: false,
  analyticsusers: [],
  analyticsuserweeks: [],
  links: [],
  abtests: [],
  loading: false,
  analyticspopups: [],
  analyticsscrolls: [],
};

const analytics = (state = initialState, action) => {
  switch (action.type) {
    case "OLD_SET_LOADING_TRUE":
      return {
        ...state,
        loading: true,
      };
    case "OLD_SET_LOADING_FALSE":
      return {
        ...state,
        loading: false,
      };
    case "OLD_GET_PREG_USERS_ANALYTICS":
      return {
        ...state,
        pregUsers: action.data,
        allRealPeople: action.real,
      };
    case "OLD_GET_ANALYTICS_ACTIVE":
      return {
        ...state,
        activity: action.data,
      };
    case "OLD_GET_USER_COUNT":
      return {
        ...state,
        analyticsusers: action.data,
      };
    case "OLD_GET_USER_WEEK_COUNT":
      return {
        ...state,
        analyticsuserweeks: action.data,
      };

    // DEALS
    case "OLD_GET_ANALYTICS_DEALS":
      return {
        ...state,
        deals: action.data,
      };
    case "OLD_GET_ANALYTICS_DEALS_SUM":
      return {
        ...state,
        deals_sum: action.data,
      };
    case "OLD_GET_ANALYTICS_DEALS_SEVEN":
      return {
        ...state,
        deals_seven: action.data,
      };
    case "OLD_GET_ANALYTICS_DEALS_THIRTY":
      return {
        ...state,
        deals_thirty: action.data,
      };

    // SCREENS
    case "OLD_GET_ANALYTICS_SCREENS":
      return {
        ...state,
        screens: action.data,
      };
    case "OLD_GET_ANALYTICS_SCREENS_SUM":
      return {
        ...state,
        screens_sum: action.data,
      };
    case "OLD_GET_ANALYTICS_SCREENS_SEVEN":
      return {
        ...state,
        screens_seven: action.data,
      };
    case "OLD_GET_ANALYTICS_SCREENS_THIRTY":
      return {
        ...state,
        screens_thirty: action.data,
      };

    // TODOS
    case "OLD_GET_ANALYTICS_TODO":
      return {
        ...state,
        screens_todo: action.data,
      };
    case "OLD_GET_ANALYTICS_TODO_SUM":
      return {
        ...state,
        screens_todo_sum: action.data,
      };
    case "OLD_GET_ANALYTICS_TODO_SEVEN":
      return {
        ...state,
        screens_todo_seven: action.data,
      };
    case "OLD_GET_ANALYTICS_TODO_THIRTY":
      return {
        ...state,
        screens_todo_thirty: action.data,
      };

    // KNOWLEDGES
    case "OLD_GET_ANALYTICS_KNOW":
      return {
        ...state,
        screens_know: action.data,
      };
    case "OLD_GET_ANALYTICS_KNOW_SUM":
      return {
        ...state,
        screens_know_sum: action.data,
      };
    case "OLD_GET_ANALYTICS_KNOW_SEVEN":
      return {
        ...state,
        screens_know_seven: action.data,
      };
    case "OLD_GET_ANALYTICS_KNOW_THIRTY":
      return {
        ...state,
        screens_know_thirty: action.data,
      };

    // ARTICLES
    case "OLD_GET_ANALYTICS_ARTICLES":
      return {
        ...state,
        articles: action.data,
      };
    case "OLD_SET_ARTICLES_LOADING_TRUE":
      return {
        ...state,
        articles_loading: true,
      };
    case "OLD_SET_ARTICLES_LOADING_FALSE":
      return {
        ...state,
        articles_loading: false,
      };
    // LINKS
    case "OLD_GET_A_LINKS":
      return {
        ...state,
        links: action.data,
      };
    case "OLD_GET_A_LINKS_ERROR":
      return {
        ...state,
        links: [],
      };

    // AB TEST
    case "OLD_GET_AB_TESTS":
      return {
        ...state,
        abtests: action.data,
      };
    case "OLD_GET_AB_TESTS_ERROR":
      return {
        ...state,
        abtests: [],
      };

    // POPUP
    case "OLD_GET_POPUP_ANALYTICS":
      return {
        ...state,
        analyticspopups: action.data,
      };
    case "OLD_GET_POPUP_ANALYTICS_ERROR":
      return {
        ...state,
        analyticspopups: [],
      };

    // SCROLLS
    case "OLD_GET_SCROLLS_ANALYTICS":
      return {
        ...state,
        analyticsscrolls: action.data,
      };
    case "OLD_GET_SCROLLS_ANALYTICS_ERROR":
      return {
        ...state,
        analyticsscrolls: [],
      };
    default:
      return state;
  }
};

export default analytics;
