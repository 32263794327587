import React, { Component } from "react";

export default class ScrollBox extends Component {
  render() {
    return (
      <div
        style={{
          border: this.props.selected ? "2px solid #FCC27B" : "none",
          width: "20vw",
          height: "auto",
          margin: 10,
        }}
        onClick={this.props.onClick}
      >
        <img
          alt="x"
          src={this.props.img}
          style={{ width: "100%", height: "auto" }}
        />
      </div>
    );
  }
}
