/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getScreenTools } from "../../../redux/actions/analytics";
import { formatDate } from "../helper";
import Spinner from "../../Spinner";

const ToolScreen = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const toolloading = useSelector(
    (state) => state.analytics.screentool_loading
  );
  const tool = useSelector((state) => state.analytics.screentool);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getScreenTools(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getScreenTools(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, [startDate, endDate]);

  return (
    <div className="w-full flex flex-col items-center">
      <div>
        {toolloading && <Spinner />}
        {!toolloading && tool.length !== 0 && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Screen</th>
                  <th>View</th>
                  <th>Percentage (view / all view)</th>
                  <th>Uniq</th>
                </tr>
              </thead>
              <tbody>
                {tool.screens.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.screen}</td>
                      <td>{item.pressed}</td>
                      <td>{item.percentage} %</td>
                      <td>{item.userid.length}</td>
                    </tr>
                  );
                })}
                <tr className="font-bold">
                  <td>Total for Tools</td>
                  <td>{tool.total}</td>
                  <td />
                  <td>{tool.uniq}</td>
              </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ToolScreen;
