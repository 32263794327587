import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllData, update, create } from "../../redux/actions/api";
import { openDeleteModal } from "../../redux/actions/state";
import Checkbox from "../../components/Checkbox";
import ImageUp from "../../components/ImageUp";
import FormForm from "../../components/forms/FormForm";
import Layout from "../../components/layout/Layout";
import MenuButton from "../../components/MenuButton";
import NewButton from "../../components/NewButton";
import CustomButton from "../../components/CustomButton";
import TextField from "../../components/TextField";
import HTMLEditor from "../../components/HTMLEditor";
import { sortByVisibilityAndOrder } from "../../utils/sort";

const Knowledge = () => {
  const dispatch = useDispatch();
  const apidata = useSelector((state) => state.api.data);
  const deleteType = useSelector((state) => state.state.deleteType);
  const [data, setData] = useState([]);
  const [type, setType] = useState("");
  const [tab, setTab] = useState("");
  const [week, setWeek] = useState("");
  const [newCreate, setNew] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItem2, setSelectedItem2] = useState({});
  const [htmlContent, setHtmlContent] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getAllData());
    setSelectedItem({});
    setSelectedItem2({});
    setTab(null);
    setWeek(null);
    if (Object.keys(apidata).length > 0) {
      const sortedArray = apidata.knowledge.sort(sortByVisibilityAndOrder());
      setData(sortedArray);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(apidata).length > 0) {
      const sortedArray = apidata.knowledge.sort(sortByVisibilityAndOrder());
      setData(sortedArray);
      setTab(null);
      setWeek(null);
    }
    if (deleteType === "knowledge-category") {
      setWeek(null);
      setNew(false);
      setSelectedItem2({});
      setSelectedItem({});
      setHtmlContent("");
    }
    if (deleteType === "knowledge-article") {
      setWeek(null);
      setNew(false);
      setSelectedItem2({});
      setSelectedItem({});
      setHtmlContent("");
    }
  }, [apidata]);

  const save = () => {
    const selected = selectedItem;
    const url = "/knowledge/" + selectedItem.id;
    selected.summary = htmlContent;
    dispatch(update(selected, url));
    setSelectedItem2({});
    setSelectedItem({});
    setHtmlContent("");
    setData([]);
  };

  const save2 = () => {
    const selected = selectedItem2;
    selected.article = htmlContent;
    const url = "/knowledge/updatedata/" + selectedItem.id;
    dispatch(update(selected, url));
    setSelectedItem2({});
    setSelectedItem({});
    setHtmlContent("");
    setData([]);
  };

  const createCategory = () => {
    const selected = selectedItem;
    selected.id = Date.now().toString();
    selected.summary = htmlContent;
    const url = "/knowledge/";
    dispatch(create(selected, url));
    setNew(false);
    setType("");
    setSelectedItem2({});
    setSelectedItem({});
    setHtmlContent("");
    setData([]);
  };

  const createArticle = () => {
    const selected = selectedItem2;
    selected.id = Date.now().toString();
    selected.article = htmlContent;
    const url = "/knowledge/data/" + selectedItem.id;
    dispatch(update(selected, url));
    setSelectedItem2({});
    setSelectedItem({});
    setHtmlContent("");
    setNew(false);
    setType("");
    setData([]);
  };

  const deleteCategory = () => {
    const selected = selectedItem;
    const url = "/knowledge/" + selectedItem.id;
    const type = "knowledge-category";
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  const deleteArticle = () => {
    const selected = selectedItem2;
    const url = "/knowledge/removedata/" + selectedItem.id;
    const type = "knowledge-article";
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  const renderLinksInArticle = () => {
    return (
      <div>
        {!selectedItem2.links[0] && (
          <div
            className="rounded px-4 py-2 m-2 text-black bg-lightorange w-32"
            onClick={() => {
              var selectedItemNew = { ...selectedItem2 };
              selectedItemNew.links.push({
                companyname: "",
                product: "",
                link: "",
              });
              setSelectedItem2(selectedItemNew);
            }}
          >
            Add link
          </div>
        )}
        {selectedItem2.links[0] && (
          <div>
            <p className="font-bold">1st link</p>
            <TextField
              type="text"
              label="Link"
              name="link"
              value={selectedItem2.links[0].link}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem2 };
                selectedItemNew.links[0].link = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Product/Service"
              name="product"
              value={selectedItem2.links[0].product}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem2 };
                selectedItemNew.links[0].product = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Company"
              name="companyname"
              value={selectedItem2.links[0].companyname}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem2 };
                selectedItemNew.links[0].companyname = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />
          </div>
        )}
        {selectedItem2.links[0] && !selectedItem2.links[1] && (
          <div
            className="rounded px-4 py-2 m-2 text-black bg-lightorange w-32"
            onClick={() => {
              var selectedItemNew = { ...selectedItem2 };
              selectedItemNew.links.push({
                companyname: "",
                product: "",
                link: "",
              });
              setSelectedItem2(selectedItemNew);
            }}
          >
            Add link
          </div>
        )}
        {selectedItem2.links[1] && (
          <div>
            <p className="font-bold">2nd link</p>
            <TextField
              type="text"
              label="Link"
              name="link"
              value={selectedItem2.links[1].link}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem2 };
                selectedItemNew.links[1].link = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Product/Service"
              name="product"
              value={selectedItem2.links[1].product}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem2 };
                selectedItemNew.links[1].product = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Company"
              name="companyname"
              value={selectedItem2.links[1].companyname}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem2 };
                selectedItemNew.links[1].companyname = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />
          </div>
        )}
        {selectedItem2.links[1] && !selectedItem2.links[2] && (
          <div
            className="rounded px-4 py-2 m-2 text-black bg-lightorange w-32"
            onClick={() => {
              var selectedItemNew = { ...selectedItem2 };
              selectedItemNew.links.push({
                companyname: "",
                product: "",
                link: "",
              });
              setSelectedItem2(selectedItemNew);
            }}
          >
            Add link
          </div>
        )}
        {selectedItem2.links[2] && (
          <div>
            <p className="font-bold">3rd link</p>
            <TextField
              type="text"
              label="Link"
              name="link"
              value={selectedItem2.links[2].link}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem2 };
                selectedItemNew.links[2].link = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Product/Service"
              name="product"
              value={selectedItem2.links[2].product}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem2 };
                selectedItemNew.links[2].product = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Company"
              name="companyname"
              value={selectedItem2.links[2].companyname}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem2 };
                selectedItemNew.links[2].companyname = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <Layout active="knowledge">
      <div className="w-full">
        <div className="mx-12 flex flex-wrap pt-12">
          <NewButton
            onClick={() => {
              setNew(true);
              setType("first");
              setSelectedItem({
                id: "",
                img: "",
                order: "",
                companyname: "",
                product: "",
                summary: "",
                title: "",
                visible: "false",
                articles: [],
                abtest: "false",
                secondtitle: "",
                secondimg: "",
              });
              setHtmlContent("");
              setLoading(false);
              setTimeout(() => {
                setLoading(true);
              }, 500);
            }}
            title="Add Category"
          />
          {data &&
            data.map((e, i) => {
              return (
                <MenuButton
                  key={i}
                  onClick={() => {
                    const datatorender = {
                      id: e.id,
                      img: e.img ? e.img : "",
                      order: e.order ? e.order : "",
                      companyname: e.companyname ? e.companyname : "",
                      product: e.product ? e.product : "",
                      summary: e.summary ? e.summary : "",
                      title: e.title ? e.title : "",
                      visible: e.visible ? e.visible : "true",
                      headers: e.headers ? e.headers : [],
                      articles: e.articles ? e.articles : [],
                      abtest: e.abtest ? e.abtest : "false",
                      secondtitle: e.secondtitle ? e.secondtitle : "",
                      secondimg: e.secondimg ? e.secondimg : "",
                    };
                    setHtmlContent(e.summary ? e.summary : "");
                    setTab(e.id);
                    setWeek(null);
                    setNew(false);
                    setSelectedItem(datatorender);
                    setSelectedItem2({});
                    setLoading(false);
                    setTimeout(() => {
                      setLoading(true);
                    }, 500);
                  }}
                  active={tab === e.id}
                  visible={e.visible ? e.visible : "true"}
                  title={e.title + " - " + e.order}
                />
              );
            })}
        </div>
      </div>
      <div className="w-full my-8 flex justify-center">
        {data && data.length > 0 && Object.keys(selectedItem).length > 0 && (
          <div className="w-full mx-12 flex flex-wrap">
            <NewButton
              onClick={() => {
                setNew(true);
                setType("second");
                setSelectedItem2({
                  id: "",
                  article: "",
                  img: "",
                  length: "",
                  order: "",
                  title: "",
                  visible: "true",
                  abtest: "false",
                  links: [],
                  secondtitle: "",
                  secondimg: "",
                  form: "",
                  formId: "",
                });
                setHtmlContent("");
                setLoading(false);
                setTimeout(() => {
                  setLoading(true);
                }, 500);
              }}
              title="Add Article"
            />
            {newCreate && type === "first"
              ? null
              : data &&
                data.length !== 0 &&
                data
                  .find(item => item.id === tab)
                  .articles.sort(sortByVisibilityAndOrder())
                  .map((value, index) => (
                    <MenuButton
                      key={index}
                      onClick={() => {
                        const datatorender = {
                          id: value.id,
                          article: value.article ? value.article : "",
                          img: value.img ? value.img : "",
                          length: value.length ? value.length : "",
                          order: value.order ? value.order : "",
                          title: value.title ? value.title : "",
                          visible: value.visible ? value.visible : "true",
                          abtest: value.abtest ? value.abtest : "false",
                          links: value.links ? value.links : [],
                          secondtitle: value.secondtitle
                            ? value.secondtitle
                            : "",
                          secondimg: value.secondimg ? value.secondimg : "",
                          form: value.form ? value.form : "",
                          formId: value.formId ? value.formId : "",
                        };
                        setWeek(index);
                        setHtmlContent(value.article ? value.article : "");
                        setNew(false);
                        setSelectedItem2(datatorender);
                        setLoading(false);
                        setTimeout(() => {
                          setLoading(true);
                        }, 500);
                      }}
                      active={week === index}
                      visible={value.visible ? value.visible : "true"}
                      title={value.title + " - " + value.order}
                    />
                  ))}
          </div>
        )}
      </div>
      {
        // CATEGORY
        ((newCreate && type === "first") ||
          (Object.keys(selectedItem).length > 0 &&
            Object.keys(selectedItem2).length === 0 &&
            !newCreate)) &&
          loading && (
            <div className="mx-12">
              <TextField
                type="text"
                label="Id"
                name="id"
                value={selectedItem.id}
                disabled
              />
              <TextField
                type="text"
                label="Title"
                name="title"
                value={selectedItem.title}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem };
                  selectedItemNew.title = event.target.value;
                  setSelectedItem(selectedItemNew);
                }}
              />
              <TextField
                type="text"
                label="Order"
                name="order"
                value={selectedItem.order}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem };
                  selectedItemNew.order = event.target.value;

                  setSelectedItem(selectedItemNew);
                }}
              />
              <TextField
                type="text"
                label="Company"
                name="companyname"
                value={selectedItem.companyname}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem };
                  selectedItemNew.companyname = event.target.value;
                  setSelectedItem(selectedItemNew);
                }}
              />
              <TextField
                type="text"
                label="Product/Service"
                name="product"
                value={selectedItem.product}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem };
                  selectedItemNew.product = event.target.value;
                  setSelectedItem(selectedItemNew);
                }}
              />
              <ImageUp
                title="Cover Image (ratio: 2 / 0.8 )"
                value={selectedItem.img}
                parentCallback={() => {
                  var selectedItemNew = { ...selectedItem };
                  selectedItemNew.img = "";
                  setSelectedItem(selectedItemNew);
                }}
                getImg={(value) => {
                  var selectedItemNew = { ...selectedItem };
                  selectedItemNew.img = value;
                  setSelectedItem(selectedItemNew);
                }}
              />
              <div className="flex flex-row">
                <div className="w-1/3" />
                <div className="flex flex-col items-start justify-start">
                  <Checkbox
                    name="Visibility"
                    selected={selectedItem.visible}
                    getValue={(data) => {
                      var selectedItemNew = { ...selectedItem };
                      selectedItemNew.visible = data;
                      setSelectedItem(selectedItemNew);
                    }}
                  />

                  <Checkbox
                    name="Ab test"
                    selected={selectedItem.abtest}
                    getValue={(data) => {
                      var selectedItemNew = { ...selectedItem };
                      selectedItemNew.abtest = data;
                      setSelectedItem(selectedItemNew);
                    }}
                  />
                </div>
              </div>
              {selectedItem.abtest === "true" && (
                <div>
                  <TextField
                    type="text"
                    label="New (B) Title"
                    name="secondtitle"
                    value={selectedItem.secondtitle}
                    onChange={(event) => {
                      var selectedItemNew = { ...selectedItem };
                      selectedItemNew.secondtitle = event.target.value;
                      setSelectedItem(selectedItemNew);
                    }}
                  />
                  <ImageUp
                    title="New (B) Cover Image (820 x 460)"
                    parentCallback={() => {
                      var selectedItemNew = { ...selectedItem };
                      selectedItemNew.secondimg = "";
                      setSelectedItem(selectedItemNew);
                    }}
                    value={selectedItem.secondimg}
                    getImg={(value) => {
                      var selectedItemNew = { ...selectedItem };
                      selectedItemNew.secondimg = value;
                      setSelectedItem(selectedItemNew);
                    }}
                  />
                </div>
              )}
              <HTMLEditor
                title="Summary"
                defaultValue={htmlContent}
                onChange={(content) => {
                  setHtmlContent(content);
                }}
              />
              {newCreate && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <CustomButton
                    type="submit"
                    className="w-1/4"
                    onClick={() => createCategory()}
                  >
                    Create
                  </CustomButton>
                </div>
              )}
              {!newCreate && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <CustomButton
                    red
                    type="submit"
                    className="w-1/4"
                    onClick={() => deleteCategory()}
                  >
                    Delete
                  </CustomButton>
                  <div style={{ width: 20, height: 1 }} />
                  <CustomButton
                    type="submit"
                    className="w-1/4"
                    onClick={() => save()}
                  >
                    Save
                  </CustomButton>
                </div>
              )}
            </div>
          )
      }
      {
        //  ARTICLE
        ((newCreate && type === "second") ||
          (Object.keys(selectedItem2).length > 0 && !newCreate)) &&
          loading && (
            <div className="mx-12">
              <TextField
                type="text"
                label="Id"
                name="id"
                value={selectedItem2.id}
                disabled
              />
              <TextField
                type="text"
                label="Title"
                name="title"
                value={selectedItem2.title}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.title = event.target.value;
                  setSelectedItem2(selectedItemNew);
                }}
              />
              <TextField
                type="text"
                label="Order"
                name="order"
                value={selectedItem2.order}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.order = event.target.value;

                  setSelectedItem2(selectedItemNew);
                }}
              />
              <ImageUp
                title="Cover Image (ratio: 2 / 1)"
                value={selectedItem2.img}
                parentCallback={() => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.img = "";
                  setSelectedItem2(selectedItemNew);
                }}
                getImg={(value) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.img = value;
                  setSelectedItem2(selectedItemNew);
                }}
              />
              <TextField
                type="text"
                label="Length (should be a number (minutes))"
                name="length"
                value={selectedItem2.length}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.length = event.target.value;
                  setSelectedItem2(selectedItemNew);
                }}
              />
              <div className="flex flex-row">
                <div className="w-1/3" />
                <div className="flex flex-col items-start justify-start">
                  <Checkbox
                    name="Visibility"
                    selected={selectedItem2.visible}
                    getValue={(data) => {
                      var selectedItemNew = { ...selectedItem2 };
                      selectedItemNew.visible = data;
                      setSelectedItem2(selectedItemNew);
                    }}
                  />
                  <Checkbox
                    name="Ab test"
                    selected={selectedItem2.abtest}
                    getValue={(data) => {
                      var selectedItemNew = { ...selectedItem2 };
                      selectedItemNew.abtest = data;
                      setSelectedItem2(selectedItemNew);
                    }}
                  />
                </div>
              </div>
              {selectedItem2.abtest === "true" && (
                <div>
                  <TextField
                    type="text"
                    label="New (B) Title"
                    name="secondtitle"
                    value={selectedItem2.secondtitle}
                    onChange={(event) => {
                      var selectedItemNew = { ...selectedItem2 };
                      selectedItemNew.secondtitle = event.target.value;
                      setSelectedItem2(selectedItemNew);
                    }}
                  />
                  <ImageUp
                    title="New (B) Image (820 x 460)"
                    value={selectedItem2.secondimg}
                    parentCallback={() => {
                      var selectedItemNew = { ...selectedItem2 };
                      selectedItemNew.secondimg = "";
                      setSelectedItem2(selectedItemNew);
                    }}
                    getImg={(value) => {
                      var selectedItemNew = { ...selectedItem2 };
                      selectedItemNew.secondimg = value;
                      setSelectedItem2(selectedItemNew);
                    }}
                  />
                </div>
              )}
              <HTMLEditor
                title="Article"
                defaultValue={htmlContent}
                onChange={(content) => {
                  setHtmlContent(content);
                }}
              />
              <p>Links in article</p>
              {renderLinksInArticle()}
              <Checkbox
                name="Form visible"
                selected={selectedItem2.form}
                getValue={(data) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.form = data;
                  setSelectedItem2(selectedItemNew);
                }}
              />
              {selectedItem2.form === "true" && selectedItem2.id && (
                <FormForm
                  formtype="article"
                  sourceIdnow={selectedItem2.id}
                  categoryIdnow={selectedItem.id}
                  parentCallback={() => setSelectedItem2({})}
                  selectedItem={selectedItem2}
                />
              )}
              {selectedItem2.form === "true" && !selectedItem2.id && (
                <div>Please first save this item</div>
              )}
              {newCreate && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <CustomButton
                    type="submit"
                    className="w-1/4"
                    onClick={() => {
                      setSelectedItem2({});
                      setNew(false);
                      setType("");
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <div style={{ width: 20, height: 1 }} />
                  <CustomButton
                    type="submit"
                    className="w-1/4"
                    onClick={() => type === "second" && createArticle()}
                  >
                    Create
                  </CustomButton>
                </div>
              )}
              {!newCreate && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <CustomButton
                    red
                    type="submit"
                    className="w-20"
                    onClick={() => deleteArticle()}
                  >
                    Delete
                  </CustomButton>
                  <div style={{ width: 20, height: 1 }} />
                  <CustomButton
                    type="submit"
                    className="w-20"
                    onClick={() => save2()}
                  >
                    Save
                  </CustomButton>
                </div>
              )}
            </div>
          )
      }
    </Layout>
  );
};

export default Knowledge;
