import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../redux/actions/auth";
import TextField from "../components/TextField";
import CustomButton from "../components/CustomButton";
// img
import logo from "../assets/logo.png";
import { useHistory } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state.state.token);
  const logerror = useSelector((state) => state.auth.logerror);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (token !== "") {
      history.push("/home");
    }
  }, [token]);

  return (
    <div className="bg-orange flex items-center justify-center pt-16 h-screen">
      <div className="w-1/4">
        <img alt="logo" src={logo} className="w-1/2 mx-auto mb-4" />
        <div className="bg-white rounded p-8 pt-12 shadow">
          <TextField
            type="text"
            label="E-mail cím:"
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            type="password"
            label="Password"
            name="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <CustomButton onClick={() => dispatch(login(email, password))}>
            Belépés
          </CustomButton>
          <p stlye={{ color: "red" }}>{logerror}</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
