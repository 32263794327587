import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setClickLoadingFalse } from "../../../redux/actions/analytics";
import CouponClick from "./CouponClick";
import TodoClick from "./TodoClick";
import ArticleClick from "./ArticleClick";

import DateSelector from "../../DateSelector";
import MenuButton from "../../MenuButton";

const ScreenAnalytics = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedScreen, setSelectedScreen] = useState("");

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />
      <div className="w-full flex flex-row items-center justify-center my-2">
        <MenuButton
          onClick={() => {
            setSelectedScreen("articles");
            dispatch(setClickLoadingFalse());
          }}
          active={selectedScreen === "articles"}
          title="Articles"
          visible="true"
        />
        <MenuButton
          onClick={() => {
            setSelectedScreen("coupons");
            dispatch(setClickLoadingFalse());
          }}
          active={selectedScreen === "coupons"}
          title="Coupons"
          visible="true"
        />
        <MenuButton
          onClick={() => {
            setSelectedScreen("todo");
            dispatch(setClickLoadingFalse());
          }}
          active={selectedScreen === "todo"}
          title="Todos"
          visible="true"
        />
      </div>
      {selectedScreen === "coupons" && (
        <CouponClick startDate={startDate} endDate={endDate} />
      )}
      {selectedScreen === "todo" && (
        <TodoClick startDate={startDate} endDate={endDate} />
      )}
      {selectedScreen === "articles" && (
        <ArticleClick startDate={startDate} endDate={endDate} />
      )}
    </div>
  );
};

export default ScreenAnalytics;
