export const initialState = {
  foodsafeties: [],
  recipes: [],
  faqs: [],
  compliants: [],
  photographers: [],
  babyisborn: {},
  videos: [],
};

const tools = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FOODSAFETY":
      return {
        ...state,
        foodsafeties: action.data,
      };
    case "GET_RECIPES":
      return {
        ...state,
        recipes: action.data,
      };
    case "GET_FAQ":
      return {
        ...state,
        faqs: action.data,
      };
    case "GET_COMPLIANTS":
      return {
        ...state,
        compliants: action.data,
      };
    case "GET_PHOTOGRAPHERS":
      return {
        ...state,
        photographers: action.data,
      };
    case "GET_BABYISBORN":
      return {
        ...state,
        babyisborn: action.data,
      };
    case "GET_VIDEOS":
      return {
        ...state,
        videos: action.data,
      };
    default:
      return state;
  }
};

export default tools;
