/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetAbTests } from "../../redux/actions/analytics";
import { formatDate } from "./helper";
import DateSelector from "../DateSelector";
import Spinner from "../Spinner";
import MenuButton from "../MenuButton";

const ABTests = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showTab, setShowTab] = useState("");
  const dispatch = useDispatch();
  const abtestloading = useSelector((state) => state.analytics.abtest_loading);
  const abtest = useSelector((state) => state.analytics.abtest);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        GetAbTests(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, [startDate, endDate]);

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />
      <div className="w-full flex flex-row items-center justify-center my-2">
        <MenuButton
          onClick={() => setShowTab("")}
          active={showTab === ""}
          title="All"
          visible="true"
        />
        <MenuButton
          onClick={() => setShowTab("deals")}
          active={showTab === "deals"}
          title="Deals"
          visible="true"
        />
        <MenuButton
          onClick={() => setShowTab("knowledge")}
          active={showTab === "knowledge"}
          title="Knowledge"
          visible="true"
        />
        <MenuButton
          onClick={() => setShowTab("article")}
          active={showTab === "article"}
          title="Article"
          visible="true"
        />
        <MenuButton
          onClick={() => setShowTab("popup")}
          active={showTab === "popup"}
          title="Popup"
          visible="true"
        />
      </div>
      <div>
        {abtestloading && <Spinner />}
        {!abtestloading &&
          abtest.length !== 0 &&
          abtest.screens
            .filter((item) => (showTab === "" ? item : item.type === showTab))
            .map((item, index) => {
              return (
                <table key={index}>
                  <thead>
                    <tr>
                      <th>{item.type}</th>
                      <th>Original (A)</th>
                      <th>New (B)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.original.title && item.new.title && (
                      <tr>
                        <td className="font-bold">Title</td>
                        <td>{item.original.title}</td>
                        <td>{item.new.title}</td>
                      </tr>
                    )}
                    {item.original.html && item.new.html && (
                      <tr>
                        <td className="font-bold">Html</td>
                        <td>{item.original.html}</td>
                        <td>{item.new.html}</td>
                      </tr>
                    )}
                    {item.original.img && item.new.img && (
                      <tr>
                        <td className="font-bold">Image</td>
                        <td>{item.original.img}</td>
                        <td>{item.new.img}</td>
                      </tr>
                    )}
                    {item.original.description && item.new.description && (
                      <tr>
                        <td className="font-bold">Description</td>
                        <td>{item.original.description}</td>
                        <td>{item.new.description}</td>
                      </tr>
                    )}
                     {item.original.buttonText && item.new.buttonText && (
                      <tr>
                        <td className="font-bold">buttonText</td>
                        <td>{item.original.buttonText}</td>
                        <td>{item.new.buttonText}</td>
                      </tr>
                    )}
                    <tr>
                      <td className="font-bold">Clicked</td>
                      <td>{item.original.pressed}</td>
                      <td>{item.new.pressed}</td>
                    </tr>
                    <tr>
                      <td className="font-bold">Percentage</td>
                      <td>
                        {" "}
                        {(
                          (Number(item.original.pressed) /
                            Number(
                              Number(item.original.pressed) +
                                Number(item.new.pressed)
                            )) *
                          100
                        ).toFixed(2)}{" "}
                        %
                      </td>
                      <td>
                        {(
                          (Number(item.new.pressed) /
                            Number(
                              Number(item.original.pressed) +
                                Number(item.new.pressed)
                            )) *
                          100
                        ).toFixed(2)}{" "}
                        %
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            })}
      </div>
    </div>
  );
};

export default ABTests;
