export const oldApiUrl = "https://api.preghello.com";
export const apiUrl = "https://backend.preghello.com";
export const analyticsUrl = "https://analytics.preghello.com";
export const dev_apiUrl = "http://localhost:9000";
export const master_key = "G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1";
export const herokuUrl = "https://preghelloapi.herokuapp.com";
export const baseUrl = "admin.admin.hu";
export const googleClientId =
  "575542549953-942pmlu068a8bjf1j0u7phdma3d7cjla.apps.googleusercontent.com";
export const googleClientSecret = "yzopwmuRbr8ZyPkBG8Um2Ynb";
export const googleApiKey = "AIzaSyBRbSjblJ2WecogHbmPFU6yA1sID8izY8Y";
