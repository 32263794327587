import axios from "axios";
import { apiUrl, herokuUrl, master_key } from "../../config/settings";
import {
  getCompliants,
  getFaqs,
  getFoodsafeties,
  getPhotographers,
  getRecipes,
  getVideos,
} from "./tools";
import { updatedData } from "./state";

export const update = (selectedItem, url) => (dispatch, getState) => {
  axios
    .put(
      apiUrl + url,
      {
        ...selectedItem,
      },
      { headers: { Authorization: "Bearer " + master_key } }
    )
    .then((response) => {
      dispatch({ type: "UPDATE_" + url });
      alert("Updated");
      dispatch(getAllData());
      dispatch(getPopups());
      dispatch(getRecipes());
      dispatch(getFaqs());
      dispatch(getPhotographers());
      dispatch(getCompliants());
      dispatch(getFoodsafeties());
    })
    .catch(function (error) {
      alert("Update ERROR");
      dispatch({ type: "UPDATE_" + url + "_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};
export const create = (selectedItem, url) => (dispatch, getState) => {
  axios
    .post(
      apiUrl + url,
      {
        ...selectedItem,
      },
      { headers: { Authorization: "Bearer " + master_key } }
    )
    .then((response) => {
      dispatch({ type: "UPDATE_" + url });
      alert("Updated");
      dispatch(getAllData());
      dispatch(getPopups());
    })
    .catch(function (error) {
      alert("Update ERROR");
      dispatch({ type: "UPDATE_" + url + "_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const sendNotif =
  (
    title,
    body,
    from,
    to,
    selectedItem,
    url,
    users,
    articleID,
    categoryID,
    link
  ) =>
  async (dispatch, getState) => {
    let segment = [];
    if (!(from === 0 && to === 0)) {
      users.forEach((x) => {
        if (
          x.pregdata.currentWeek >= from &&
          x.pregdata.currentWeek <= to &&
          x.notifId
        ) {
          segment.push(x.notifId);
        }
      });
    } else {
      segment.push("All");
    }
    let _ = {};
    if (segment[0] === "All") {
      _ = {
        app_id: "c0298c8a-b3f0-4a2a-96d2-5dc6b38ba450",
        contents: { en: body },
        headings: { en: title },
        data: { articleId: articleID, categoryId: categoryID, url: link },
        included_segments: segment,
      };
    } else {
      _ = {
        app_id: "c0298c8a-b3f0-4a2a-96d2-5dc6b38ba450",
        contents: { en: body },
        headings: { en: title },
        data: { articleId: articleID, categoryId: categoryID, url: link },
        include_player_ids: segment,
      };
    }

    axios
      .post("https://onesignal.com/api/v1/notifications", _, {
        headers: {
          Authorization:
            "Basic NTc0OGNmNTAtZmEzOS00ZWEzLTgyNDktMzI4OWJhMTJmNjEw",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (segment[0] !== "All")
          alert("Sent to " + segment.length + " device");
        alert("Success");
      })
      .catch(function (error) {
        alert("ERROR");
      });
  };

export const remove = (selectedItem, url) => (dispatch, getState) => {
  axios
    .delete(apiUrl + url, {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch({ type: "UPDATE_" + url });
      alert("Updated");
      dispatch(getAllData());
      dispatch(getPopups());
      dispatch(getRecipes());
      dispatch(getFaqs());
      dispatch(getPhotographers());
      dispatch(getCompliants());
      dispatch(getFoodsafeties());
      dispatch(getVideos());
    })
    .catch(function (error) {
      alert("Update ERROR");
      dispatch({ type: "UPDATE_" + url + "_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const getAllData = () => (dispatch, getState) => {
  return axios
    .get(apiUrl + "/alldatawithoutcache", {
      headers: { Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1" },
    })
    .then((response) => {
      dispatch(getScrolls());
      dispatch({ type: "GET_ALL_DATA", data: response.data });
      dispatch(updatedData());
      return response.data;
    })
    .catch(function (error) {
      dispatch({ type: "GET_ALL_DATA_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const refreshCache = () => (dispatch, getState) => {
  return axios
    .get(apiUrl + "/alldata/refresh", {
      headers: { Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1" },
    })
    .then((response) => {
      dispatch({ type: "REFRESH_CACHE", data: response.data });
      alert("Successfully refreshed all data to all users on digitalocean");
      return response.data;
    })
    .catch(function (error) {
      dispatch({ type: "REFRESH_CACHE_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const refreshCacheHeroku = () => (dispatch, getState) => {
  return axios
    .get(herokuUrl + "/alldata/refresh", {
      headers: { Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1" },
    })
    .then((response) => {
      dispatch({ type: "REFRESH_CACHE", data: response.data });
      alert("Successfully refreshed all data to all users on heroku");
      return response.data;
    })
    .catch(function (error) {
      dispatch({ type: "REFRESH_CACHE_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const sendNotification =
  (from, to, body, title, link) => (dispatch) => {
    axios
      .post(
        apiUrl + "/sendnotification",
        {
          from: from,
          to: to,
          body: body,
          title: title,
          link: link,
        },
        { headers: { Authorization: "Bearer " + master_key } }
      )
      .then((response) => {
        dispatch({ type: "SEND_NOTIFICATION" });
        alert(response.data.message);
      })
      .catch(function (error) {
        dispatch({ type: "SEND_NOTIFICATION_ERROR" });
      });
  };

//****************************************************USERS ************************************** */

export const getAnalyticsUserCount = () => (dispatch, getState) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  return axios
    .get(apiUrl + "/analyticsusers", {
      headers: {
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      dispatch({ type: "GET_USER_COUNT", data: response.data });
      dispatch({ type: "SET_LOADING_FALSE" });
    })
    .catch(function (error) {
      dispatch({ type: "SET_LOADING_FALSE" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const getAnalyticsUserWeeks = () => (dispatch, getState) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  return axios
    .get(apiUrl + "/analyticsuserweeks", {
      headers: {
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      dispatch({ type: "GET_USER_WEEK_COUNT", data: response.data });
      dispatch({ type: "SET_LOADING_FALSE" });
    })
    .catch(function (error) {
      dispatch({ type: "SET_LOADING_FALSE" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const getUserEmail = (email) => (dispatch, getState) => {
  dispatch({ type: "GET_USER_EMAIL_ERROR_DELETE" });
  axios
    .get(apiUrl + "/users/email/" + email, {
      headers: { Authorization: "Bearer " + getState().state.token },
    })
    .then((response) => {
      dispatch({ type: "GET_USER_EMAIL", data: response.data });
    })
    .catch(function (error) {
      dispatch({ type: "GET_USER_EMAIL_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const getUsers_ =
  (
    isNoti,
    title,
    body,
    from,
    to,
    selectedItem,
    url,
    users,
    articleID,
    categoryID,
    link
  ) =>
  (dispatch, getState) => {
    let i = -1000;
    let users = [];
    var interval = window.setInterval(() => {
      i = i + 1000;
      return axios
        .get(apiUrl + "/users?skip=" + i, {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer " + getState().state.token,
          },
        })
        .then((response) => {
          users.push(...response.data);

          if (response.data.length === 0) {
            clearInterval(interval);
            dispatch({ type: "GET_USERS", data: users });
            if (isNoti) {
              dispatch(
                sendNotif(
                  title,
                  body,
                  from,
                  to,
                  selectedItem,
                  url,
                  users,
                  articleID,
                  categoryID,
                  link
                )
              );
            } else {
              dispatch(getUsers(users));
            }
          }
        })
        .catch(function (error) {
          dispatch({ type: "GET_USERS_ERROR", data: "error event" });
          if (error.response) {
            if (error.response.status === 401) {
              dispatch({ type: "401_ERROR" });
            }
          }
          return [];
        });
    }, 2500);
  };
export const getUsers = (response) => (dispatch, getState) => {
  let weeks = [
    {
      week: 0,
      people: 0,
      percentage: 0,
    },
    {
      week: 1,
      people: 0,
      percentage: 0,
    },
    {
      week: 2,
      people: 0,
      percentage: 0,
    },
    {
      week: 3,
      people: 0,
      percentage: 0,
    },
    {
      week: 4,
      people: 0,
      percentage: 0,
    },
    {
      week: 5,
      people: 0,
      percentage: 0,
    },
    {
      week: 6,
      people: 0,
      percentage: 0,
    },
    {
      week: 7,
      people: 0,
      percentage: 0,
    },
    {
      week: 8,
      people: 0,
      percentage: 0,
    },
    {
      week: 9,
      people: 0,
      percentage: 0,
    },
    {
      week: 10,
      people: 0,
      percentage: 0,
    },
    {
      week: 11,
      people: 0,
      percentage: 0,
    },
    {
      week: 12,
      people: 0,
      percentage: 0,
    },
    {
      week: 13,
      people: 0,
      percentage: 0,
    },
    {
      week: 14,
      people: 0,
      percentage: 0,
    },
    {
      week: 15,
      people: 0,
      percentage: 0,
    },
    {
      week: 16,
      people: 0,
      percentage: 0,
    },
    {
      week: 17,
      people: 0,
      percentage: 0,
    },
    {
      week: 18,
      people: 0,
      percentage: 0,
    },
    {
      week: 19,
      people: 0,
      percentage: 0,
    },
    {
      week: 20,
      people: 0,
      percentage: 0,
    },
    {
      week: 21,
      people: 0,
      percentage: 0,
    },
    {
      week: 22,
      people: 0,
      percentage: 0,
    },
    {
      week: 23,
      people: 0,
      percentage: 0,
    },
    {
      week: 24,
      people: 0,
      percentage: 0,
    },
    {
      week: 25,
      people: 0,
      percentage: 0,
    },
    {
      week: 26,
      people: 0,
      percentage: 0,
    },
    {
      week: 27,
      people: 0,
      percentage: 0,
    },
    {
      week: 28,
      people: 0,
      percentage: 0,
    },
    {
      week: 29,
      people: 0,
      percentage: 0,
    },
    {
      week: 30,
      people: 0,
      percentage: 0,
    },
    {
      week: 31,
      people: 0,
      percentage: 0,
    },
    {
      week: 32,
      people: 0,
      percentage: 0,
    },
    {
      week: 33,
      people: 0,
      percentage: 0,
    },
    {
      week: 34,
      people: 0,
      percentage: 0,
    },
    {
      week: 35,
      people: 0,
      percentage: 0,
    },
    {
      week: 36,
      people: 0,
      percentage: 0,
    },
    {
      week: 37,
      people: 0,
      percentage: 0,
    },
    {
      week: 38,
      people: 0,
      percentage: 0,
    },
    {
      week: 39,
      people: 0,
      percentage: 0,
    },
    {
      week: 40,
      people: 0,
      percentage: 0,
    },
  ];
  response.forEach((e) => {
    if (e.pregdata.currentWeek) {
      weeks.forEach((x) => {
        if (e.pregdata.currentWeek === x.week) {
          x.people = x.people + 1;
        }
      });
    }
  });
  let allRealPeople = 0;
  weeks.forEach((e) => {
    allRealPeople += e.people;
  });
  const finishedWeeks = weeks.map((e) => {
    return {
      week: e.week,
      people: e.people,
      percentage: Math.round((100 / allRealPeople) * e.people * 100) / 100,
    };
  });

  dispatch({ type: "GET_USERS", data: response.data });
  dispatch({
    type: "GET_PREG_USERS_ANALYTICS",
    data: finishedWeeks,
    real: allRealPeople,
  });
};

export const getAnalyticsUsers = () => (dispatch, getState) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  axios
    .get(apiUrl + "/analyticsactives", {
      headers: {
        "Cache-Control": "no-cache",
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      dispatch({ type: "GET_ANALYTICS_ACTIVE", data: response.data });
      dispatch({ type: "SET_LOADING_FALSE" });
    })
    .catch(function (error) {
      dispatch({ type: "SET_LOADING_FALSE" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const getAnalyticsLinks = () => (dispatch, getState) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  return axios
    .get(apiUrl + "/analyticslinkknow", {
      headers: {
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      dispatch({ type: "GET_A_LINKS", data: response.data });
      dispatch({ type: "SET_LOADING_FALSE" });
    })
    .catch(function (error) {
      dispatch({ type: "GET_A_LINKS_ERROR" });
      dispatch({ type: "SET_LOADING_FALSE" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const getAbTests = () => (dispatch, getState) => {
  return axios
    .get(apiUrl + "/analyticsab", {
      headers: {
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      dispatch({ type: "GET_AB_TESTS", data: response.data });
    })
    .catch(function (error) {
      dispatch({ type: "GET_AB_TESTS_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const updateUser =
  (
    id,
    name,
    email,
    password,
    role,
    phone,
    address,
    school,
    training,
    finishedDays,
    active
  ) =>
  (dispatch, getState) => {
    axios
      .put(apiUrl + "/users/" + id, {
        name: name,
        email: email,
        pasword: password,
        role: role,
        phone: phone,
        address: address,
        school: school,
        training: training,
        active: active,
        finishedDays: finishedDays,
        access_token: getState().state.token,
      })
      .then((response) => {
        dispatch({ type: "UPDATE_USER" });
        dispatch(getUsers());
      })
      .catch(function (error) {
        dispatch({ type: "UPDATE_USER" });
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
      });
  };

export const deleteUser = (id) => (dispatch, getState) => {
  axios
    .delete(apiUrl + "/users/" + id, {
      headers: { Authorization: "Bearer " + getState().state.token },
    })
    .then((response) => {
      dispatch({ type: "DELETE_USER" });
      alert("User deleted");
      //dispatch(getUsers());
    })
    .catch(function (error) {
      dispatch({ type: "DELETE_USER_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

/*******************************************************************      POPUP     ****************************************************************/
export const getPopups = () => (dispatch, getState) => {
  return axios
    .get(apiUrl + "/popups", {
      headers: { Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1" },
    })
    .then((response) => {
      dispatch({ type: "GET_POPUPS", data: response.data });
      return response.data;
    })
    .catch(function (error) {
      dispatch({ type: "GET_POPUPS_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const createPopup =
  (
    title,
    description,
    buttontext,
    img,
    start_date,
    end_date,
    url,
    articleId,
    categoryId,
    dealId,
    fromWeek,
    toWeek
  ) =>
  (dispatch) => {
    axios
      .post(
        apiUrl + "/popups",
        {
          title: title,
          description: description,
          buttontext: buttontext,
          img: img,
          start_date: start_date,
          end_date: end_date,
          url: url,
          articleId: articleId,
          categoryId: categoryId,
          dealId: dealId,
          fromWeek: fromWeek,
          toWeek: toWeek,
        },
        { headers: { Authorization: "Bearer " + master_key } }
      )
      .then((response) => {
        dispatch(getPopups());
        alert("Created popup!");
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
      });
  };

export const updatePopup =
  (
    id,
    title,
    description,
    buttontext,
    img,
    start_date,
    end_date,
    url,
    articleId,
    categoryId,
    dealId,
    fromWeek,
    toWeek
  ) =>
  (dispatch) => {
    axios
      .put(
        apiUrl + "/popups/" + id,
        {
          title: title,
          description: description,
          buttontext: buttontext,
          img: img,
          start_date: start_date,
          end_date: end_date,
          url: url,
          articleId: articleId,
          categoryId: categoryId,
          dealId: dealId,
          fromWeek: fromWeek,
          toWeek: toWeek,
        },
        { headers: { Authorization: "Bearer " + master_key } }
      )
      .then((response) => {
        dispatch(getPopups());
        alert("Popup updated!");
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
      });
  };

export const deletePopup = (id) => (dispatch) => {
  axios
    .delete(apiUrl + "/popups/" + id, {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch(getPopups());
      alert("Popup deleted!");
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const getAnalyticsPopups = () => (dispatch, getState) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  return axios
    .get(apiUrl + "/analyticspopups", {
      headers: {
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      dispatch({ type: "GET_POPUP_ANALYTICS", data: response.data });
      dispatch({ type: "SET_LOADING_FALSE" });
    })
    .catch(function (error) {
      dispatch({ type: "GET_POPUP_ANALYTIC_ERROR" });
      dispatch({ type: "SET_LOADING_FALSE" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const getScrolls = () => (dispatch, getState) => {
  return axios
    .get(apiUrl + "/scrollweeks", {
      headers: { Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1" },
    })
    .then((response) => {
      dispatch({ type: "GET_SCROLLS", data: response.data });
      return response.data;
    })
    .catch(function (error) {
      dispatch({ type: "GET_SCROLLS_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const createScrolls =
  (
    week,
    scrollweek,
    type,
    boxtype,
    order,
    categorytitle,
    headline,
    text,
    img,
    link,
    tool,
    articleId,
    categoryId,
    dealId
  ) =>
  (dispatch) => {
    axios
      .post(
        apiUrl + "/scrollitems",
        {
          week: week,
          scrollweek: scrollweek,
          type: type,
          boxtype: boxtype,
          order: order,
          categorytitle: categorytitle,
          headline: headline,
          text: text,
          img: img,
          link: link,
          tool: tool,
          articleId: articleId,
          categoryId: categoryId,
          dealId: dealId,
        },
        { headers: { Authorization: "Bearer " + master_key } }
      )
      .then((response) => {
        dispatch(getScrolls());
        alert("Created scroll!");
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
      });
  };

export const updateScrolls =
  (
    id,
    week,
    scrollweek,
    type,
    boxtype,
    order,
    categorytitle,
    headline,
    text,
    img,
    link,
    tool,
    articleId,
    categoryId,
    dealId
  ) =>
  (dispatch) => {
    axios
      .put(
        apiUrl + "/scrollitems/" + id,
        {
          week: week,
          scrollweek: scrollweek,
          type: type,
          boxtype: boxtype,
          order: order,
          categorytitle: categorytitle,
          headline: headline,
          text: text,
          img: img,
          link: link,
          tool: tool,
          articleId: articleId,
          categoryId: categoryId,
          dealId: dealId,
        },
        { headers: { Authorization: "Bearer " + master_key } }
      )
      .then((response) => {
        dispatch(getScrolls());
        alert("Scrolls updated!");
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
      });
  };

export const deleteScrolls = (id) => (dispatch) => {
  axios
    .delete(apiUrl + "/scrollitems/" + id, {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch(getScrolls());
      alert("Scroll deleted!");
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const getScrollsAnalytics = () => (dispatch, getState) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  return axios
    .get(apiUrl + "/analyticsscrolls", {
      headers: {
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      dispatch({ type: "GET_SCROLLS_ANALYTICS", data: response.data });
      dispatch({ type: "SET_LOADING_FALSE" });
    })
    .catch(function (error) {
      dispatch({ type: "GET_SCROLLS_ANALYTIC_ERROR" });
      dispatch({ type: "SET_LOADING_FALSE" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};
