import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { create, update, getCompliants } from "../../redux/actions/tools";
import { openDeleteModal } from "../../redux/actions/state";
import MenuButton from "../MenuButton";
import NewButton from "../NewButton";
import CustomButton from "../CustomButton";
import IntroForm from "../forms/IntroForm";
import Spinner from "../Spinner";
import TextField from "../TextField";
import TextArea from "../TextArea";

const Complaints = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.tools.compliants);
  const deleteType = useSelector((state) => state.state.deleteType);
  const [selectedItem3, setSelectedItem] = useState([]);
  const [createtype, setCreateType] = useState(false);

  useEffect(() => {
    if (data === [] || !data) {
      dispatch(getCompliants());
    }
  }, []);

  useEffect(() => {
    if (deleteType === "compliants") {
      setSelectedItem({
        id: "",
        title: "",
        summary: "",
      });
    }
  }, [data]);

  const updateItem = () => {
    const selected = selectedItem3;
    const url = "/compliants/" + selected.id;
    dispatch(update(selected, url, "compliants"));
    setSelectedItem({});
  };

  const createItems = () => {
    const selected = selectedItem3;
    const url = "/compliants";
    dispatch(create(selected, url, "compliants"));
    setSelectedItem({});
  };

  const deleteItem = () => {
    const selected = selectedItem3;
    const url = "/compliants/" + selected.id;
    const type = "compliants";
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  return (
    <div>
      {data && <IntroForm intro={data.intro} />}
      <div className="flex flex-row w-full pb-12 flex-wrap">
        {!data && <Spinner />}
        {data && data !== [] && data.data.length !== 0 && (
          <div className="w-full">
            <div
              style={{
                width: "100%",
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="w-full mx-12 flex flex-wrap items-start">
                <NewButton
                  onClick={() => {
                    setCreateType(true);
                    setSelectedItem({
                      id: "",
                      title: "",
                      summary: "",
                    });
                  }}
                  title="Add Article"
                />
                {data.data.map((value, index) => (
                  <MenuButton
                    key={index}
                    backgroundColor="blue"
                    onClick={() => {
                      const datatorender = {
                        id: data.data[index].id,
                        title: data.data[index].title
                          ? data.data[index].title
                          : "",
                        summary: data.data[index].summary
                          ? data.data[index].summary
                          : "",
                      };
                      setCreateType(false);
                      setSelectedItem(datatorender);
                    }}
                    active={selectedItem3.id === value.id}
                    title={value.title}
                    visible="true"
                  />
                ))}
              </div>
            </div>
            {(createtype || Object.keys(selectedItem3).length > 0) && (
              <div className="mx-12">
                <h3>{selectedItem3.title}</h3>
                <TextField
                  type="text"
                  label="Id"
                  name="id"
                  value={selectedItem3.id}
                  disabled
                />
                <TextField
                  type="text"
                  label="Title"
                  name="title"
                  value={selectedItem3.title}
                  onChange={(event) => {
                    var selectedItem = { ...selectedItem3 };
                    selectedItem.title = event.target.value;
                    setSelectedItem(selectedItem);
                  }}
                />
                <TextArea
                  type="text"
                  label="Summary"
                  name="summary"
                  value={selectedItem3.summary}
                  onChange={(event) => {
                    var selectedItem = { ...selectedItem3 };
                    selectedItem.summary = event.target.value;
                    setSelectedItem(selectedItem);
                  }}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {!createtype && (
                    <CustomButton
                      red
                      type="submit"
                      className="w-1/5"
                      onClick={() => {
                        deleteItem();
                      }}
                    >
                      Delete
                    </CustomButton>
                  )}
                  <CustomButton
                    type="submit"
                    className="w-1/5"
                    onClick={() => {
                      if (createtype) {
                        createItems();
                      } else {
                        updateItem();
                      }
                    }}
                  >
                    Save
                  </CustomButton>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Complaints;
