import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/state";
import cookie from "react-cookies";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../assets/logo.png";
import "./style.css";

const SidebarMenu = ({ active }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logoutNow = () => {
    cookie.remove("token");
    dispatch(logout());
    history.push("/login");
  };

  return (
    <div className="fixed top-0 left-0 bottom-0 bg-orange rounded mt-2 ml-2 mb-2 w-64">
      <img alt="logo" src={logo} className="h-20 w-auto mx-auto mt-2" />
      <MenuItem
        title="Content"
        active={
          active === "home" ||
          active === "deals" ||
          active === "tools" ||
          active === "todo" ||
          active === "knowledge" ||
          active === "notification" ||
          active === "popup" ||
          active === "settings"
        }
        heading
      />
      <div className="ml-8 my-1px">
        <MenuItem to="/home" title="Home" active={active === "home"} />
        <MenuItem to="/deals" title="Deals" active={active === "deals"} />
        <MenuItem to="/tools" title="Tools" active={active === "tools"} />
        <MenuItem to="/todo" title="Todo" active={active === "todo"} />
        <MenuItem
          to="/knowledge"
          title="Knowledge"
          active={active === "knowledge"}
        />
        <MenuItem
          to="/notification"
          title="Notification"
          active={active === "notification"}
        />
        <MenuItem to="/popup" title="Popup" active={active === "popup"} />
        <MenuItem
          to="/settings"
          title="Settings"
          active={active === "settings"}
        />
      </div>
      <MenuItem
        title="Analytics"
        active={
          active === "useranalytics" ||
          active === "contentanalytics" ||
          active === "companyanalytics" ||
          active === "oldanalytics"
        }
        heading
      />
      <div className="ml-8 my-1px">
        <MenuItem
          to="/useranalytics"
          title="Users"
          active={active === "useranalytics"}
        />
        <MenuItem
          to="/contentanalytics"
          title="App content"
          active={active === "contentanalytics"}
        />
        <MenuItem
          to="/companyanalytics"
          title="Company"
          active={active === "companyanalytics"}
        />
        <MenuItem
          to="/oldanalytics"
          title="Old Analytics"
          active={active === "oldanalytics"}
        />
      </div>
      <MenuItem to="/forms" title="Forms" active={active === "forms"} />
      <MenuItem to="/help" title="Help" active={active === "help"} />
      <MenuItem
        to="/appversion"
        title="AppVersion"
        active={active === "appversion"}
      />
      <MenuItem
        to="/userdelete"
        title="Delete user"
        active={active === "userdelete"}
      />
      <MenuItem
        title="Logout"
        active={active === "b"}
        onClick={() => logoutNow()}
        logout
      />
    </div>
  );
};

const MenuItem = ({ to, title, active, onClick, heading, logout }) => {
  return (
    <li
      className={`relative z-10 flex flex-row h-7 mx-2 my-1px rounded ${
        active && "border-2 border-white"
      }`}
    >
      {heading && (
        <div className="text-base text-white ml-2 my-auto ">{title}</div>
      )}
      {to && (
        <Link
          to={to}
          title={title}
          className="text-base text-white ml-2 my-auto"
        >
          {title}
        </Link>
      )}
      {logout && (
        <div
          type="button"
          title={title}
          className="text-base text-white ml-2 my-auto focus:underline cursor:pointer"
          onClick={() => onClick()}
        >
          {title}
        </div>
      )}
    </li>
  );
};

export default SidebarMenu;
