import React, { useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "../screens/Login";

// content screens
import Home from "../screens/content/Home";
import Deals from "../screens/content/Deals";
import Tool from "../screens/content/Tool";
import Todo from "../screens/content/Todo";
import Knowledge from "../screens/content/Knowledge";
import Notification from "../screens/content/Notification";
import Popup from "../screens/content/Popup";
import Settings from "../screens/content/Settings";

// analytics screens
import OldAnalytics from "../screens/analytics/OldAnalytics";
import Users from "../screens/analytics/Users";
import Content from "../screens/analytics/Content";
import Company from "../screens/analytics/Company";

// other screens
import Forms from "../screens/Forms";
import AdminHelp from "../screens/AdminHelp";
import AppVersion from "../screens/AppVersion";
import UserDelete from "../screens/UserDelete";

import cookie from "react-cookies";
import { saveTokenFromCookie } from "../redux/actions/state";

const Routes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const error_401 = useSelector((state) => state.auth.logerror);
  const token = cookie.load("token");

  if (token) {
    dispatch(saveTokenFromCookie(token));
  }

  useEffect(() => {
    if (error_401) {
      history.push("/login");
      cookie.remove("token");
    }
  }, [error_401]);

  return (
    <Switch>
      <Route
        path={`/`}
        exact
        render={() =>
          token ? <Redirect to="/home" /> : <Redirect to="/login" />
        }
      />
      <Route
        path={`/login`}
        render={(props) => <Login {...props} key={Date.now()} />}
      />
      {/* Content screens */}
      <Route
        path={`/home`}
        render={(props) => <Home {...props} key={Date.now()} />}
      />
      <Route
        path={`/knowledge`}
        render={(props) => <Knowledge {...props} key={Date.now()} />}
      />
      <Route
        path={`/tools`}
        render={(props) => <Tool {...props} key={Date.now()} />}
      />
      <Route
        path={`/settings`}
        render={(props) => <Settings {...props} key={Date.now()} />}
      />
      <Route
        path={`/todo`}
        render={(props) => <Todo {...props} key={Date.now()} />}
      />
      <Route
        path={`/notification`}
        render={(props) => <Notification {...props} key={Date.now()} />}
      />
      <Route
        path={`/popup`}
        render={(props) => <Popup {...props} key={Date.now()} />}
      />
      <Route
        path={`/deals`}
        render={(props) => <Deals {...props} key={Date.now()} />}
      />
      {/* Analytics screens */}
      <Route
        path={`/oldanalytics`}
        render={(props) => <OldAnalytics {...props} key={Date.now()} />}
      />
      <Route
        path={`/useranalytics`}
        render={(props) => <Users {...props} key={Date.now()} />}
      />
      <Route
        path={`/contentanalytics`}
        render={(props) => <Content {...props} key={Date.now()} />}
      />{" "}
      <Route
        path={`/companyanalytics`}
        render={(props) => <Company {...props} key={Date.now()} />}
      />
      {/* Helper screens */}
      <Route
        path={`/forms`}
        render={(props) => <Forms {...props} key={Date.now()} />}
      />{" "}
      <Route
        path={`/appversion`}
        render={(props) => <AppVersion {...props} key={Date.now()} />}
      />{" "}
      <Route
        path={`/help`}
        render={(props) => <AdminHelp {...props} key={Date.now()} />}
      />{" "}
      <Route
        path={`/userdelete`}
        render={(props) => <UserDelete {...props} key={Date.now()} />}
      />{" "}
    </Switch>
  );
};

export default Routes;
