import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GetPopupAnalytics,
  getCompanyList,
  setCompanyLoadedFalse,
} from "../../redux/actions/analytics";
import { formatDate } from "./helper";
import DateSelector from "../DateSelector";
import Spinner from "../Spinner";
import Dropdown from "../Dropdown";
import { getAllData } from "../../redux/actions/api";

const PopupAnalytics = () => {
  const dispatch = useDispatch();
  const apidata = useSelector((state) => state.api.data);

  const companylist = useSelector((state) => state.analytics.companylist);
  const popuploading = useSelector(
    (state) => state.analytics.popup_analytics_loading
  );
  const popup = useSelector((state) => state.analytics.popup_analytics);
  const popupdate = useSelector(
    (state) => state.analytics.popup_date_analytics
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [shown, setShown] = useState("first");

  useEffect(() => {
    dispatch(getAllData());
  }, []);


  useEffect(() => {
    if (companylist.length === 0) {
      dispatch(getCompanyList());
    }
  }, [companylist]);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        GetPopupAnalytics(
          formatDate(startDate),
          formatDate(endDate),
          limit,
          skip
        )
      );
    }
  }, [startDate, endDate]);

  const getKnowledgeFromId = (placement_id) => {
    const articleselect = [];
      apidata.knowledge.forEach((item) => {
          articleselect.push({ value: item.id, label: item.title });
      });
    const title = articleselect.find((item) => item.value === placement_id).label
    return `Kategória: ${title}`
  }

const getArticleFromId = (placement_id) => {
  const articleselect = [];
    apidata.knowledge.forEach((item) => {
      item.articles.forEach((e) => {
        articleselect.push({ value: e.id, label: e.title });
      });
    });
  const title = articleselect.find((item) => item.value === placement_id).label
  return `Cikk: ${title}`
}

  const getPlacementName = (placement_type, placement_id) => {
    switch (placement_type) {
      case "home":
        return "Home főoldal";
      case "home_baby":
        return `Home baba - ${placement_id}. hét`;
      case "home_mum":
        return `Home mama - ${placement_id}. hét`;
      case "knowledge":
        return "Tudástás főoldal";
      case "knowledge_category":
        return getKnowledgeFromId(placement_id); 
      case "knowledge_article":
        return getArticleFromId(placement_id); 
      case "tools":
        return "Eszköztár főoldal";
      case "tools_category":
        return `Eszköz: ${placement_id}`;
      case "todo":
        return "Teendők főoldal";
      default:
        return "";
    }
  };

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />
      <div className="w-1/2">
        <Dropdown
          value={shown}
          options={[
            { value: "first", label: "First time shown" },
            { value: "second", label: "Second time shown" },
          ]}
          onChange={(value) => {
            setShown(value.value);
          }}
        />
        <Dropdown
          value={companyname}
          options={companylist}
          onChange={(value) => {
            setCompanyname(value.value);
            dispatch(setCompanyLoadedFalse());
          }}
        />
      </div>
      <div>
        {popuploading && <Spinner />}
        {!popuploading && popup.length !== 0 && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Where</th>
                  <th>Impression</th>
                  <th>Click</th>
                  <th>CTR</th>
                </tr>
              </thead>
              <tbody>
                {popup.screens
                  .filter((item) => item.shown === shown)
                  .filter((item) => companyname === '' ? item : item.companyname === companyname)
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.companyname}</td>
                        <td>
                          {getPlacementName(
                            item.placement_type,
                            item.placement_id
                          )}
                        </td>
                        <td>{item.appeared}</td>
                        <td>{item.pressed}</td>
                        <td>
                          {(
                            (Number(item.pressed) / Number(item.appeared)) *
                            100
                          ).toFixed(2)}{" "}
                          %
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
        {!popuploading && popupdate.length !== 0 && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Where</th>
                  <th>Impression</th>
                  <th>Click</th>
                  <th>CTR</th>
                </tr>
              </thead>
              <tbody>
                {JSON.parse(popupdate)
                  .sort((a, b) => a.date.localeCompare(b.date))
                  .map((item, i) => {
                    return item.popups
                      .filter((item) => item.shown === shown)
                      .filter((item) => companyname === '' ? item : item.companyname === companyname)
                      .map((element, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.date}</td>
                            <td>
                              {getPlacementName(
                                element.placement_type,
                                element.placement_id
                              )}
                            </td>
                            <td>{element.appeared}</td>
                            <td>{element.pressed}</td>
                            <td>
                              {(
                                (Number(element.pressed) /
                                  Number(element.appeared)) *
                                100
                              ).toFixed(2)}{" "}
                              %
                            </td>
                          </tr>
                        );
                      });
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default PopupAnalytics;
