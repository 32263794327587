import axios from "axios";
import { apiUrl, master_key } from "../../config/settings";

export const getNotes = () => (dispatch) => {
  return axios
    .get(apiUrl + "/notes", {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch({ type: "GET_NOTES", data: response.data });
    })
    .catch(function (error) {
      dispatch({ type: "GET_NOTES_ERROR" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
      return [];
    });
};

export const updateNotes = (newNote) => (dispatch, getState) => {
  axios
    .put(
      apiUrl + "/notes",
      {
        text: newNote,
      },
      { headers: { Authorization: "Bearer " + master_key } }
    )
    .then((response) => {
      dispatch({ type: "UPDATE_NOTES" });
      alert("Updated");
      dispatch(getNotes());
    })
    .catch(function (error) {
      alert("Update ERROR");
      alert(error);
      dispatch({ type: "UPDATE_NOTES_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const getAppversion = () => (dispatch) => {
  return axios
    .get(apiUrl + "/appversion", {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch({ type: "GET_APPVERSION", data: response.data });
    })
    .catch(function (error) {
      dispatch({ type: "GET_APPVERSION_ERROR" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
      return [];
    });
};

export const updateAppversion = (appversion) => (dispatch, getState) => {
  axios
    .put(
      apiUrl + "/appversion",
      {
        version: appversion,
      },
      { headers: { Authorization: "Bearer " + master_key } }
    )
    .then((response) => {
      dispatch({ type: "UPDATE_APPVERSION" });
      alert("Updated");
      dispatch(getAppversion());
    })
    .catch(function (error) {
      alert("Update ERROR");
      alert(error);
      dispatch({ type: "UPDATE_APPVERSION_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const getSettings = () => (dispatch) => {
  return axios
    .get(apiUrl + "/settings", {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch({ type: "GET_SETTINGS", data: response.data });
    })
    .catch(function (error) {
      dispatch({ type: "GET_SETTINGS_ERROR" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
      return [];
    });
};

export const updateSettings = (selecteditem, type) => (dispatch, getState) => {
  const body = {};
  if (type === "about") {
    body.aboutText = selecteditem.aboutText;
  }
  if (type === "privacy") {
    body.privacyText = selecteditem.privacyText;
  }
  if (type === "data") {
    body.dataprivacy = selecteditem.dataprivacy;
  }
  axios
    .put(apiUrl + "/settings/" + type, body, {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch({ type: "UPDATE_SETTINGS" });
      alert("Updated");
      dispatch(getSettings());
    })
    .catch(function (error) {
      alert("Update ERROR");
      alert(error);
      dispatch({ type: "UPDATE_SETTINGS_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};
