export const initialState = {
  note: "",
  appversion: "",
  settings: {},
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case "GET_NOTES":
      return {
        ...state,
        note: action.data,
      };
    case "GET_APPVERSION":
      return {
        ...state,
        appversion: action.data.version,
      };
    case "GET_SETTINGS":
      return {
        ...state,
        settings: action.data,
      };
    default:
      return state;
  }
};

export default settings;
