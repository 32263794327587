import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { filterByDate } from "../../utils/filter";

const KnowLedgeAnalytics = ({ startDate, endDate }) => {
  const screens = useSelector((state) => state.oldanalytics.screens_know);
  const [knowTable, setKnowTable] = useState([]);
  const [sum, setSum] = useState("");

  useEffect(() => {
    if (screens.length !== 0 && startDate !== "" && endDate !== "") {
      getTable();
    }
  }, [screens, startDate, endDate]);

  const getTable = () => {
    const screensDate = filterByDate(screens, startDate, endDate);
    const screensFlat = screensDate.map((item) => item.screens).flat(2);
    addScreens(screensFlat);
  };

  async function addScreens(screensFlat) {
    const screenAgregated = [];
    // minden nap adatát összeadogatjuk és egy nagy adattömböt csinálunk belőle
    await Promise.all(
      screensFlat.map(async (item) => {
        let isScreen = screenAgregated.find((x) => x.screen === item.screen);
        if (isScreen) {
          await Promise.all(
            screenAgregated.map(async (element, index) => {
              if (element === isScreen) {
                await Object.assign(screenAgregated[index], {
                  pressed:
                    Number(screenAgregated[index].pressed) +
                    Number(item.pressed),
                });
                const newuserid = item.userid.filter(
                  (e) => !element.userid.includes(e)
                );
                await screenAgregated[index].userid.push(...newuserid);
              }
            })
          );
        } else {
          await screenAgregated.push(item);
        }
      })
    );
    setKnowTable(screenAgregated);
    const sum = screenAgregated.map((item) => item.pressed);
    var total = 0;
    for (var i in sum) {
      total += Number(sum[i]);
    }
    setSum(total);
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          minWidth: "30vw",
          marginTop: 20,
          marginBottom: 20,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {knowTable.length !== 0 && (
          <div>
            <div>Összesített táblázat</div>
            <table>
              <thead>
                <tr>
                  <th>Screen</th>
                  <th>View</th>
                  <th>Percentage (view/all view)</th>
                  <th>Uniq</th>
                </tr>
              </thead>
              <tbody>
                {knowTable.map((f, index) => {
                  return (
                    <tr key={index}>
                      <td>{f.screen}</td>
                      <td>{f.pressed}</td>
                      <td>
                        {((f.pressed * 100) / sum).toFixed(2)}
                        {" %"}
                      </td>
                      <td>{f.userid.length}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default KnowLedgeAnalytics;
