import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllData, update } from "../../redux/actions/api";
import Checkbox from "../../components/Checkbox";
import FormForm from "../../components/forms/FormForm";
import Layout from "../../components/layout/Layout";
import CustomButton from "../../components/CustomButton";
import MenuButton from "../../components/MenuButton";
import TextField from "../../components/TextField";
import Scrolls from "../../components/Scrolls";
import HTMLEditor from "../../components/HTMLEditor";

const Home = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [week, setWeek] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [sizeTexts, setSizeTexts] = useState([]);
  const [weekTexts, setWeekTexts] = useState([]);
  const [htmlContentBaby, setHtmlContentBaby] = useState("");
  const [htmlContentMum, setHtmlContentMum] = useState("");
  const apidata = useSelector((state) => state.api.data);

  useEffect(() => {
    dispatch(getAllData());
    setSelectedItem({});
    if (Object.keys(apidata).length > 0) {
      setSizeTexts(apidata.homescreen[0].sizeTexts);
      setWeekTexts(apidata.homescreen[0].weekTexts);
    }
  }, []);

  useEffect(() => {
    setSelectedItem({});
    if (Object.keys(apidata).length > 0) {
      setSizeTexts(apidata.homescreen[0].sizeTexts);
      setWeekTexts(apidata.homescreen[0].weekTexts);
    }
  }, [apidata]);

  const save = () => {
    const selected = selectedItem;
    const url = "/homescreen/updatesize/" + selectedItem.fruit;
    dispatch(update(selected, url));
  };
  const saveCalendar = () => {
    const selected = selectedItem;
    selected.baby_text = htmlContentBaby;
    selected.mum_text = htmlContentMum;
    const url = "/homescreen/updateweek";
    dispatch(update(selected, url));
  };

  const renderSizeTexts = () => {
    return (
      <div className="mx-12">
        {Object.keys(selectedItem).length > 0 && tab === 0 && (
          <div>
            <h3>
              {tab === 0 ? "Size text - " : "Calendar Text - "}
              {Number(week) === 41 ? "40+ weeks" : week + ".week"}
            </h3>
            <TextField
              type="text"
              label="Fruit"
              name="fruit"
              value={selectedItem.fruit}
              disabled
            />
            <TextField
              type="text"
              label="Length"
              name="length"
              value={selectedItem.length}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.length = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Weight"
              name="weight"
              value={selectedItem.weight}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.weight = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Hand Size"
              name="handSize"
              value={selectedItem.handSize}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.handSize = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <div>
              {"Image - "}
              {selectedItem.img}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton
                type="submit"
                className="w-1/4"
                onClick={() => save()}
              >
                Save
              </CustomButton>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderWeekTexts = () => {
    return (
      <div className="mx-12">
        {Object.keys(selectedItem).length > 0 && tab === 1 && (
          <div>
            <h3>
              {tab === 0 ? "Size text - " : "Calendar Text - "}
              {Number(week) === 41 ? "40+ weeks" : week + ".week"}
            </h3>
            <TextField
              type="text"
              label="Image"
              name="img"
              value={selectedItem.img}
              disabled
            />
            <TextField
              type="text"
              label="Week"
              name="week_no"
              value={selectedItem.week_no}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.week_no = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Baby Ad Company"
              name="babyAd_company"
              value={selectedItem.babyAd_company}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.babyAd_company = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Baby Ad Product/Service"
              name="babyAd_product"
              value={selectedItem.babyAd_product}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.babyAd_product = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <HTMLEditor
              title="Baby Text"
              defaultValue={htmlContentBaby}
              onChange={(content) => {
                setHtmlContentBaby(content);
              }}
            />
            <TextField
              type="text"
              label="Mum Ad Company"
              name="mumAd_company"
              value={selectedItem.mumAd_company}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.mumAd_company = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Mum Ad Product/Service"
              name="mumAd_product"
              value={selectedItem.mumAd_product}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.mumAd_product = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <HTMLEditor
              title="Mum Text"
              defaultValue={htmlContentMum}
              onChange={(content) => {
                setHtmlContentMum(content);
              }}
            />
            <Checkbox
              name="Form visible"
              selected={selectedItem.form}
              getValue={(data) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.form = data;
                setSelectedItem(selectedItemNew);
              }}
            />
            {selectedItem.form === "true" && selectedItem.week_no && (
              <FormForm
                formtype="home"
                sourceIdnow={selectedItem.week_no}
                categoryIdnow={null}
                parentCallback={() => setSelectedItem({})}
                selectedItem={selectedItem}
              />
            )}
            {selectedItem.form === "true" && !selectedItem.week_no && (
              <div>Please first save this item</div>
            )}

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton
                type="submit"
                className="w-1/4"
                onClick={() => saveCalendar()}
              >
                Save
              </CustomButton>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Layout active="home">
      <div className="flex flex-row pt-20 w-full justify-around">
        <MenuButton
          onClick={() => {
            setTab(0);
            setWeek(0);
            setSelectedItem({});
          }}
          active={tab === 0}
          title="Size Text"
          visible="true"
        />
        <MenuButton
          onClick={() => {
            setTab(1);
            setWeek(0);
            setSelectedItem({});
            setHtmlContentBaby("");
            setHtmlContentMum("");
          }}
          active={tab === 1}
          title="Calendar Text"
          visible="true"
        />
        {/* <MenuButton
            onClick={() => this.setState({ tab: 2, week: 0, selectedItem: {} })}
            active={this.state.tab === 2}
            title="Scrolls"
            visible="true"
          />*/}
      </div>
      <div className="flex justify-center my-12 w-full">
        <div className="flex flex-wrap w-full mx-12">
          {tab === 0 &&
            sizeTexts &&
            sizeTexts.map((value, index) => (
              <MenuButton
                key={index}
                onClick={() => {
                  const datatorender = {
                    fruit: value.fruit ? value.fruit : "",
                    handSize: value.handSize ? value.handSize : "",
                    img: value.img ? value.img : "",
                    length: value.length ? value.length : "",
                    weight: value.weight ? value.weight : "",
                  };

                  setSelectedItem(datatorender);
                  setLoading(false);
                  setWeek(index + 1);
                  setTimeout(() => {
                    setLoading(true);
                  }, 500);
                }}
                active={week === index + 1}
                visible="true"
                title={
                  index + 1 === 41 ? "40+ weeks" : Number(index + 1) + ".week"
                }
              />
            ))}
          {tab === 1 &&
            weekTexts &&
            weekTexts.map((value, index) => (
              <MenuButton
                key={index}
                onClick={() => {
                  const datatorender = {
                    baby_text: value.baby_text ? value.baby_text : "",
                    img: value.img ? value.img : "",
                    mum_text: value.mum_text ? value.mum_text : "",
                    week_no: value.week_no ? value.week_no : "",
                    mumAd_company: value.mumAd_company
                      ? value.mumAd_company
                      : "",
                    mumAd_product: value.mumAd_product
                      ? value.mumAd_product
                      : "",
                    babyAd_company: value.babyAd_company
                      ? value.babyAd_company
                      : "",
                    babyAd_product: value.babyAd_product
                      ? value.babyAd_product
                      : "",
                    form: value.form ? value.form : "false",
                    formId: value.formId ? value.formId : "",
                  };
                  setSelectedItem(datatorender);
                  setHtmlContentBaby(value.baby_text ? value.baby_text : "");
                  setHtmlContentMum(value.mum_text ? value.mum_text : "");
                  setLoading(false);
                  setWeek(index + 1);
                  setTimeout(() => {
                    setLoading(true);
                  }, 500);
                }}
                active={week === index + 1}
                visible="true"
                title={
                  index + 1 === 41 ? "40+ weeks" : Number(index + 1) + ".week"
                }
              />
            ))}
        </div>
      </div>
      {renderSizeTexts()}
      {loading && renderWeekTexts()}
      {tab === 2 && <Scrolls />}
    </Layout>
  );
};

export default Home;
