export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] !== "" && b[property] !== ""
        ? a[property] < b[property]
          ? -1
          : a[property] > b[property]
          ? 1
          : 0
        : 0;
    return result * sortOrder;
  };
}

export function dynamicSortMultiple() {
  var props = arguments;
  return function (obj1, obj2) {
    var i = 0,
      result = 0,
      numberOfProperties = props.length;
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i])(obj1, obj2);
      i++;
    }
    return result;
  };
}

export function sortByVisibilityAndOrder() {
  var props = arguments;
  return function (a, b) {
    var aSize = a.visible;
    var bSize = b.visible;
    var aLow = a.order === "" ? 999 : a.order;
    var bLow = b.order === "" ? 999 : b.order;
    if (aSize === bSize) {
      return Number(aLow) < Number(bLow)
        ? -1
        : Number(aLow) > Number(bLow)
        ? 1
        : 0;
    } else {
      return aSize < bSize ? 1 : -1;
    }
  };
}

export function sortByOrder() {
  var props = arguments;
  return function (a, b) {
    var aLow = a.order === "" ? 999 : a.order;
    var bLow = b.order === "" ? 999 : b.order;

    return Number(aLow) < Number(bLow)
      ? -1
      : Number(aLow) > Number(bLow)
      ? 1
      : 0;
  };
}

export function sortByVisibility() {
  var props = arguments;
  return function (a, b) {
    var aSize = a.visible;
    var bSize = b.visible;

    return aSize < bSize ? 1 : -1;
  };
}
