import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllData, update, create } from "../../redux/actions/api";
import { openDeleteModal } from "../../redux/actions/state";
import Checkbox from "../../components/Checkbox";
import ImageUp from "../../components/ImageUp";
import Layout from "../../components/layout/Layout";
import MenuButton from "../../components/MenuButton";
import NewButton from "../../components/NewButton";
import IntroForm from "../../components/forms/IntroForm";
import CustomButton from "../../components/CustomButton";
import TextField from "../../components/TextField";
import TextArea from "../../components/TextArea";
import { sortByOrder, sortByVisibilityAndOrder } from "../../utils/sort";

const Todo = () => {
  const dispatch = useDispatch();
  const apidata = useSelector((state) => state.api.data);
  const deleteType = useSelector((state) => state.state.deleteType);
  const [data, setData] = useState([]);
  const [todointro, setTodoIntro] = useState([]);
  const [type, setType] = useState("");
  const [tab, setTab] = useState("");
  const [week, setWeek] = useState("");
  const [week2, setWeek2] = useState("");
  const [newCreate, setNew] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItem2, setSelectedItem2] = useState({});
  const [selectedItem3, setSelectedItem3] = useState({});

  useEffect(() => {
    dispatch(getAllData());
    setSelectedItem({});
    setSelectedItem2({});
    setSelectedItem3({});
    setTab(null);
    setWeek(null);
    setWeek2(null);
    setType("");
    setNew(true);
    if (Object.keys(apidata).length > 0) {
      setData(apidata.todo.data);
      setTodoIntro(apidata.todo.intro);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(apidata).length > 0) {
      setData(apidata.todo.data);
      setTodoIntro(apidata.todo.intro);
      setTab(null);
      setWeek(null);
      setWeek2(null);
    }
    if (deleteType === "todo-category") {
      setWeek(null);
      setNew(true);
      setSelectedItem({
        id: "",
        title: "",
        visible: "true",
        order: "",
        data: [],
      });
      setType("");
    }
    if (deleteType === "todo-article") {
      setWeek(null);
      setNew(true);
      setSelectedItem2({
        id: "",
        title: "",
        summary: "",
        order: "",
        items: [],
      });
      setType("");
    }
    if (deleteType === "todo-item") {
      setWeek(null);
      setNew(true);
      setSelectedItem3({
        id: "",
        info: "",
        isButton: "false",
        visible: "true",
        link: "",
        title: "",
        order: "",
        companyname: "",
        img: "",
      });
      setType("");
    }
  }, [apidata]);

  const save = () => {
    const selected = selectedItem;
    const url = "/todo/" + selectedItem.id;
    dispatch(update(selected, url));
  };
  const save2 = () => {
    const selected = selectedItem2;
    const url = "/todo/updatedata/" + selectedItem.id;
    setSelectedItem2({
      id: "",
      title: "",
      summary: "",
      order: "",
      items: [],
    });
    dispatch(update(selected, url));
  };
  const save3 = () => {
    const selected = selectedItem3;
    const selected2 = selectedItem2;
    selected2.items.forEach((e, i) => {
      if (e.id === selected.id) {
        selected2.items[i] = selected;
      }
    });
    const url = "/todo/updatedata/" + selectedItem.id;
    dispatch(update(selected2, url));
  };
  const createCategory = () => {
    const selected = selectedItem;
    selected.id = Date.now().toString();
    const url = "/todo/";
    setSelectedItem({
      id: "",
      title: "",
      visible: "true",
      order: "",
      data: [],
    });
    setNew(false);
    setType("");
    dispatch(create(selected, url));
  };
  const createArticle = () => {
    const selected = selectedItem2;
    selected.id = Date.now().toString();
    const url = "/todo/data/" + selectedItem.id;
    setSelectedItem2({
      id: "",
      title: "",
      summary: "",
      order: "",
      items: [],
    });
    setNew(false);
    setType("");
    dispatch(update(selected, url));
  };
  const createItems = () => {
    const selected = selectedItem3;
    const selected2 = selectedItem2;
    selected.id = Date.now().toString();
    selected2.items = [...selected2.items, selected];
    const url = "/todo/updatedata/" + selectedItem.id;
    setSelectedItem3({
      id: "",
      info: "",
      isButton: "false",
      visible: "true",
      link: "",
      title: "",
      order: "",
      companyname: "",
      img: "",
    });
    setNew(false);
    setType("");
    dispatch(update(selected2, url));
  };

  const deleteCategory = () => {
    const selected = selectedItem;
    const url = "/todo/" + selectedItem.id;
    const type = "todo-category";
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };
  const deleteArticle = () => {
    const selected = selectedItem2;
    const url = "/todo/removedata/" + selectedItem.id;
    const type = "todo-article";
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };
  const deleteItems = () => {
    const selected3 = selectedItem3;
    const selected = selectedItem2;
    selected.items = selected.items.filter((item) => item.id !== selected3.id);
    const url = "/todo/updatedata/" + selectedItem.id;
    const type = "todo-item";
    const title = selected3.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  return (
    <Layout active="todo">
      {todointro && <IntroForm intro={todointro} />}
      <div className="w-full">
        <div className="flex flex-row flex-wrap justify-around mx-12">
          <NewButton
            onClick={() => {
              setNew(true);
              setType("first");
              setSelectedItem({
                id: "",
                title: "",
                order: "",
                data: [],
              });
              setTab(null);
            }}
            title="Add Category"
          />
          {data &&
            data.sort(sortByOrder()).map((e, i) => {
              return (
                <MenuButton
                  key={i}
                  onClick={() => {
                    const datatorender = {
                      id: e.id,
                      title: e.title ? e.title : "",
                      order: e.order ? e.order : "",
                      data: e.data ? e.data : [],
                    };
                    setSelectedItem(datatorender);
                    setTab(e.id);
                    setWeek(null);
                    setNew(false);
                    setSelectedItem2({});
                    setSelectedItem3({});
                  }}
                  active={tab === e.id}
                  visible="true"
                  title={e.title + " - " + e.order}
                />
              );
            })}
        </div>
      </div>
      <div className="w-full my-2 flex justify-center">
        {data &&
          tab !== null &&
          data.length > 0 &&
          Object.keys(selectedItem).length > 0 && (
            <div className="flex flex-row flex-wrap w-full mx-12">
              <NewButton
                onClick={() => {
                  setNew(true);
                  setType("second");
                  setSelectedItem2({
                    id: "",
                    title: "",
                    summary: "",
                    order: "",
                    items: [],
                  });
                }}
                title="Add sub-category"
              />
              {selectedItem.data.sort(sortByOrder()).map((value, index) => (
                <MenuButton
                  onClick={() => {
                    const datatorender = {
                      id: value.id,
                      title: value.title ? value.title : "",
                      summary: value.summary ? value.summary : "",
                      order: value.order ? value.order : "",
                      items: value.items ? value.items : [],
                    };
                    setWeek(value.id);
                    setNew(false);
                    setSelectedItem2(datatorender);
                    setSelectedItem3({});
                  }}
                  active={week === value.id}
                  title={value.title + " - " + value.order}
                  visible="true"
                />
              ))}
            </div>
          )}
      </div>
      <div className="w-full my-2 flex justify-center">
        {data &&
          tab !== null &&
          week !== null &&
          data.length > 0 &&
          Object.keys(selectedItem2).length > 0 && (
            <div className="flex flex-row flex-wrap w-full mx-12">
              <NewButton
                onClick={() => {
                  setNew(true);
                  setType("third");
                  setSelectedItem3({
                    id: "",
                    info: "",

                    isButton: "false",
                    visible: "false",
                    link: "",
                    title: "",
                    order: "",
                    companyname: "",
                    img: "",
                  });
                }}
                title="Add Item"
              />
              {selectedItem2.items &&
                selectedItem2.items
                  .sort(sortByVisibilityAndOrder())
                  .map((value, index) => (
                    <MenuButton
                      onClick={() => {
                        const datatorender = {
                          id: value.id,
                          info: value.info ? value.info : "",
                          isButton: value.isButton ? value.isButton : "false",
                          visible: value.visible ? value.visible : "true",
                          link: value.link ? value.link : "",
                          title: value.title ? value.title : "",
                          order: value.order ? value.order : "",
                          companyname: value.companyname
                            ? value.companyname
                            : "",
                          img: value.img ? value.img : "",
                        };
                        setWeek2(value.id);
                        setNew(false);
                        setSelectedItem3(datatorender);
                      }}
                      active={week2 === value.id}
                      title={value.title + " - " + value.order}
                      visible={value.visible ? value.visible : "true"}
                    />
                  ))}
            </div>
          )}
      </div>
      {
        // CATEGORY
        ((newCreate && type === "first") ||
          (Object.keys(selectedItem).length > 0 &&
            Object.keys(selectedItem2).length === 0 &&
            !newCreate)) && (
          <div className="mx-12">
            <TextField
              type="text"
              label="Id"
              name="id"
              value={selectedItem.id}
              disabled
            />
            <TextField
              type="text"
              label="Title"
              name="title"
              value={selectedItem.title}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.title = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Order"
              name="order"
              value={selectedItem.order}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.order = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            {newCreate && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CustomButton
                  type="submit"
                  className="w-1/4"
                  onClick={() => {
                    setNew(true);
                    setType("first");
                    setSelectedItem({ visible: "false" });
                    setTab(null);
                  }}
                >
                  Cancel
                </CustomButton>
                <div style={{ width: 20, height: 1 }} />
                <CustomButton
                  type="submit"
                  className="w-1/4"
                  onClick={() => createCategory()}
                >
                  Create
                </CustomButton>
              </div>
            )}
            {!newCreate && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CustomButton
                  red
                  type="submit"
                  className="w-1/4"
                  onClick={() => deleteCategory()}
                >
                  Delete
                </CustomButton>
                <div style={{ width: 20, height: 1 }} />
                <CustomButton
                  type="submit"
                  className="w-1/4"
                  onClick={() => save()}
                >
                  Save
                </CustomButton>
              </div>
            )}
          </div>
        )
      }
      {
        // ARTICLE
        ((newCreate && type === "second") ||
          (Object.keys(selectedItem2).length > 0 &&
            Object.keys(selectedItem3).length === 0 &&
            !newCreate)) && (
          <div className="mx-12">
            <TextField
              type="text"
              label="Id"
              name="id"
              value={selectedItem2.id}
              disabled
            />
            <TextField
              type="text"
              label="Title"
              name="title"
              value={selectedItem2.title}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem2 };
                selectedItemNew.title = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Summary"
              name="summary"
              value={selectedItem2.summary}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem2 };
                selectedItemNew.summary = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Order"
              name="order"
              value={selectedItem2.order}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem2 };
                selectedItemNew.order = event.target.value;
                setSelectedItem2(selectedItemNew);
              }}
            />
            {newCreate && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CustomButton
                  type="submit"
                  className="w-1/4"
                  onClick={() => {
                    selectedItem2({});
                    setNew(false);
                    setType("");
                  }}
                >
                  Cancel
                </CustomButton>
                <div style={{ width: 20, height: 1 }} />
                <CustomButton
                  type="submit"
                  className="w-1/4"
                  onClick={() => type === "second" && createArticle()}
                >
                  Create
                </CustomButton>
              </div>
            )}
            {!newCreate && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CustomButton
                  red
                  type="submit"
                  className="w-1/4"
                  onClick={() => deleteArticle()}
                >
                  Delete
                </CustomButton>
                <div style={{ width: 20, height: 1 }} />
                <CustomButton
                  type="submit"
                  className="w-1/4"
                  onClick={() => save2()}
                >
                  Save
                </CustomButton>
              </div>
            )}
          </div>
        )
      }
      {
        // ITEM
        ((newCreate && type === "third") ||
          (Object.keys(selectedItem3).length > 0 && !newCreate)) && (
          <div className="mx-12">
            <TextField
              type="text"
              label="Id"
              name="id"
              value={selectedItem3.id}
              disabled
            />
            <TextField
              type="text"
              label="Title"
              name="title"
              value={selectedItem3.title}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem3 };
                selectedItemNew.title = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <TextArea
              type="text"
              label="Info"
              name="info"
              value={selectedItem3.info}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem3 };
                selectedItemNew.info = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Link (hould start with 'https://')"
              name="link"
              value={selectedItem3.link}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem3 };
                selectedItemNew.link = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Company"
              name="company"
              value={selectedItem3.companyname}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem3 };
                selectedItemNew.companyname = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Product/Service"
              name="product"
              value={selectedItem3.product}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem3 };
                selectedItemNew.product = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Order"
              name="order"
              value={selectedItem3.order}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem3 };
                selectedItemNew.order = event.target.value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            <div className="flex flex-row">
              <div className="w-1/3" />
              <div className="flex flex-col items-start justify-start">
                <Checkbox
                  name="Mutasd button"
                  selected={selectedItem3.isButton}
                  getValue={(data) => {
                    var selectedItemNew = { ...selectedItem3 };
                    selectedItemNew.isButton = data;
                    setSelectedItem3(selectedItemNew);
                  }}
                />
                <Checkbox
                  name="Info button"
                  selected={selectedItem3.infoButton}
                  getValue={(data) => {
                    var selectedItemNew = { ...selectedItem3 };
                    selectedItemNew.infoButton = data;
                    setSelectedItem3(selectedItemNew);
                  }}
                />
                <Checkbox
                  name="Visible"
                  selected={selectedItem3.visible}
                  getValue={(data) => {
                    var selectedItemNew = { ...selectedItem3 };
                    selectedItemNew.visible = data;
                    setSelectedItem3(selectedItemNew);
                  }}
                />
              </div>
            </div>
            <ImageUp
              title="Ad Image (ratio: 2 / 1)"
              value={selectedItem3.img}
              parentCallback={() => {
                var selectedItemNew = { ...selectedItem3 };
                selectedItemNew.img = "";
                setSelectedItem3(selectedItemNew);
              }}
              getImg={(value) => {
                var selectedItemNew = { ...selectedItem3 };
                selectedItemNew.img = value;
                setSelectedItem3(selectedItemNew);
              }}
            />
            {newCreate && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CustomButton
                  type="submit"
                  className="w-1/4"
                  onClick={() => {
                    setSelectedItem3({});
                    setNew(false);
                    setType("");
                  }}
                >
                  Cancel
                </CustomButton>
                <div style={{ width: 20, height: 1 }} />
                <CustomButton
                  type="submit"
                  className="w-1/4"
                  onClick={() => type === "third" && createItems()}
                >
                  Create
                </CustomButton>
              </div>
            )}
            {!newCreate && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CustomButton
                  red
                  type="submit"
                  className="w-1/4"
                  onClick={() => deleteItems()}
                >
                  Delete
                </CustomButton>
                <div style={{ width: 20, height: 1 }} />
                <CustomButton
                  type="submit"
                  className="w-1/4"
                  onClick={() => save3()}
                >
                  Save
                </CustomButton>
              </div>
            )}
          </div>
        )
      }
    </Layout>
  );
};

export default Todo;
