/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getScreenHome } from "../../../redux/actions/analytics";
import { formatDate } from "../helper";
import Dropdown from "../../Dropdown";
import Spinner from "../../Spinner";

const HomeScreen = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const homeloading = useSelector(
    (state) => state.analytics.screenhome_loading
  );
  const home = useSelector((state) => state.analytics.screenhome);
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getScreenHome(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getScreenHome(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, [startDate, endDate]);

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-1/2 mt-8">
        <Dropdown
          value={selectedType}
          options={[
            { value: "", label: "All" },
            { value: "baby", label: "Baby" },
            { value: "mum", label: "Mum" },
          ]}
          onChange={(value) => setSelectedType(value.value)}
        />
      </div>
      <div>
        {homeloading && <Spinner />}
        {!homeloading && home.length !== 0 && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Week</th>
                  <th>Type</th>
                  <th>View</th>
                  <th>Percentage (view / all view)</th>
                  <th>Uniq</th>
                </tr>
              </thead>
              <tbody>
                {home.screens
                  .filter((item) =>
                    selectedType === "" ? item : item.type === selectedType
                  )
                  .filter((item) => item.week !== "40+")
                  .sort((a, b) => (Number(a.week) > Number(b.week) && 1) || -1)
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.week}</td>
                        <td>{item.type}</td>
                        <td>{item.pressed}</td>
                        <td>
                          {selectedType === "baby"
                            ? (
                                (Number(item.pressed) /
                                  Number(home.babytotal)) *
                                100
                              ).toFixed(2)
                            : selectedType === "mum"
                            ? (
                                (Number(item.pressed) / Number(home.mumtotal)) *
                                100
                              ).toFixed(2)
                            : (
                                (Number(item.pressed) / Number(home.total)) *
                                100
                              ).toFixed(2)}{" "}
                          %
                        </td>
                        <td>{item.userid.length}</td>
                      </tr>
                    );
                  })}
                {home.screens
                  .filter((item) =>
                    selectedType === "" ? item : item.type === selectedType
                  )
                  .filter((item) => item.week === "40+")
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.week}</td>
                        <td>{item.type}</td>
                        <td>{item.pressed}</td>
                        <td>
                          {selectedType === "baby"
                            ? (
                                (Number(item.pressed) /
                                  Number(home.babytotal)) *
                                100
                              ).toFixed(2)
                            : selectedType === "mum"
                            ? (
                                (Number(item.pressed) / Number(home.mumtotal)) *
                                100
                              ).toFixed(2)
                            : (
                                (Number(item.pressed) / Number(home.total)) *
                                100
                              ).toFixed(2)}{" "}
                          %
                        </td>
                        <td>{item.userid.length}</td>
                      </tr>
                    );
                  })}
                <tr className="font-bold">
                  <td>Baby SUM</td>
                  <td />
                  <td>{home.babytotal}</td>
                  <td />
                  <td>{home.babyuniq}</td>
                </tr>
                <tr className="font-bold">
                  <td>Mum SUM</td>
                  <td />
                  <td>{home.mumtotal}</td>
                  <td />
                  <td>{home.mumuniq}</td>
                </tr>
                <tr className="font-bold">
                  <td>Total SUM</td>
                  <td />
                  <td>{home.total}</td>
                  <td />
                  <td>{home.uniq}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeScreen;
