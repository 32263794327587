/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getScreenTodo,
  getScreenTodoItem,
} from "../../../redux/actions/analytics";
import { getAllData } from "../../../redux/actions/api";
import { formatDate, newPercentage } from "../helper";
import Dropdown from "../../Dropdown";
import Spinner from "../../Spinner";

const TodoScreen = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const todoloading = useSelector(
    (state) => state.analytics.screentodo_loading
  );
  const todo = useSelector((state) => state.analytics.screentodo);
  const tododata = useSelector((state) => state.api.data.todo);

  const todoitemloading = useSelector(
    (state) => state.analytics.screentodoitem_loading
  );
  const todoitem = useSelector((state) => state.analytics.screentodoitem);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [todoitemFilteredArray, setTodoitemFilteredArray] = useState([]);

  const TodoCategoryOptions = () => {
    if (tododata) {
      const articleselect = [{ value: "", label: "All" }];
      tododata.data.forEach((item) => {
        articleselect.push({ value: item.id, label: item.title });
      });
      return articleselect;
    }
    return [];
  };

  useEffect(() => {
    if (!tododata) {
      dispatch(getAllData());
    }
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getScreenTodo(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getScreenTodo(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && selectedCategory !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getScreenTodoItem(
          formatDate(startDate),
          formatDate(endDate),
          limit,
          skip
        )
      );
    }
  }, [startDate, endDate, selectedCategory]);

  useEffect(() => {
    if (
      startDate !== "" &&
      endDate !== "" &&
      selectedCategory !== "" &&
      todoitem &&
      todoitem.screens
    ) {
      let filtered = todoitem.screens.filter(
        (item) => item.categoryId === selectedCategory
      );
      newPercentage(filtered).then((element) =>
        setTodoitemFilteredArray(element)
      );
    }
  }, [startDate, endDate, selectedCategory, todoitem]);

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-1/2 mt-8">
        <Dropdown
          value={selectedCategory}
          options={TodoCategoryOptions()}
          onChange={(value) => setSelectedCategory(value.value)}
        />
      </div>
      <div>
        {todoloading && <Spinner />}
        {!todoloading && todo.length !== 0 && selectedCategory === "" && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Screen</th>
                  <th>View</th>
                  <th>Percentage (view / all view)</th>
                  <th>Uniq</th>
                </tr>
              </thead>
              <tbody>
                {todo.screens.map((item, index) => {
                  // filter data out if something selected
                  return (
                    <tr key={index}>
                      <td>{item.screen}</td>
                      <td>{item.pressed}</td>
                      <td>{item.percentage} %</td>
                      <td>{item.userid.length}</td>
                    </tr>
                  );
                })}
                <tr className="font-bold">
                  <td>Total for ToDo</td>
                  <td>{todo.total}</td>
                  <td />
                  <td>{todo.uniq}</td>
              </tr>
              </tbody>
            </table>
          </div>
        )}
        {todoitemloading && <Spinner />}
        {!todoitemloading &&
          todoitemFilteredArray.length !== 0 &&
          selectedCategory !== "" && (
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Subcategory</th>
                    <th>Screen</th>
                    <th>View (tick)</th>
                    <th>Percentage (view / all view)</th>
                    <th>Uniq</th>
                  </tr>
                </thead>
                <tbody>
                  {todoitemFilteredArray.screens.map((item, index) => {
                    // filter data out if something selected
                    return (
                      <tr key={index}>
                        <td>{item.subcategory}</td>
                        <td>{item.screen}</td>
                        <td>{item.pressed}</td>
                        <td>{item.percentage} %</td>
                        <td>{item.userid.length}</td>
                      </tr>
                    );
                  })}
                 <tr className="font-bold">
                  <td>Total for ToDo items</td>
                  <td />
                  <td>{todoitemFilteredArray.total}</td>
                  <td />
                  <td>{todoitemFilteredArray.uniq}</td>
                </tr>
                </tbody>
              </table>
            </div>
          )}
      </div>
    </div>
  );
};

export default TodoScreen;
