/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllData } from "../../../redux/actions/api";
import { getClickTodos } from "../../../redux/actions/analytics";
import { formatDate, newPercentage } from "../helper";
import Spinner from "../../Spinner";
import Dropdown from "../../Dropdown";

const TodoClick = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const todoloading = useSelector((state) => state.analytics.clicktodo_loading);
  const todo = useSelector((state) => state.analytics.clicktodo);
  const tododata = useSelector((state) => state.api.data.todo);

  const [loadFilter, setloadFilter] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [todoitemFilteredArray, setTodoitemFilteredArray] = useState([]);

  const TodoCategoryOptions = () => {
    if (tododata) {
      const articleselect = [{ value: "", label: "All" }];
      tododata.data.forEach((item) => {
        articleselect.push({ value: item.id, label: item.title });
      });
      return articleselect;
    }
    return [];
  };

  useEffect(() => {
    if (!tododata) {
      dispatch(getAllData());
    }
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getClickTodos(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getClickTodos(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedCategory !== "") {
      setloadFilter(true);
      if (
        startDate !== "" &&
        endDate !== "" &&
        selectedCategory !== "" &&
        todo &&
        todo.screens
      ) {
        let filtered = todo.screens.filter(
          (item) => item.categoryId === selectedCategory
        );
        newPercentage(filtered).then((element) => {
          setTodoitemFilteredArray(element);
          setloadFilter(false);
        });
      }
    }
  }, [startDate, endDate, selectedCategory, todo]);

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full flex flex-col items-center">
        <div className="w-1/2 mt-8">
          <Dropdown
            value={selectedCategory}
            options={TodoCategoryOptions()}
            onChange={(value) => setSelectedCategory(value.value)}
          />
        </div>
        {todoloading && <Spinner />}
        {loadFilter && <Spinner />}
        {!todoloading &&
          !loadFilter &&
          (selectedCategory === ""
            ? todo.length !== 0
            : todoitemFilteredArray.length !== 0) && (
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Product/Service</th>
                    <th>Todo Category</th>
                    <th>Screen</th>
                    <th>Clicked</th>
                    <th>Percentage (click / all click)</th>
                    <th>Uniq</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedCategory === ""
                    ? todo.screens.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.companyname}</td>
                            <td>{item.product}</td>
                            <td>{item.category}</td>
                            <td>{item.screen}</td>
                            <td>{item.pressed}</td>
                            <td>
                              {(
                                (Number(item.pressed) / Number(todo.total)) *
                                100
                              ).toFixed(2)}{" "}
                              %
                            </td>
                            <td>{item.userid.length}</td>
                          </tr>
                        );
                      })
                    : todoitemFilteredArray.screens.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.companyname}</td>
                            <td>{item.product}</td>
                            <td>{item.category}</td>
                            <td>{item.screen}</td>
                            <td>{item.pressed}</td>
                            <td>{item.percentage} %</td>
                            <td>{item.userid.length}</td>
                          </tr>
                        );
                      })}
                  <tr className="font-bold">
                  <td>SUM</td>
                  <td />
                  <td />
                  <td />
                  <td>{selectedCategory === ""
                    ? todo.total : todoitemFilteredArray.total}</td>
                  <td />
                  <td>{selectedCategory === ""
                    ? todo.uniq : todoitemFilteredArray.uniq}</td>
              </tr>
                </tbody>
              </table>
            </div>
          )}
      </div>
    </div>
  );
};

export default TodoClick;
