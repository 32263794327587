import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyAnalytics,
  getCompanyList,
  createNewCompany,
  setCompanyLoadedFalse,
} from "../../redux/actions/analytics";
import Layout from "../../components/layout/Layout";
import Dropdown from "../../components/Dropdown";
import DateSelector from "../../components/DateSelector";
import TextField from "../../components/TextField";
import CustomButton from "../../components/CustomButton";
import Spinner from "../../components/Spinner";
import CompanyAnalyticsTables from "../../components/analytics/CompanyAnalyticsTables";

const Company = () => {
  const dispatch = useDispatch();
  const companylist = useSelector((state) => state.analytics.companylist);
  const company_loading = useSelector(
    (state) => state.analytics.company_loading
  );
  const company_loaded = useSelector((state) => state.analytics.company_loaded);
  const [newcompany, setNewCompany] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [product, setProduct] = useState("");

  useEffect(() => {
    if (companylist.length === 0) {
      dispatch(getCompanyList());
    }
  }, [companylist]);

  return (
    <Layout active="companyanalytics">
      <div className="flex flex-col items-center justify-center mx-20">
        <div className="w-full flex flex-row items-center justify-center my-2">
          Company Analytics
        </div>
        <div className="w-1/2 mt-8">
          <Dropdown
            value={companyname}
            options={[
              ...companylist,
              { value: "new", label: "Add new company" },
            ]}
            onChange={(value) => {
              setCompanyname(value.value);
              dispatch(setCompanyLoadedFalse());
            }}
          />
          {companyname === "new" && (
            <div>
              <TextField
                type="text"
                label="New company name"
                name="company"
                value={newcompany}
                onChange={(e) => setNewCompany(e.target.value)}
              />
              <CustomButton
                type="submit"
                className="w-1/4 self-end"
                onClick={() => {
                  dispatch(createNewCompany(newcompany));
                }}
              >
                Create New Company
              </CustomButton>
            </div>
          )}
          <DateSelector
            startDate={startDate}
            endDate={endDate}
            startDateChanged={(date) => setStartDate(date)}
            endDateChanged={(date) => setEndDate(date)}
          />
          <TextField
            type="text"
            label="Product/service"
            name="product"
            value={product}
            onChange={(e) => setProduct(e.target.value)}
          />
          <CustomButton
            type="submit"
            className="w-1/4 self-end"
            onClick={() => {
              if (companyname === "") {
                return alert("First select a company!");
              }
              if (startDate === "" || endDate === "") {
                return alert("Please select a date range!");
              }
              dispatch(getCompanyAnalytics(companyname, startDate, endDate));
            }}
          >
            Search
          </CustomButton>
        </div>
        {company_loading && <Spinner />}
        {!company_loading && company_loaded && (
          <CompanyAnalyticsTables
            startDate={startDate}
            endDate={endDate}
            companyname={companyname}
            product={product}
          />
        )}
      </div>
    </Layout>
  );
};

export default Company;
