import axios from "axios";
import cookie from "react-cookies";
import { apiUrl } from "../../config/settings";
import { getAllData } from "./api";

export const login = (email, password) => (dispatch) => {
  const basicAuth = "Basic " + btoa(email + ":" + password);
  return axios
    .post(
      apiUrl + "/auth?access_token=G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      {},
      {
        headers: { Authorization: basicAuth },
      }
    )
    .then((response) => {
      cookie.save("token", response.data.token, { path: "/" });
      dispatch({ type: "LOGIN", data: response });
      dispatch({ type: "SAVE_TOKEN_FROM_COOKIE", data: response.data.token });
      dispatch(getAllData());
      dispatch(getMe());
      return response;
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "LOGIN_ERROR", data: "Hiba a belépésnél" });
        } else {
          dispatch({
            type: "LOGIN_ERROR",
            data: "Rossz felhasználónév vagy jelszó",
          });
        }
      }
      dispatch({
        type: "LOGIN_ERROR",
        data: "Rossz felhasználónév vagy jelszó",
      });
    });
};

export const getMe = () => async (dispatch, getState) => {
  return axios
    .get(apiUrl + "/users/me", {
      headers: { Authorization: "Bearer " + getState().state.token },
    })
    .then(async (response) => {
      await dispatch({ type: "GET_ME", data: response });
      dispatch(getAllData());
      return response.data;
    })
    .catch(function (error) {
      dispatch({ type: "GET_ME_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};
