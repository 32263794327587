import { combineReducers } from "redux";

import state from "./state";
import auth from "./auth";
import api from "./api";
import analytics from "./analytics";
import forms from "./forms";
import tools from "./tools";
import oldanalytics from "./oldanalytics";
import settings from "./settings";

const rootReducer = combineReducers({
  state,
  auth,
  api,
  analytics,
  forms,
  tools,
  oldanalytics,
  settings,
});

export default rootReducer;
