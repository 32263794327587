import format from "date-fns/format";

export function filterByDate(array, startDate, endDate) {
  function dateToNum(d) {
    // Convert date "26/06/2016" to 20160626
    d = d.split(".");
    return Number(d[0] + d[1] + d[2]);
  }
  if (startDate !== "" && endDate !== "") {
    let start = format(new Date(startDate), "yyyy.LL.dd");
    let end = format(new Date(endDate), "yyyy.LL.dd");
    return array
      .filter((item) => {
        if (start) {
          return (
            startDate && dateToNum(item.date.toString()) >= dateToNum(start)
          );
        } else {
          return item;
        }
      })
      .filter((item) => {
        if (end) {
          return dateToNum(item.date.toString()) <= dateToNum(end);
        } else {
          return item;
        }
      })
      .sort(function (a, b) {
        return dateToNum(a.date) - dateToNum(b.date);
      })
      .reverse();
  }
  return array;
}
