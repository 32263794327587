import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OLD_GETArticles } from "../../redux/actions/oldanalytics";
import DateSelector from "./DateSelector";
import Spinner from "../Spinner";

import { filterByDate } from "../../utils/filter";
import { dynamicSortMultiple } from "../../utils/sort";

const ArticleAnalytics = () => {
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.oldanalytics.articles);
  const loading = useSelector((state) => state.oldanalytics.articles_loading);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    dispatch(OLD_GETArticles());
  }, []);

  return (
    <div className="mx-12 flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />
      {loading && <Spinner />}
      {articles.length !== 0 &&
        filterByDate(articles, startDate, endDate).map((e) => {
          return (
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 50,
              }}
            >
              <h1>{e.date}</h1>
              <div
                style={{
                  background: "#F5F2EE",
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <table>
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Article</th>
                      <th>View</th>
                      <th>Percentage (view/all view)</th>
                      <th>Uniq</th>
                    </tr>
                  </thead>
                  <tbody>
                    {e.articles
                      .sort(dynamicSortMultiple("category", "article"))
                      .map((f) => {
                        return (
                          <tr>
                            <td>{f.category}</td>
                            <td>{f.article}</td>
                            <td>{f.pressed}</td>
                            <td>
                              {f.percentage}
                              {"%"}
                            </td>
                            <td>{f.uniq ? f.uniq : "0"}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ArticleAnalytics;
