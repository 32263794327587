import React from "react";
import CustomButton from '../../CustomButton'
const UserActivity = () => {
  return (
    <div className="w-full flex justify-center items-center mt-16">
      <CustomButton onClick={() => window.open('https://console.firebase.google.com/u/0/project/preg-app-1/overview', "_blank")}>
        Firebase console
        </CustomButton>
        <CustomButton onClick={() => window.open('https://appstoreconnect.apple.com/analytics/app/d30/1507950107/overview', "_blank")}>
        App store connect (IOS)
        </CustomButton>
        <CustomButton onClick={() => window.open('https://play.google.com/console/u/0/developers/7712405964058936567/app/4975574470102497858/statistics?metrics=ACTIVE_USERS-ALL-UNIQUE-PER_INTERVAL-DAY&dimension=COUNTRY&dimensionValues=&dateRange=2021_8_28-2021_9_26&growthRateDimensionValue=OVERALL&tab=APP_STATISTICS&ctpMetric=DAU_MAU-ACQUISITION_UNSPECIFIED-COUNT_UNSPECIFIED-CALCULATION_UNSPECIFIED-DAY&ctpDateRange=2021_8_28-2021_9_26&ctpDimension=COUNTRY&ctpDimensionValue=OVERALL', "_blank")}>
        Google play (Android)
        </CustomButton>
    </div>
  );
};

export default UserActivity;
