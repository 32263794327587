import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBabyBorn, updateBabyBorn } from "../../redux/actions/tools";
import CustomButton from "../../components/CustomButton";
import TextField from "../../components/TextField";
import TextArea from "../../components/TextArea";

const BabyIsBorn = () => {
  const dispatch = useDispatch();
  const babyisborn = useSelector((state) => state.tools.babyisborn);
  const [newInfotext, setNewInfoText] = useState(babyisborn.infotext || "");
  const [newSendButton, setNewSendButton] = useState(
    babyisborn.savebutton || ""
  );
  const [newShareButton, setNewShareButton] = useState(
    babyisborn.sharebutton || ""
  );

  useEffect(() => {
    dispatch(getBabyBorn());
  }, []);

  useEffect(() => {
    if (babyisborn) {
      setNewInfoText(babyisborn.infotext);
      setNewShareButton(babyisborn.sharebutton);
      setNewSendButton(babyisborn.savebutton);
    }
  }, [babyisborn]);

  return (
    <div>
      <div className="mx-auto pt-20 flex flex-col w-2/3">
        <TextArea
          type="text"
          label="Info text"
          name="infotext"
          value={newInfotext}
          onChange={(e) => setNewInfoText(e.target.value)}
        />
        <TextField
          type="text"
          label="Save Button text"
          name="savebutton"
          value={newSendButton}
          onChange={(e) => setNewSendButton(e.target.value)}
        />
        <TextField
          type="text"
          label="Share Button text"
          name="sharebutton"
          value={newShareButton}
          onChange={(e) => setNewShareButton(e.target.value)}
        />

        <CustomButton
          onClick={() =>
            dispatch(
              updateBabyBorn({ newInfotext, newSendButton, newShareButton })
            )
          }
        >
          Save
        </CustomButton>
      </div>
    </div>
  );
};

export default BabyIsBorn;
