import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setClickLoadingFalse } from "../../../redux/actions/analytics";
import Dropdown from "../../Dropdown";
import HomeClick from "./HomeClick";
import KnowledgeClick from "./KnowledgeClick";
import PhotographerClick from "./PhotographerClick";
import RecipeClick from "./RecipeClick";

const ArticleClick = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("");

  const articleoptions = [
    { value: "home", label: "Home" },
    { value: "knowledge", label: "Knowledge" },
    { value: "photographer", label: "Photographer" },
    { value: "recipe", label: "Recipe" },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-1/2">
        <Dropdown
          value={selectedType}
          options={articleoptions}
          onChange={(value) => {
            setSelectedType(value.value);
            dispatch(setClickLoadingFalse());
          }}
        />
      </div>
      {selectedType === "home" && (
        <HomeClick startDate={startDate} endDate={endDate} />
      )}
      {selectedType === "knowledge" && (
        <KnowledgeClick startDate={startDate} endDate={endDate} />
      )}
      {selectedType === "photographer" && (
        <PhotographerClick startDate={startDate} endDate={endDate} />
      )}
      {selectedType === "recipe" && (
        <RecipeClick startDate={startDate} endDate={endDate} />
      )}
    </div>
  );
};

export default ArticleClick;
