import axios from "axios";
import { getAllData } from "./api";
import { apiUrl, master_key } from "../../config/settings";

export const sendIntro = (intro) => (dispatch, getState) => {
  axios
    .put(
      apiUrl + "/intros/" + intro.id,
      {
        title: intro.title,
        summary: intro.summary,
        img: intro.img,
      },
      { headers: { Authorization: "Bearer " + master_key } }
    )
    .then((response) => {
      dispatch({ type: "UPDATE_INTRO" });
      alert("Updated");
      if (intro.type === "foodsafety") {
        dispatch(getFoodsafeties());
      }
      if (intro.type === "recipes") {
        dispatch(getRecipes());
      }
      if (intro.type === "faq") {
        dispatch(getFaqs());
      }
      if (intro.type === "compliants") {
        dispatch(getCompliants());
      }
      if (intro.type === "todo") {
        dispatch(getAllData());
      }
    })
    .catch(function (error) {
      alert("Update ERROR");
      alert(error);
      dispatch({ type: "UPDATE_INTRO_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const create = (selectedItem, url, type) => (dispatch, getState) => {
  axios
    .post(
      apiUrl + url,
      {
        ...selectedItem,
      },
      { headers: { Authorization: "Bearer " + master_key } }
    )
    .then((response) => {
      dispatch({ type: "UPDATE_" + url });
      alert("Updated");
      if (type === "foodsafety") {
        dispatch(getFoodsafeties());
      }
      if (type === "recipes") {
        dispatch(getRecipes());
      }
      if (type === "faq") {
        dispatch(getFaqs());
      }
      if (type === "compliants") {
        dispatch(getCompliants());
      }
      if (type === "photographer") {
        dispatch(getPhotographers());
      }
      if (type === "babyisborn") {
        dispatch(getBabyBorn());
      }
      if (type === "videos") {
        dispatch(getVideos());
      }
    })
    .catch(function (error) {
      alert("Update ERROR");
      dispatch({ type: "UPDATE_" + url + "_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const update = (selectedItem, url, type) => (dispatch, getState) => {
  axios
    .put(
      apiUrl + url,
      {
        ...selectedItem,
      },
      { headers: { Authorization: "Bearer " + master_key } }
    )
    .then((response) => {
      dispatch({ type: "UPDATE_" + url });
      alert("Updated");
      if (type === "foodsafety") {
        dispatch(getFoodsafeties());
      }
      if (type === "recipes") {
        dispatch(getRecipes());
      }
      if (type === "faq") {
        dispatch(getFaqs());
      }
      if (type === "compliants") {
        dispatch(getCompliants());
      }
      if (type === "photographer") {
        dispatch(getPhotographers());
      }
      if (type === "babyisborn") {
        dispatch(getBabyBorn());
      }
      if (type === "videos") {
        dispatch(getVideos());
      }
    })
    .catch(function (error) {
      alert("Update ERROR");
      dispatch({ type: "UPDATE_" + url + "_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const remove = (url, type) => (dispatch, getState) => {
  axios
    .delete(apiUrl + url, {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch({ type: "UPDATE_" + type });
      alert("Updated");
      if (type === "foodsafety") {
        dispatch(getFoodsafeties());
      }
      if (type === "recipes") {
        dispatch(getRecipes());
      }
      if (type === "faq") {
        dispatch(getFaqs());
      }
      if (type === "compliants") {
        dispatch(getCompliants());
      }
      if (type === "photographer") {
        dispatch(getPhotographers());
      }
      if (type === "babyisborn") {
        dispatch(getBabyBorn());
      }
      if (type === "videos") {
        dispatch(getVideos());
      }
    })
    .catch(function (error) {
      alert("Update ERROR");
      dispatch({ type: "UPDATE_" + type + "_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

// FOODSAFETY
export const getFoodsafeties = () => (dispatch) => {
  return axios
    .get(apiUrl + "/foodsafety", {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch({ type: "GET_FOODSAFETY", data: response.data });
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        return null;
      }
      dispatch({ type: "GET_FOODSAFETY_ERROR" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
      return [];
    });
};

// FAQ
export const getFaqs = () => (dispatch) => {
  return axios
    .get(apiUrl + "/faqs", {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch({ type: "GET_FAQ", data: response.data });
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        return null;
      }
      dispatch({ type: "GET_FAQ_ERROR" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
      return [];
    });
};

// COMPLIANTS
export const getCompliants = () => (dispatch) => {
  return axios
    .get(apiUrl + "/compliants", {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch({ type: "GET_COMPLIANTS", data: response.data });
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        return null;
      }
      dispatch({ type: "GET_COMPLIANTS_ERROR" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
      return [];
    });
};

// PHOTOGRAPHER
export const getPhotographers = () => (dispatch) => {
  return axios
    .get(apiUrl + "/photographers", {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch({ type: "GET_PHOTOGRAPHERS", data: response.data });
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        return null;
      }
      dispatch({ type: "GET_PHOTOGRAPHERS_ERROR" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
      return [];
    });
};

// RECIPES
export const getRecipes = () => (dispatch) => {
  return axios
    .get(apiUrl + "/recipes", {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch({ type: "GET_RECIPES", data: response.data });
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        return null;
      }
      dispatch({ type: "GET_RECIPES_ERROR" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
      return [];
    });
};

// BABY IS BORN
export const getBabyBorn = () => (dispatch) => {
  return axios
    .get(apiUrl + "/babyisborn", {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch({ type: "GET_BABYISBORN", data: response.data });
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        return null;
      }
      dispatch({ type: "GET_BABYISBORN_ERROR" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
      return [];
    });
};

export const updateBabyBorn =
  ({ newInfotext, newSendButton, newShareButton }) =>
  (dispatch, getState) => {
    axios
      .put(
        apiUrl + "/babyisborn",
        {
          infotext: newInfotext,
          sharebutton: newShareButton,
          savebutton: newSendButton,
        },
        { headers: { Authorization: "Bearer " + master_key } }
      )
      .then((response) => {
        dispatch({ type: "UPDATE_BABYISBORN" });
        alert("Updated");
        dispatch(getBabyBorn());
      })
      .catch(function (error) {
        alert("Update ERROR");
        alert(error);
        dispatch({ type: "UPDATE_BABYISBORN_ERROR" });
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
      });
  };

// VIDEOS
export const getVideos = () => (dispatch) => {
  return axios
    .get(apiUrl + "/videos", {
      headers: { Authorization: "Bearer " + master_key },
    })
    .then((response) => {
      dispatch({ type: "GET_VIDEOS", data: response.data });
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        return null;
      }
      dispatch({ type: "GET_VIDEOS_ERROR" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
      return [];
    });
};
