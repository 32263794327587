import React, { Component } from "react";
import yes from "../assets/yes.svg";
import no from "../assets/no.svg";

export default class Checkbox extends Component {
  checkBox = (e) => {
    e.preventDefault();
    var selectednew = this.props.bool
      ? this.props.selected === true
        ? false
        : true
      : this.props.selected === "true"
      ? "false"
      : "true";
    this.props.getValue(selectednew);
  };

  render() {
    return (
      <div className="flex flex-row justify-center items-center my-2">
        <button
          className="flex w-4 h-4 mr-2 outline-none focus:outline-none"
          onClick={(e) => this.checkBox(e)}
        >
          {this.props.bool ? (
            this.props.selected === true ? (
              <img src={yes} alt="Y" className="h-4 w-4" />
            ) : (
              <img src={no} alt="N" className="h-4 w-4" />
            )
          ) : this.props.selected === "true" ? (
            <img src={yes} alt="Y" className="h-4 w-4" />
          ) : (
            <img src={no} alt="N" className="h-4 w-4" />
          )}
        </button>
        <div>{this.props.name}</div>
      </div>
    );
  }
}
