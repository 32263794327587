/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllData,
  update,
  sendNotification,
} from "../../redux/actions/api";
import Layout from "../../components/layout/Layout";
import CustomButton from "../../components/CustomButton";
import MenuButton from "../../components/MenuButton";
import TextField from "../../components/TextField";
import TextArea from "../../components/TextArea";
import Dropdown from "../../components/Dropdown";

const Notification = () => {
  const dispatch = useDispatch();
  const apidata = useSelector((state) => state.api.data);
  const [data, setData] = useState([]);
  const [tab, setTab] = useState(0);

  const [week, setWeek] = useState(0);
  const [selectedItem, setSelectedItem] = useState(0);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [link, setLink] = useState(null);

  useEffect(() => {
    dispatch(getAllData());
    setSelectedItem({});
    if (Object.keys(apidata).length > 0) {
      setData(apidata.notifications);
    }
  }, []);

  useEffect(() => {
    setSelectedItem({});
    if (Object.keys(apidata).length > 0) {
      setData(apidata.notifications);
    }
  }, [apidata]);

  const save = () => {
    const selected = selectedItem;
    const url = "/notifications/" + selectedItem.id;
    dispatch(update(selected, url));
  };

  const WeekOptions = () => {
    const weekselect = [];
    for (let index = 0; index < 42; index++) {
      if (index === 41) {
        weekselect.push({ value: index, label: "40+" });
      } else {
        weekselect.push({ value: index, label: index });
      }
    }
    return weekselect;
  };

  const renderNotiSend = () => {
    return (
      <div>
        <TextField
          type="text"
          label="Title (20 character)"
          name="title"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
        />
        <TextArea
          type="text"
          label="Body (107 character)"
          name="body"
          value={body}
          onChange={(event) => setBody(event.target.value)}
        />
        <TextField
          type="text"
          label="Link"
          name="link"
          value={link}
          onChange={(event) => setLink(event.target.value)}
        />
        <div>
          Sample link: https://www.google.com/ or preghello://app
        </div>
        <div className="w-full flex flex-row items-center justify-center mb-2">
          <div className="w-1/3">From (Week)</div>
          <div className="w-2/3">
            <Dropdown
              value={from}
              options={WeekOptions()}
              onChange={(value) => setFrom(value.value)}
            />
          </div>
        </div>

        <div className="w-full flex flex-row items-center justify-center mb-2">
          <div className="w-1/3">To (Week)</div>
          <div className="w-2/3">
            <Dropdown
              value={to}
              options={WeekOptions()}
              onChange={(value) => setTo(value.value)}
            />
          </div>
        </div>

        <CustomButton
          type="submit"
          className="w-full"
          onClick={
            () => dispatch(sendNotification(
                from,
                to,
                body,
                title,
                link
              ))
          }
        >
          Send
        </CustomButton>
      </div>
    );
  };

  const renderWeeklyNoti = () => {
    return (
      <div>
        <div
          style={{
            width: "100%",
            marginTop: 40,
            marginBottom: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "80%", display: "flex", flexWrap: "wrap" }}>
            {data
              .sort((a, b) => (a.week > b.week && 1) || -1)
              .map((value, index) => (
                <MenuButton
                  key={index}
                  onClick={() => {
                    setWeek(index + 1);
                    setSelectedItem(value);
                  }}
                  active={week === index + 1}
                  title={value.week + ".week"}
                  visible="true"
                />
              ))}
          </div>
        </div>
        {Object.keys(selectedItem).length > 0 && (
          <div className="mx-12">
            <TextField
              type="text"
              label="Id"
              name="id"
              value={selectedItem.id}
              disabled
            />
            <TextArea
              type="text"
              label="Message"
              name="summary"
              value={selectedItem.summary}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.summary = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Week"
              name="week"
              value={selectedItem.week}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.week = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton
                type="submit"
                className="w-20"
                onClick={() => save()}
              >
                Save
              </CustomButton>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Layout active="notification">
      <div className="mx-12 mt-12">
        <div className="flex flex-row items-center justify-center">
          <MenuButton
            onClick={() => {
              setTab(1);
            }}
            active={tab === 1}
            title="Send new"
            visible="true"
          />
          <MenuButton
            onClick={() => {
              setTab(2);
              setWeek(0);
              setSelectedItem({});
            }}
            active={tab === 2}
            title="Edit weekly"
            visible="true"
          />
        </div>
        {tab === 1 && <div className="mt-12">{renderNotiSend()}</div>}
        {tab === 2 && <div className="mt-12">{renderWeeklyNoti()}</div>}
      </div>
    </Layout>
  );
};

export default Notification;
