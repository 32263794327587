/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getClickHome } from "../../../redux/actions/analytics";
import { formatDate } from "../helper";
import Spinner from "../../Spinner";
import Dropdown from "../../Dropdown";

const HomeClick = ({ startDate, endDate }) => {
  const [selectedType, setSelectedType] = useState("");
  const dispatch = useDispatch();
  const homeloading = useSelector((state) => state.analytics.clickhome_loading);
  const home = useSelector((state) => state.analytics.clickhome);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getClickHome(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getClickHome(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, [startDate, endDate]);

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-1/2 mt-8">
        <Dropdown
          value={selectedType}
          options={[
            { value: "", label: "All" },
            { value: "baby", label: "Baby" },
            { value: "mum", label: "Mum" },
          ]}
          onChange={(value) => setSelectedType(value.value)}
        />
      </div>
      {homeloading && <Spinner />}
      {!homeloading && home.length !== 0 && (
        <div>
          <table>
            <thead>
              <tr>
                <th>Company</th>
                <th>Product/Service</th>
                <th>Week</th>
                <th>Type</th>
                <th>Clicked</th>
                <th>Percentage (click / all click)</th>
                <th>Uniq</th>
              </tr>
            </thead>
            <tbody>
              {home.screens
                .filter((item) =>
                  selectedType === "" ? item : item.type === selectedType
                )
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.companyname}</td>
                      <td>{item.product}</td>
                      <td>{item.week}</td>
                      <td>{item.type}</td>
                      <td>{item.pressed}</td>
                      <td>
                        {selectedType === "baby"
                          ? (
                              (Number(item.pressed) / Number(home.babytotal)) *
                              100
                            ).toFixed(2)
                          : selectedType === "mum"
                          ? (
                              (Number(item.pressed) / Number(home.mumtotal)) *
                              100
                            ).toFixed(2)
                          : (
                              (Number(item.pressed) / Number(home.total)) *
                              100
                            ).toFixed(2)}{" "}
                        %
                      </td>
                      <td>{item.userid.length}</td>
                    </tr>
                  );
                })}
              <tr className="font-bold">
                <td>SUM Baby</td>
                <td />
                <td />
                <td />
                <td>{home.babytotal}</td>
                <td />
                <td>{home.babyuniq}</td>
              </tr>
              <tr className="font-bold">
                <td>SUM Mum</td>
                <td />
                <td />
                <td />
                <td>{home.mumtotal}</td>
                <td />
                <td>{home.mumuniq}</td>
              </tr>
              <tr className="font-bold">
                <td>SUM</td>
                <td />
                <td />
                <td />
                <td>{home.total}</td>
                <td />
                <td>{home.uniq}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default HomeClick;
