import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSettings, updateSettings } from "../../redux/actions/settings";
import { getBabyBorn } from "../../redux/actions/tools";
import Layout from "../../components/layout/Layout";
import CustomButton from "../../components/CustomButton";
import MenuButton from "../../components/MenuButton";
import BabyIsBorn from "../../components/tools/BabyIsBorn";
import HTMLEditor from "../../components/HTMLEditor";

const Settings = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.settings);
  const [showTab, setShowTab] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const [html2Content, setHtml2Content] = useState("");
  const [html3Content, setHtml3Content] = useState("");

  useEffect(() => {
    dispatch(getSettings());
    dispatch(getBabyBorn());
  }, []);

  useEffect(() => {
    if (settings) {
      setHtmlContent(settings.aboutText);
      setHtml2Content(settings.privacyText);
      setHtml3Content(settings.dataprivacy);

    }
  }, [settings]);

  const save = (type) => {
    let selected = {
      aboutText: htmlContent,
      privacyText: html2Content,
      dataprivacy: html3Content
    };
    dispatch(updateSettings(selected, type));
  };

  return (
    <Layout active="settings">
      <div className="flex flex-row mx-12">
        <MenuButton
          onClick={() => setShowTab("policies")}
          active={showTab === "policies"}
          title="Policies"
          visible="true"
        />
        <MenuButton
          onClick={() => setShowTab("babyisborn")}
          active={showTab === "babyisborn"}
          title="Baby is born"
          visible="true"
        />
      </div>
      {showTab === "babyisborn" && <BabyIsBorn />}
      {showTab === "policies" && (
        <div className="mx-24">
          <div className="flex flex-row w-full items-center justify-between mr-12">
            <div className="w-1/4" />
            <div>About</div>
            <div className="mr-4">
              <CustomButton
                type="submit"
                className="w-1/4"
                onClick={() => save("about")}
              >
                Save
              </CustomButton>
              <CustomButton
                red
                type="submit"
                className="w-1/4"
                onClick={() => {
                  setHtmlContent(settings.aboutText);
                }}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
          <HTMLEditor
              dontshowImage
                defaultValue={htmlContent}
                onChange={(content) => {
                  setHtmlContent(content);
                }}
              />
          
          <div className="flex flex-row mt-20 mb-4 w-full items-center justify-between mr-12">
            <div className="w-1/4" />
            <div>Terms and condition</div>
            <div className="mr-4">
              <CustomButton
                type="submit"
                className="w-1/4"
                onClick={() => save("privacy")}
              >
                Save
              </CustomButton>
              <CustomButton
                red
                type="submit"
                className="w-1/4"
                onClick={() => {
                  setHtmlContent(settings.privacyText);

                }}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
          <HTMLEditor
          dontshowImage
                defaultValue={html2Content}
                onChange={(content) => {
                  setHtml2Content(content);
                }}
              />
          
          
          <div className="flex flex-row mt-20 mb-4 w-full items-center justify-between mr-12">
            <div className="w-1/4" />
            <div>Data Privacy Policy</div>
            <div className="mr-4">
              <CustomButton
                type="submit"
                className="w-1/4"
                onClick={() => save("data")}
              >
                Save
              </CustomButton>
              <CustomButton
                red
                type="submit"
                className="w-1/4"
                onClick={() => {
                  setHtmlContent(settings.dataprivacy);

                }}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
          <HTMLEditor
          dontshowImage
                defaultValue={html3Content}
                onChange={(content) => {
                  setHtml3Content(content);
                }}
              />
        </div>
      )}
    </Layout>
  );
};

export default Settings;
