export const initialState = {
  list: [],
};

const forms = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FORMS":
      return {
        ...state,
        list: action.data,
      };
    default:
      return state;
  }
};

export default forms;
