import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { sendIntro } from "../../redux/actions/tools";
import TextField from "../TextField";
import ImageUp from "../ImageUp";
import CustomButton from "../CustomButton";
import MenuButton from "../MenuButton";
import TextArea from "../TextArea";

const IntroForm = ({ intro }) => {
  const dispatch = useDispatch();
  const [showIntro, setShowIntro] = useState(false);

  const [id, setId] = useState(null);
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [img, setImg] = useState("");
  const [type, setType] = useState("");

  const saveIntro = () => {
    const intro = {
      id,
      title,
      summary,
      img,
      type,
    };
    dispatch(sendIntro(intro));
  };

  useEffect(() => {
    if (intro) {
      setId(intro.id);
      setTitle(intro.title);
      setSummary(intro.summary);
      setImg(intro.img);
      setType(intro.type);
    }
  }, []);

  return (
    <div className="p-4 m-4">
      <MenuButton
        onClick={() => setShowIntro(!showIntro)}
        active={showIntro}
        title="Show intro"
        visible="true"
      />
      {showIntro && (
        <div>
          <TextField
            type="text"
            label="Title"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <ImageUp
            title="Cover Image (ratio: 1 / 1)"
            value={img}
            parentCallback={() => {
              setImg("");
            }}
            getImg={(value) => {
              setImg(value);
            }}
          />
          <TextArea
            type="text"
            label="Summary"
            name="summary"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
          />
          <CustomButton
            type="submit"
            className="w-1/4"
            onClick={() => saveIntro()}
          >
            Save intro text
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default IntroForm;
