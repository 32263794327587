import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formatDate } from "./helper";
import MenuButton from "../MenuButton";
import { CSVLink } from "react-csv";

const CompanyAnalyticsTables = ({
  startDate,
  endDate,
  companyname,
  product,
}) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.analytics.company);
  const [showTab, setShowTab] = useState("");
  const [all, setAll] = useState("");

  const headers = [
    { label: "Type", key: "analyticstype" },
    { label: "Product/Service", key: "product" },
    { label: "Category", key: "category" },
    { label: "SubCategory", key: "subcategory" },
    { label: "Screen", key: "screen" },
    { label: "Clicked", key: "pressed" },
    { label: "Uniq", key: "uniq" },
    { label: "Impression", key: "impression" },
    { label: "CTR", key: "ctr" },
  ];

  useEffect(() => {
    if (data) {
      const all = [];

      const coupons = data.coupons.map((item) => item);
      coupons.forEach((item) => {
        item.category = item.screen;
        item.analyticstype = "Coupon";
        item.ctr =
          ((Number(item.pressed) / Number(item.impression)) * 100).toFixed(2) +
          " %";
        item.screen = "";
      });

      const todos = data.todo.map((item) => item);
      todos.forEach((item) => {
        item.analyticstype = "Todo item";
        item.ctr =
          ((Number(item.pressed) / Number(item.impression)) * 100).toFixed(2) +
          " %";
      });

      const home = data.home.map((item) => item);
      home.forEach((item) => {
        item.category = item.week + ". week";
        item.subcategory = item.type;
        item.analyticstype = "Home article";
        item.ctr =
          ((Number(item.pressed) / Number(item.impression)) * 100).toFixed(2) +
          " %";
      });

      const knowledge = data.knowledge.map((item) => item);
      knowledge.forEach((item) => {
        item.category = item.screen;
        item.analyticstype = "Knowledge Category";
        item.ctr =
          ((Number(item.pressed) / Number(item.impression)) * 100).toFixed(2) +
          " %";
        item.screen = "";
      });

      const article = data.article.map((item) => item);
      article.forEach((item) => {
        item.analyticstype = "Knowledge Article";
        item.ctr =
          ((Number(item.pressed) / Number(item.impression)) * 100).toFixed(2) +
          " %";
      });
      all.push(...coupons, ...todos, ...home, ...knowledge, ...article);
      setAll(all);
    }
  }, [data]);

  return (
    <div className="w-full flex flex-col items-center">
      <CSVLink
        className="bg-orange text-white p-2 rounded mb-4"
        filename={`${companyname}-${formatDate(startDate)}--${formatDate(
          endDate
        )}.csv`}
        data={all}
        headers={headers}
      >
        Download all data
      </CSVLink>

      <div className="w-full flex flex-row items-center justify-center my-2">
        <MenuButton
          onClick={() => setShowTab("all")}
          active={showTab === "all"}
          title="All"
          visible="true"
        />
        <MenuButton
          onClick={() => setShowTab("coupon")}
          active={showTab === "coupon"}
          title="Coupon"
          visible="true"
        />
        <MenuButton
          onClick={() => setShowTab("todo")}
          active={showTab === "todo"}
          title="Todo"
          visible="true"
        />
        <MenuButton
          onClick={() => setShowTab("links")}
          active={showTab === "links"}
          title="Links"
          visible="true"
        />
      </div>
      {/** TODO: FILTER TO PRODUCT !!! */}
      {showTab === "coupon" && (
        <div>
          {data.coupons.length === 0 && (
            <p>There are no Coupon analytics for this company</p>
          )}
          {data.coupons.length !== 0 && (
            <table>
              <thead>
                <tr>
                  <th>P/S</th>
                  <th>Category</th>
                  <th>Clicked</th>
                  <th>Uniq</th>
                  <th>Impression</th>
                  <th>CTR</th>
                </tr>
              </thead>
              <tbody>
                {data.coupons
                  .filter(
                    (x) =>
                      (x.product &&
                        x.product
                          .toLowerCase()
                          .includes(product.toLowerCase())) ||
                      !product
                  )
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.product}</td>
                        <td>{item.category}</td>
                        <td>{item.pressed}</td>
                        <td>{item.userid.length}</td>
                        <td>{item.impression}</td>
                        <td>
                          {(
                            (Number(item.pressed) / Number(item.impression)) *
                            100
                          ).toFixed(2)}{" "}
                          %
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      )}
      {showTab === "todo" && (
        <div>
          {data.coupons.length === 0 && (
            <p>There are no Todo analytics for this company</p>
          )}
          {data.todo.length !== 0 && (
            <table>
              <thead>
                <tr>
                  <th>P/S</th>
                  <th>Category</th>
                  <th>Subcategory</th>
                  <th>Screen</th>
                  <th>Clicked</th>
                  <th>Uniq</th>
                  <th>Impression</th>
                  <th>CTR</th>
                </tr>
              </thead>
              <tbody>
                {data.todo
                  .filter(
                    (x) =>
                      (x.product &&
                        x.product
                          .toLowerCase()
                          .includes(product.toLowerCase())) ||
                      !product
                  )
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.product}</td>
                        <td>{item.category}</td>
                        <td>{item.subcategory}</td>

                        <td>{item.screen}</td>
                        <td>{item.pressed}</td>
                        <td>{item.userid.length}</td>
                        <td>{item.impression}</td>
                        <td>
                          {(
                            (Number(item.pressed) / Number(item.impression)) *
                            100
                          ).toFixed(2)}{" "}
                          %
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      )}
      {showTab === "links" && (
        <div>
          {data.home.length === 0 &&
            data.knowledge.length === 0 &&
            data.article.length === 0 && (
              <p>There are no link analytics for this company</p>
            )}
          {(data.home.length !== 0 ||
            data.knowledge.length !== 0 ||
            data.article.length !== 0) && (
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>P/S</th>
                  <th>Category</th>
                  <th>Subcategory</th>
                  <th>Screen</th>
                  <th>Clicked</th>
                  <th>Uniq</th>
                  <th>Impression</th>
                  <th>CTR</th>
                </tr>
              </thead>
              <tbody>
                {data.home
                  .filter(
                    (x) =>
                      (x.product &&
                        x.product
                          .toLowerCase()
                          .includes(product.toLowerCase())) ||
                      !product
                  )
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>Home link</td>
                        <td>{item.product}</td>
                        <td>{item.week}</td>
                        <td>{item.type}</td>
                        <td>{}</td>
                        <td>{item.pressed}</td>
                        <td>{item.userid.length}</td>
                        <td>{item.impression}</td>
                        <td>
                          {(
                            (Number(item.pressed) / Number(item.impression)) *
                            100
                          ).toFixed(2)}{" "}
                          %
                        </td>
                      </tr>
                    );
                  })}
                {data.knowledge
                  .filter(
                    (x) =>
                      (x.product &&
                        x.product
                          .toLowerCase()
                          .includes(product.toLowerCase())) ||
                      !product
                  )
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>Knowledge Category</td>
                        <td>{item.product}</td>
                        <td>{item.category}</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{item.pressed}</td>
                        <td>{item.userid.length}</td>
                        <td>{item.impression}</td>
                        <td>
                          {(
                            (Number(item.pressed) / Number(item.impression)) *
                            100
                          ).toFixed(2)}{" "}
                          %
                        </td>
                      </tr>
                    );
                  })}
                {data.article
                  .filter(
                    (x) =>
                      (x.product &&
                        x.product
                          .toLowerCase()
                          .includes(product.toLowerCase())) ||
                      !product
                  )
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>Article link</td>
                        <td>{item.product}</td>
                        <td>{item.category}</td>
                        <td>{item.subcategory}</td>
                        <td>{item.screen}</td>
                        <td>{item.pressed}</td>
                        <td>{item.userid.length}</td>
                        <td>{item.impression}</td>
                        <td>
                          {(
                            (Number(item.pressed) / Number(item.impression)) *
                            100
                          ).toFixed(2)}{" "}
                          %
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      )}
      {showTab === "all" && (
        <div>
          {all.length === 0 && (
            <p>
              There are no analytics for this company in the given date range
            </p>
          )}
          {all.length !== 0 && (
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>P/S</th>
                  <th>Category</th>
                  <th>Subcategory</th>
                  <th>Screen</th>
                  <th>Clicked</th>
                  <th>Uniq</th>
                  <th>Impression</th>
                  <th>CTR</th>
                </tr>
              </thead>
              <tbody>
                {all
                  .filter(
                    (x) =>
                      (x.product &&
                        x.product
                          .toLowerCase()
                          .includes(product.toLowerCase())) ||
                      !product
                  )
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.analyticstype}</td>
                        <td>{item.product}</td>
                        <td>{item.category}</td>
                        <td>{item.subcategory}</td>
                        <td>{item.screen}</td>
                        <td>{item.pressed}</td>
                        <td>{item.userid.length}</td>
                        <td>{item.impression}</td>
                        <td>
                          {(
                            (Number(item.pressed) / Number(item.impression)) *
                            100
                          ).toFixed(2)}{" "}
                          %
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default CompanyAnalyticsTables;
