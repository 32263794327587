import React, { useState } from "react";
import Layout from "../../components/layout/Layout";
import MenuButton from "../../components/MenuButton";
import ScreenAnalytics from "../../components/analytics/screen";
import LinkAnalytics from "../../components/analytics/link";
import ABTests from "../../components/analytics/ABTests";
import PopupAnalytics from "../../components/analytics/PopupAnalytics";

const Contents = () => {
  const [showTab, setShowTab] = useState("");
  return (
    <Layout active="contentanalytics">
      <div className="flex flex-col items-center justify-center mx-20">
        <div className="w-full flex flex-row items-center justify-center my-2">
          Content Analytics
        </div>
        <div className="w-full flex flex-row items-center justify-center my-2">
          <MenuButton
            onClick={() => {
              setShowTab("");
              setTimeout(() => {
                setShowTab("screens");
              }, 500);
            }}
            active={showTab === "screens"}
            title="Content Views"
            visible="true"
          />
          <MenuButton
            onClick={() => {
              setShowTab("");
              setTimeout(() => {
                setShowTab("links");
              }, 500);
            }}
            active={showTab === "links"}
            title="Clicks"
            visible="true"
          />
          <MenuButton
            onClick={() => {
              setShowTab("");
              setTimeout(() => {
                setShowTab("ab");
              }, 500);
            }}
            active={showTab === "ab"}
            title="AB Tests"
            visible="true"
          />
          <MenuButton
            onClick={() => {
              setShowTab("");
              setTimeout(() => {
                setShowTab("popup");
              }, 500);
            }}
            active={showTab === "popup"}
            title="Popup"
            visible="true"
          />
        </div>
        {showTab === "screens" && <ScreenAnalytics />}
        {showTab === "links" && <LinkAnalytics />}
        {showTab === "ab" && <ABTests />}
        {showTab === "popup" && <PopupAnalytics />}
      </div>
    </Layout>
  );
};

export default Contents;
