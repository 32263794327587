import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createScrolls,
  deleteScrolls,
  getAllData,
  getScrolls,
  getScrollsAnalytics,
  updateScrolls,
} from "../redux/actions/api";
import DatePicker from "react-datepicker";
import ImageUp from "./ImageUp";
import ScrollBox from "./ScrollBox";
import Spinner from "./Spinner";
import { dynamicSortMultiple } from "../utils/sort";
import { filterByDate } from "../utils/filter";

// img
import plus from "../assets/plus.png";
import boxtype1 from "../assets/scrolltypes/boxtype1.JPG";
import boxtype10 from "../assets/scrolltypes/boxtype10.JPG";
import boxtype11 from "../assets/scrolltypes/boxtype11.JPG";
import boxtype12 from "../assets/scrolltypes/boxtype12.JPG";
import boxtype13 from "../assets/scrolltypes/boxtype13.JPG";
import boxtype14 from "../assets/scrolltypes/boxtype14.JPG";
import boxtype15 from "../assets/scrolltypes/boxtype15.JPG";
import boxtype16 from "../assets/scrolltypes/boxtype16.JPG";
import boxtype17 from "../assets/scrolltypes/boxtype17.JPG";
import boxtype18 from "../assets/scrolltypes/boxtype18.JPG";
import boxtype19 from "../assets/scrolltypes/boxtype19.JPG";
import boxtype2 from "../assets/scrolltypes/boxtype2.JPG";
import boxtype20 from "../assets/scrolltypes/boxtype20.JPG";
import boxtype3 from "../assets/scrolltypes/boxtype3.JPG";
import boxtype4 from "../assets/scrolltypes/boxtype4.JPG";
import boxtype5 from "../assets/scrolltypes/boxtype5.JPG";
import boxtype6 from "../assets/scrolltypes/boxtype6.JPG";
import boxtype7 from "../assets/scrolltypes/boxtype7.JPG";
import boxtype8 from "../assets/scrolltypes/boxtype8.JPG";
import boxtype9 from "../assets/scrolltypes/boxtype9.JPG";

class Scrolls extends Component {
  constructor() {
    super();
    this.state = {
      type: "create",
      tab: "weeks",
      weektab: 0,
      order: "",
      categorytitle: "",
      headline: "",
      selectedItem: {},
      markdown: "",
      modalIsOpen: false,
      scrolls: true,
      title: "",
      img: "",
      url: null,
      articleId: null,
      categoryId: null,
      dealId: null,
      selectedArticle: "",
      deallabel: "",
      label: "",
      startDate: "",
      endDate: "",
    };
  }

  componentWillMount() {
    this.popupData();
  }

  async popupData() {
    await this.props.getScrolls();
    // await this.props.getScrollsAnalytics();
    await this.props.getAllData();
  }

  createNewScrolls() {
    const week = this.props.scrolls[this.state.weektab].week;
    const scrollweek = this.props.scrolls[this.state.weektab].id;
    const type = this.state.selectedItem.type;
    const boxtype = this.state.selectedItem.boxtype;
    const order = this.state.selectedItem.order;
    const categorytitle = this.state.selectedItem.categorytitle;
    const img = this.state.selectedItem.img;
    const headline = this.state.selectedItem.headline;
    const text = this.state.selectedItem.text;
    const link = this.state.selectedItem.link;
    const tool = this.state.selectedItem.tool;
    const articleId = this.state.selectedItem.articleId;
    const categoryId = this.state.selectedItem.categoryId;
    const dealId = this.state.selectedItem.dealId;
    if (this.state.type === "create") {
      this.props.createScrolls(
        week,
        scrollweek,
        type,
        boxtype,
        order,
        categorytitle,
        headline,
        text,
        img,
        link,
        tool,
        articleId,
        categoryId,
        dealId
      );
    }
    if (this.state.type === "update") {
      const id = this.state.selectedItem.id;
      this.props.updateScrolls(
        id,
        week,
        scrollweek,
        type,
        boxtype,
        order,
        categorytitle,
        headline,
        text,
        img,
        link,
        tool,
        articleId,
        categoryId,
        dealId
      );
    }
    this.setState({
      type: "create",
      selectedItem: {
        id: "",
        title: "",
        week: "",
        scrollweek: "",
        type: null,
        boxtype: "",
        order: "",
        categorytitle: "",
        headline: "",
        text: "",
        img: "",
        url: null,
        articleId: null,
        categoryId: null,
        dealId: null,
        selectedArticle: "",
        deallabel: "",
        label: "",
        tool: "",
      },
    });
  }

  setArticleAndCategoryID(event) {
    event.preventDefault();
    var selectedItem = { ...this.state.selectedItem };
    selectedItem.articleId = event.target.value;
    this.props.knowledge.forEach((item) => {
      item.articles.forEach((article) => {
        if (article.id === event.target.value) {
          selectedItem.categoryId = item.id;
          selectedItem.img = article.img;
          this.setState({ selectedItem, label: article.title });
        }
      });
    });
  }

  setDealID(event) {
    event.preventDefault();
    this.props.deals.forEach((item) => {
      if (item.id === event.target.value) {
        var selectedItem = { ...this.state.selectedItem };
        selectedItem.img = item.img;
        selectedItem.dealId = item.id;
        this.setState({ selectedItem, deallabel: item.title });
      }
    });
  }

  choosePopup(index) {
    const popups = this.props.scrolls;
    const { weektab } = this.state;
    const datatorender = {
      id: popups[weektab].items[index].id,
      type: popups[weektab].items[index].type
        ? popups[weektab].items[index].type
        : "",
      title: popups[weektab].items[index].title
        ? popups[weektab].items[index].title
        : "",
      boxtype: popups[weektab].items[index].boxtype
        ? popups[weektab].items[index].boxtype
        : "",
      order: popups[weektab].items[index].order
        ? popups[weektab].items[index].order
        : "",
      categorytitle: popups[weektab].items[index].categorytitle
        ? popups[weektab].items[index].categorytitle
        : "",
      headline: popups[weektab].items[index].headline
        ? popups[weektab].items[index].headline
        : "",
      text: popups[weektab].items[index].text
        ? popups[weektab].items[index].text
        : "",
      img: popups[weektab].items[index].img
        ? popups[weektab].items[index].img
        : "",
      url: popups[weektab].items[index].url
        ? popups[weektab].items[index].url
        : null,
      tool: popups[weektab].items[index].tool
        ? popups[weektab].items[index].tool
        : null,
      articleId: popups[weektab].items[index].articleId
        ? popups[weektab].items[index].articleId
        : null,
      categoryId: popups[weektab].items[index].categoryId
        ? popups[weektab].items[index].categoryId
        : null,
      dealId: popups[weektab].items[index].dealId
        ? popups[weektab].items[index].dealId
        : null,
      todo: popups[weektab].items[index].todo
        ? popups[weektab].items[index].todo
        : "",
    };
    if (popups[weektab].items[index].articleId) {
      this.props.knowledge.forEach((item) => {
        item.articles.forEach((article) => {
          if (article.id === popups[weektab].items[index].articleId) {
            this.setState({ label: article.title });
          }
        });
      });
    } else {
      this.setState({ label: null });
    }
    if (popups[weektab].items[index].dealId) {
      this.props.deals.forEach((item) => {
        if (item.id === popups[weektab].items[index].dealId) {
          this.setState({ deallabel: item.title });
        }
      });
    } else {
      this.setState({ deallabel: null });
    }
    this.setState({ type: "update", selectedItem: datatorender });
  }

  /*
  renderBoxes() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {this.state.selectedItem.type === "article" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "1"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "1";
              this.setState({ selectedItem });
            }}
            img={boxtype1}
          />
        )}
        {this.state.selectedItem.type === "article" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "2"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "2";
              this.setState({ selectedItem });
            }}
            img={boxtype2}
          />
        )}
        {this.state.selectedItem.type === "article" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "3"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "3";
              this.setState({ selectedItem });
            }}
            img={boxtype3}
          />
        )}
        {this.state.selectedItem.type === "article" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "4"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "4";
              this.setState({ selectedItem });
            }}
            img={boxtype4}
          />
        )}
        {this.state.selectedItem.type === "ad" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "5"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "5";
              this.setState({ selectedItem });
            }}
            img={boxtype5}
          />
        )}

        {this.state.selectedItem.type === "ad" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "6"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "6";
              this.setState({ selectedItem });
            }}
            img={boxtype6}
          />
        )}

        {this.state.selectedItem.type === "ad" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "7"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "7";
              this.setState({ selectedItem });
            }}
            img={boxtype7}
          />
        )}

        {this.state.selectedItem.type === "ad" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "8"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "8";
              this.setState({ selectedItem });
            }}
            img={boxtype8}
          />
        )}
        {this.state.selectedItem.type === "ad" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "9"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "9";
              this.setState({ selectedItem });
            }}
            img={boxtype9}
          />
        )}
        {this.state.selectedItem.type === "tool" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "10"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "10";
              this.setState({ selectedItem });
            }}
            img={boxtype10}
          />
        )}
        {this.state.selectedItem.type === "tool" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "11"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "11";
              this.setState({ selectedItem });
            }}
            img={boxtype11}
          />
        )}
        {this.state.selectedItem.type === "tool" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "12"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "12";
              this.setState({ selectedItem });
            }}
            img={boxtype12}
          />
        )}
        {this.state.selectedItem.type === "tool" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "13"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "13";
              this.setState({ selectedItem });
            }}
            img={boxtype13}
          />
        )}
        {this.state.selectedItem.type === "tool" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "14"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "14";
              this.setState({ selectedItem });
            }}
            img={boxtype14}
          />
        )}
        {this.state.selectedItem.type === "tool" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "15"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "15";
              this.setState({ selectedItem });
            }}
            img={boxtype15}
          />
        )}
        {this.state.selectedItem.type === "todo" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "16"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "16";
              this.setState({ selectedItem });
            }}
            img={boxtype16}
          />
        )}
        {this.state.selectedItem.type === "todo" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "17"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "17";
              this.setState({ selectedItem });
            }}
            img={boxtype17}
          />
        )}
        {this.state.selectedItem.type === "tool" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "18"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "18";
              this.setState({ selectedItem });
            }}
            img={boxtype18}
          />
        )}
        {this.state.selectedItem.type === "other" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "19"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "19";
              this.setState({ selectedItem });
            }}
            img={boxtype19}
          />
        )}
        {this.state.selectedItem.type === "other" && (
          <ScrollBox
            selected={this.state.selectedItem.boxtype === "20"}
            onClick={(e) => {
              e.preventDefault();
              var selectedItem = { ...this.state.selectedItem };
              selectedItem.boxtype = "20";
              this.setState({ selectedItem });
            }}
            img={boxtype20}
          />
        )}
      </div>
    );
  }

  renderCreateScrolls() {
    const allarticle = [];
    this.props.knowledge.forEach((item) => {
      allarticle.push(...item.articles);
    });
    const alldeals = this.props.deals;
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Form className="Form2" style={{ width: "100%" }}>
          <div style={{ fontWeight: 600, fontSize: 16 }}>
            This is selected: {this.state.selectedItem.type}
          </div>
          <Form.Group controlId="formBasictitle">
            <Form.Control
              onChange={(event) => {
                var selectedItem = { ...this.state.selectedItem };
                selectedItem.type = event.target.value;
                if (selectedItem.type === "ad") {
                  selectedItem.categorytitle = "Hirdetés";
                }
                if (selectedItem.type === "article") {
                  selectedItem.categorytitle = "Cikk";
                }
                if (selectedItem.type === "todo") {
                  selectedItem.categorytitle = "Teendő";
                }
                if (selectedItem.type === "tool") {
                  selectedItem.categorytitle = "Eszköz";
                }

                this.setState({ selectedItem });
              }}
              as="select"
            >
              <option value={null}>Choose one</option>
              <option value="article">ARTICLE</option>
              <option value="ad">AD</option>
              <option value="tool">TOOL</option>
              <option value="todo">TODO</option>
              <option value="other">OTHER</option>
            </Form.Control>
            {this.state.selectedItem.type === "article" && (
              <div>
                <Form.Label>Article ID</Form.Label>
                <div>
                  {"This is selected: "}
                  {this.state.label}
                </div>
                <Form.Control
                  onChange={(event) => this.setArticleAndCategoryID(event)}
                  as="select"
                >
                  <option value={null}>Choose one</option>
                  {allarticle.map((e) => (
                    <option value={e.id}>{e.title}</option>
                  ))}
                </Form.Control>
              </div>
            )}
            {this.state.selectedItem.type === "ad" && (
              <div>
                <Form.Label>Ad ID</Form.Label>
                <div>
                  {"This is selected: "}
                  {this.state.deallabel}
                </div>
                <Form.Control
                  onChange={(event) => this.setDealID(event)}
                  as="select"
                >
                  <option value={null}>Choose one</option>
                  {alldeals.map((e) => (
                    <option value={e.id}>{e.title}</option>
                  ))}
                </Form.Control>
              </div>
            )}
            {this.state.selectedItem.type === "tool" && (
              <div>
                <Form.Label>Select a tool</Form.Label>
                <div>
                  {"This is selected: "}
                  {this.state.selectedItem.tool}
                </div>
                <Form.Control
                  onChange={(event) => {
                    var selectedItem = { ...this.state.selectedItem };
                    selectedItem.tool = event.target.value;
                    this.setState({ selectedItem });
                  }}
                  as="select"
                >
                  <option value={null}>Choose one</option>
                  <option value="weightcurve">Weight curve</option>
                  <option value="timer">Timer</option>
                  <option value="foodsafety">Foodsafety</option>
                  <option value="photographer">Photographer</option>
                  <option value="todolist">Todo List</option>
                  <option value="diary">Diary</option>
                  <option value="babynames">Baby names</option>
                  <option value="kickcounter">Kick counter</option>
                </Form.Control>
              </div>
            )}
            <Form.Label>Order</Form.Label>
            <Form.Control
              onChange={(event) => {
                var selectedItem = { ...this.state.selectedItem };
                selectedItem.order = event.target.value;
                this.setState({ selectedItem });
              }}
              name="body"
              type="body"
              value={this.state.selectedItem.order}
              placeholder="Order"
            />
            <Form.Label>Category title</Form.Label>
            <Form.Control
              onChange={(event) => {
                var selectedItem = { ...this.state.selectedItem };
                selectedItem.categorytitle = event.target.value;
                this.setState({ selectedItem });
              }}
              name="body"
              type="body"
              value={this.state.selectedItem.categorytitle}
              placeholder="Category title"
            />
            <ImageUp
              title="Cover Image"
              value={this.state.selectedItem.img}
              parentCallback={() => {
                var selectedItem = { ...this.state.selectedItem };
                selectedItem.img = "";
                this.setState({ selectedItem });
              }}
              getImg={(value) => {
                var selectedItem = { ...this.state.selectedItem };
                selectedItem.img = value;
                this.setState({ selectedItem });
              }}
            />
            <Form.Label>Headline</Form.Label>
            <Form.Control
              onChange={(event) => {
                var selectedItem = { ...this.state.selectedItem };
                selectedItem.headline = event.target.value;
                this.setState({ selectedItem });
              }}
              name="body"
              type="body"
              value={this.state.selectedItem.headline}
              placeholder="Headline"
            />
            <Form.Label>Text</Form.Label>
            {this.state.selectedItem.type === "todo" && (
              <div style={{ marginBottom: 10 }}>
                To get percentage, write: ››szazalek‹‹
              </div>
            )}
            <Form.Control
              onChange={(event) => {
                var selectedItem = { ...this.state.selectedItem };
                selectedItem.text = event.target.value;
                this.setState({ selectedItem });
              }}
              name="body"
              type="body"
              value={this.state.selectedItem.text}
              placeholder="Text"
            />
            {this.renderBoxes()}
            {(this.state.selectedItem.type === "ad" ||
              this.state.selectedItem.type === "other") && (
              <div>
                <Form.Label>Link</Form.Label>
                <Form.Control
                  onChange={(event) => {
                    var selectedItem = { ...this.state.selectedItem };
                    selectedItem.link = event.target.value;
                    this.setState({ selectedItem });
                  }}
                  name="link"
                  required
                  value={this.state.selectedItem.link}
                  type="text"
                  placeholder="Link fe. https://www.google.com/"
                />
              </div>
            )}
          </Form.Group>
          <Button
            variant="primary"
            style={{ backgroundColor: "#FCC27B", color: "black" }}
            onClick={() => this.createNewScrolls()}
          >
            Send
          </Button>
          {this.state.type === "update" && (
            <Button
              variant="primary"
              style={{ backgroundColor: "#FCC27B", color: "black" }}
              onClick={() => {
                this.props.deleteScrolls(this.state.selectedItem.id);
                this.setState({
                  selectedItem: {
                    id: "",
                    title: "",
                    week: "",
                    scrollweek: "",
                    type: null,
                    boxtype: "",
                    order: "",
                    categorytitle: "",
                    headline: "",
                    text: "",
                    img: "",
                    url: null,
                    articleId: null,
                    categoryId: null,
                    dealId: null,
                    selectedArticle: "",
                    deallabel: "",
                    label: "",
                    tool: "",
                  },
                });
              }}
            >
              Delete
            </Button>
          )}
        </Form>
      </div>
    );
  }

  renderPopups() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginBottom: 30,
        }}
      >
        <div
          onClick={() =>
            this.setState({
              type: "create",
              selectedItem: {
                id: "",
                title: "",
                type: null,
                boxtype: "",
                order: "",
                categorytitle: "",
                headline: "",
                text: "",
                img: "",
                url: null,
                articleId: null,
                categoryId: null,
                dealId: null,
                selectedArticle: "",
                deallabel: "",
                label: "",
                tool: "",
              },
            })
          }
          style={{
            marginLeft: 20,
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 20,
          }}
        >
          <img alt="add" style={{ height: 20, marginRight: 20 }} src={plus} />
          <p>Add Scroll</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            maxWidth: "80vw",
          }}
        >
          {this.props.scrolls[this.state.weektab].items &&
            this.props.scrolls[this.state.weektab].items.map((value, index) => (
              <button
                onClick={() => this.choosePopup(index)}
                style={{
                  height: 47,
                  borderRadius: 10,
                  margin: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border:
                    this.state.selectedItem.id === value.id
                      ? "2px solid #FCC27B"
                      : "none",
                  backgroundColor: "#F5F2EE",
                }}
              >
                {value.headline}
              </button>
            ))}
        </div>
      </div>
    );
  }

  renderWeeks() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          flexWrap: "wrap",
          maxWidth: "80vw",
        }}
      >
        {this.props.scrolls
          .sort((a, b) => (Number(a.week) > Number(b.week) && 1) || -1)
          .map((item, index) => (
            <div>
              <button
                onClick={() =>
                  this.setState({ weektab: index, selectedItem: {} })
                }
                style={{
                  height: 47,
                  border:
                    this.state.weektab === index ? "2px solid #FCC27B" : "none",
                  borderRadius: 10,
                  width: "80%",
                  backgroundColor: "#F5F2EE",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                {item.week}. week
              </button>
            </div>
          ))}
      </div>
    );
  }

  renderDateSelector(data) {
    return (
      <div>
        <div style={{ fontSize: 20, marginTop: 20, marginBottom: 10 }}>
          Dátumkezelő
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          <DatePicker
            dateFormat="yyyy.MM.dd"
            selected={this.state.startDate}
            onChange={(date) => this.setState({ startDate: date })}
          />
          <div style={{ width: 20 }} />
          <DatePicker
            dateFormat="yyyy.MM.dd"
            selected={this.state.endDate}
            onChange={(date) => this.setState({ endDate: date })}
          />
        </div>
        {data === "scrolls" && this.props.loading && <Spinner />}
      </div>
    );
  }

  renderScrollsAnalytics() {
    let analytics_filtered =
      this.props.analyticsscrolls.length !== 0 &&
      filterByDate(
        this.props.analyticsscrolls,
        this.state.startDate,
        this.state.endDate
      );
    return (
      <div>
        {this.renderDateSelector("scrolls")}
        {this.props.analyticsscrolls.length !== 0 &&
          analytics_filtered.map((e) => {
            return (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 50,
                }}
              >
                <h1>{e.date}</h1>
                <div
                  style={{
                    background: "#F5F2EE",
                    borderRadius: 50,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <table
                    
                  >
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Week</th>
                        <th>Pressed</th>
                        <th>Uniq</th>
                        <th>Percentage (pressed/all pressed)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {e.items
                        .sort(dynamicSortMultiple("week", "title"))
                        .map((f) => {
                          return (
                            <tr>
                              <td>{f.title}</td>
                              <td>{f.week}</td>
                              <td>{f.pressed}</td>
                              <td>{f.uniq ? f.uniq : "0"}</td>
                              <td>{f.percentage}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
*/
  render() {
    return (
      <div className="w-full">
        <p className="ml-12 font-bold">
          This is not done, because Ildi said that scrolls should be discussed
          and done another time
        </p>
        {/* <div className="mx-12 flex flex-col justify-center">
          <div className="w-full flex flex-col justify-center items-center">
            {this.state.tab === "weeks" && this.props.scrolls.length !== 0 && (
              <div>
                {this.renderWeeks()}
                {this.renderPopups()}
                {this.renderCreateScrolls()}
              </div>
            )}
          </div>
            </div>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state.state,
  popups: state.api.popups,
  scrolls: state.api.scrolls,
  knowledge:
    Object.keys(state.api.data).length > 0 ? state.api.data.knowledge : [],
  deals: Object.keys(state.api.data).length > 0 ? state.api.data.deals : [],
  todo: Object.keys(state.api.data).length > 0 ? state.api.data.todo : [],
  user: state.api.user,
  analyticsscrolls: state.analytics.analyticsscrolls,
});

const mapDispatchToProps = {
  getScrolls,
  createScrolls,
  updateScrolls,
  deleteScrolls,
  getAllData,
  getScrollsAnalytics,
};

export default connect(mapStateToProps, mapDispatchToProps)(Scrolls);
