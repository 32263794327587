/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  OLD_GETDeals,
  OLD_GETDealsSum,
  OLD_GETScreens,
  OLD_GETScreensSum,
  OLD_GETTodos,
  OLD_GETTodoSum,
  OLD_GETKnowledges,
  OLD_GETKnowledgeSum,
  OLD_GETAnalyticsUsers,
  OLD_GETAnalyticsUserWeeks,
  OLD_GETAnalyticsUserCount,
  OLD_GETAnalyticsLinks,
  OLD_GETAnalyticsPopups,
} from "../../redux/actions/oldanalytics";
import { dynamicSortMultiple } from "../../utils/sort";
import { filterByDate } from "../../utils/filter";
import { setIntervals } from "../../redux/actions/state";
import DatePicker from "react-datepicker";
import Layout from "../../components/layout/Layout";
import Spinner from "../../components/Spinner";
import TextField from "../../components/TextField";
import MenuButton from "../../components/MenuButton";
import AScreenName from "../../components/oldanalytics/AScreenName";
import KnowLedgeAnalytics from "../../components/oldanalytics/KnowLedgeAnalytics";
import ArticleAnalytics from "../../components/oldanalytics/ArticleAnalytics";
import OldUserAll from "../../components/oldanalytics/OldUserAll";
import OldUserActivity from "../../components/oldanalytics/OldUserActivity";

// styles
import "react-datepicker/dist/react-datepicker.css";
import "react-markdown-editor-lite/lib/index.css";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      week: 0,
      selectedItem: {},
      markdown: "",
      babyAdImg: null,
      mumAdImg: null,
      modalIsOpen: false,
      analytics: 0,
      modal: "",
      mumselectedImageURL: "",
      babyselectedImageURL: "",
      screens: "full",
      screens_todo: "full",
      screens_know: "full",
      startDate: new Date(),
      endDate: new Date(),
      term: "",
      loading: false,
    };
  }

  renderDateSelector(text, data) {
    return (
      <div className="w-full flex flex-col items-center my-4">
        <div>Dátumkezelő</div>
        <div className="flex flex-row justify-center mb-4">
          <DatePicker
            dateFormat="yyyy.MM.dd"
            className="border border-black"
            selected={this.state.startDate}
            onChange={(date) => this.setState({ startDate: date })}
          />
          <div style={{ width: 20 }} />
          <DatePicker
            dateFormat="yyyy.MM.dd"
            className="border border-black"
            selected={this.state.endDate}
            onChange={(date) => this.setState({ endDate: date })}
          />
        </div>
        {data === "deals" && this.props.loading && <Spinner />}
        {data === "screens" && this.props.loading && <Spinner />}
        {data === "screens_know" && this.props.loading && <Spinner />}
        {data === "screens_todo" && this.props.loading && <Spinner />}
        {data === "links" && this.props.loading && <Spinner />}
        {data === "popups" && this.props.loading && <Spinner />}

        {text && (
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <form
              style={{ width: 500, display: "flex", justifyContent: "center" }}
            >
              <TextField
                type="text"
                onChange={(event) =>
                  this.setState({ term: event.target.value })
                }
                value={this.state.term}
              />
            </form>
          </div>
        )}
      </div>
    );
  }


  renderDeals() {
    let filtereddeals = filterByDate(
      this.props.deals,
      this.state.startDate,
      this.state.endDate
    );
    return (
      <div className="mx-12">
        <div>
          {
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => {
                  this.state.screens === "seven"
                    ? this.setState({ screens: "" })
                    : this.setState({ screens: "seven" });
                }}
                style={{
                  height: 47,
                  borderRadius: 10,
                  margin: 10,
                  backgroundColor: "#F5F2EE",
                  border:
                    this.state.screens === "seven"
                      ? "2px solid #FCC27B"
                      : "none",
                }}
              >
                Last Week
              </button>
              <button
                onClick={() => {
                  this.state.screens === "thirty"
                    ? this.setState({ screens: "" })
                    : this.setState({ screens: "thirty" });
                }}
                style={{
                  height: 47,
                  borderRadius: 10,
                  margin: 10,
                  backgroundColor: "#F5F2EE",
                  border:
                    this.state.screens === "thirty"
                      ? "2px solid #FCC27B"
                      : "none",
                }}
              >
                Last 30 days
              </button>
              <button
                onClick={() => {
                  this.state.screens === "full"
                    ? this.setState({ screens: "" })
                    : this.setState({ screens: "full" });
                }}
                style={{
                  height: 47,
                  borderRadius: 10,
                  margin: 10,
                  backgroundColor: "#F5F2EE",
                  border:
                    this.state.screens === "full"
                      ? "2px solid #FCC27B"
                      : "none",
                }}
              >
                All
              </button>
            </div>
          }
        </div>
        {
          // DEALS_SUM
          this.props.deals_sum.length !== 0 && this.state.screens !== "" && (
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 50,
              }}
            >
              <div>
                {this.state.screens === "full"
                  ? "Összes"
                  : this.state.screens === "thirty"
                  ? "Előző 30 nap"
                  : "Előző hét"}
              </div>
              <div
                style={{
                  background: "#F5F2EE",
                  borderRadius: 50,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <table>
                  <thead>
                    <tr>
                      <th>Link</th>
                      <th>Which screen</th>
                      <th>Clicked</th>
                      <th>Uniq</th>
                      <th>Percentage (clicked/all clicked)</th>
                      <th>Other info</th>
                      <th>Todo category</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.screens === "full" &&
                      this.props.deals_sum
                        .sort(dynamicSortMultiple("screen", "type"))
                        .map((e) => {
                          return (
                            <tr>
                              <td style={{ maxWidth: 100, overflow: "hidden" }}>
                                {e.deal}
                              </td>
                              <td>{e.screen}</td>
                              <td>{e.pressed}</td>
                              <td>{e.uniq}</td>
                              <td>
                                {e.percentage}
                                {"%"}
                              </td>
                              <td>{e.type}</td>
                              <td>{e.todocategory ? e.todocategory : ""}</td>
                            </tr>
                          );
                        })}
                    {this.state.screens === "thirty" &&
                      this.props.deals_thirty
                        .sort(dynamicSortMultiple("screen", "type"))
                        .map((e) => {
                          return (
                            <tr>
                              <td style={{ maxWidth: 100, overflow: "hidden" }}>
                                {e.deal}
                              </td>
                              <td>{e.screen}</td>
                              <td>{e.pressed}</td>
                              <td>{e.uniq}</td>
                              <td>
                                {e.percentage}
                                {"%"}
                              </td>
                              <td>{e.type}</td>
                              <td>{e.todocategory ? e.todocategory : ""}</td>
                            </tr>
                          );
                        })}
                    {this.state.screens === "seven" &&
                      this.props.deals_seven
                        .sort(dynamicSortMultiple("screen", "type"))
                        .map((e) => {
                          return (
                            <tr>
                              <td style={{ maxWidth: 100, overflow: "hidden" }}>
                                {e.deal}
                              </td>
                              <td>{e.screen}</td>
                              <td>{e.pressed}</td>
                              <td>{e.uniq}</td>
                              <td>
                                {e.percentage}
                                {"%"}
                              </td>
                              <td>{e.type}</td>
                              <td>{e.todocategory ? e.todocategory : ""}</td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            </div>
          )
        }
        {this.renderDateSelector(true, "deals")}
        {this.props.deals.length !== 0 &&
          filtereddeals.map((e) => {
            return (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 50,
                }}
              >
                <h1>{e.date}</h1>
                <div
                  style={{
                    background: "#F5F2EE",
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>Link</th>
                        <th>Which screen</th>
                        <th>Clicked</th>
                        <th>Uniq</th>
                        <th>Percentage (clicked/all clicked)</th>
                        <th>Other info</th>
                        <th>Todo category</th>
                      </tr>
                    </thead>
                    <tbody>
                      {e.deals
                        .filter(
                          (x) =>
                            (x.companyname &&
                              x.companyname
                                .toLowerCase()
                                .includes(this.state.term.toLowerCase())) ||
                            !this.state.term
                        )
                        .sort(dynamicSortMultiple("screen", "type"))
                        .map((e) => {
                          return (
                            <tr>
                              <td style={{ maxWidth: 100, overflow: "hidden" }}>
                                {e.deal}
                              </td>
                              <td>{e.screen}</td>
                              <td>{e.pressed}</td>
                              <td>{e.uniq}</td>
                              <td>
                                {e.percentage}
                                {"%"}
                              </td>
                              <td>{e.type}</td>
                              <td>{e.todocategory ? e.todocategory : ""}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  renderScreens() {
    let screensfiltered = filterByDate(
      this.props.screens,
      this.state.startDate,
      this.state.endDate
    );
    return (
      <div className="mx-12">
        {this.state.analytics === 3 && (
          <div style={{ marginBottom: 400 }}>
            {
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={() => {
                    this.setState({ screens: "seven" });
                  }}
                  style={{
                    height: 47,
                    borderRadius: 10,
                    margin: 10,
                    backgroundColor: "#F5F2EE",
                    border:
                      this.state.screens === "seven"
                        ? "2px solid #FCC27B"
                        : "none",
                  }}
                >
                  Last Week
                </button>
                <button
                  onClick={() => {
                    this.setState({ screens: "thirty" });
                  }}
                  style={{
                    height: 47,
                    borderRadius: 10,
                    margin: 10,
                    backgroundColor: "#F5F2EE",
                    border:
                      this.state.screens === "thirty"
                        ? "2px solid #FCC27B"
                        : "none",
                  }}
                >
                  Last 30 days
                </button>
                <button
                  onClick={() => {
                    this.setState({ screens: "full" });
                  }}
                  style={{
                    height: 47,
                    borderRadius: 10,
                    margin: 10,
                    backgroundColor: "#F5F2EE",
                    border:
                      this.state.screens === "full"
                        ? "2px solid #FCC27B"
                        : "none",
                  }}
                >
                  All
                </button>
              </div>
            }

            {this.props.screens.length === 0 && this.state.week !== 20 && (
              <Spinner style={{ marginTop: 50 }} progress={true} />
            )}
            {
              // SCREENS_SUM
              this.props.screens_sum.length !== 0 && (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 50,
                  }}
                >
                  <div>
                    {this.state.screens === "full"
                      ? "Összes"
                      : this.state.screens === "thirty"
                      ? "Előző 30 nap"
                      : "Előző hét"}
                  </div>
                  <div
                    style={{
                      background: "#F5F2EE",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th>Screens</th>
                          <th>View</th>
                          <th>Percentage (view/all view)</th>
                          <th>Uniq</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.screens === "full" &&
                          this.props.screens_sum
                            .sort((a, b) => a.screen.localeCompare(b.screen))
                            .map((f) => {
                              return (
                                <tr>
                                  <td>{f.screen}</td>
                                  <td>{f.pressed}</td>
                                  <td>
                                    {f.percentage}
                                    {"%"}
                                  </td>
                                  <td>{f.uniq}</td>
                                </tr>
                              );
                            })}
                        {this.state.screens === "thirty" &&
                          this.props.screens_thirty
                            .sort((a, b) => a.screen.localeCompare(b.screen))
                            .map((f) => {
                              return (
                                <tr>
                                  <td>{f.screen}</td>
                                  <td>{f.pressed}</td>
                                  <td>
                                    {f.percentage}
                                    {"%"}
                                  </td>
                                  <td>{f.uniq}</td>
                                </tr>
                              );
                            })}
                        {this.state.screens === "seven" &&
                          this.props.screens_seven
                            .sort((a, b) => a.screen.localeCompare(b.screen))
                            .map((f) => {
                              return (
                                <tr>
                                  <td>{f.screen}</td>
                                  <td>{f.pressed}</td>
                                  <td>
                                    {f.percentage}
                                    {"%"}
                                  </td>
                                  <td>{f.uniq}</td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            }
            {this.renderDateSelector(false, "screens")}
            <AScreenName
              arr={this.props.screens}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              loading={this.props.loading}
            />
            {this.props.screens.length !== 0 &&
              screensfiltered.map((e) => {
                return (
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 50,
                    }}
                  >
                    <h1>{e.date}</h1>
                    <div
                      style={{
                        background: "#F5F2EE",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>Screens</th>
                            <th>View</th>
                            <th>Percentage (view/all view)</th>
                            <th>Uniq</th>
                          </tr>
                        </thead>
                        <tbody>
                          {e.screens
                            .sort((a, b) => a.screen.localeCompare(b.screen))
                            .map((f) => {
                              return (
                                <tr>
                                  <td>{f.screen}</td>
                                  <td>{f.pressed}</td>
                                  <td>
                                    {f.percentage}
                                    {"%"}
                                  </td>
                                  <td>{f.uniq ? f.uniq : "0"}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  }

  renderScreensTodo() {
    let screens_todofiltered = filterByDate(
      this.props.screens_todo,
      this.state.startDate,
      this.state.endDate
    );
    return (
      <div className="mx-12">
        {this.state.analytics === 4 && (
          <div>
            {
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={() => {
                    this.setState({ screens_todo: "seven" });
                  }}
                  style={{
                    height: 47,
                    borderRadius: 10,
                    margin: 10,
                    backgroundColor: "#F5F2EE",
                    border:
                      this.state.screens_todo === "seven"
                        ? "2px solid #FCC27B"
                        : "none",
                  }}
                >
                  Last Week
                </button>
                <button
                  onClick={() => {
                    this.setState({ screens_todo: "thirty" });
                  }}
                  style={{
                    height: 47,
                    borderRadius: 10,
                    margin: 10,
                    backgroundColor: "#F5F2EE",
                    border:
                      this.state.screens_todo === "thirty"
                        ? "2px solid #FCC27B"
                        : "none",
                  }}
                >
                  Last 30 days
                </button>
                <button
                  onClick={() => {
                    this.setState({ screens_todo: "full" });
                  }}
                  style={{
                    height: 47,
                    borderRadius: 10,
                    margin: 10,
                    backgroundColor: "#F5F2EE",
                    border:
                      this.state.screens_todo === "full"
                        ? "2px solid #FCC27B"
                        : "none",
                  }}
                >
                  All
                </button>
              </div>
            }

            {this.props.screens_todo.length === 0 && this.state.week !== 20 && (
              <Spinner style={{ marginTop: 50 }} progress={true} />
            )}
            {
              // SCREENS_SUM
              this.props.screens_todo_sum.length !== 0 && (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 50,
                  }}
                >
                  <div>
                    {this.state.screens_todo === "full"
                      ? "Összes"
                      : this.state.screens_todo === "thirty"
                      ? "Előző 30 nap"
                      : "Előző hét"}
                  </div>
                  <div
                    style={{
                      background: "#F5F2EE",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th>Screens</th>
                          <th>View</th>
                          <th>Percentage (view/all view)</th>
                          <th>Uniq</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.screens_todo === "full" &&
                          this.props.screens_todo_sum
                            .sort((a, b) => a.screen.localeCompare(b.screen))
                            .map((f) => {
                              return (
                                <tr>
                                  <td>{f.screen}</td>
                                  <td>{f.pressed}</td>
                                  <td>
                                    {f.percentage}
                                    {"%"}
                                  </td>
                                  <td>{f.uniq}</td>
                                </tr>
                              );
                            })}
                        {this.state.screens_todo === "thirty" &&
                          this.props.screens_todo_thirty
                            .sort((a, b) => a.screen.localeCompare(b.screen))
                            .map((f) => {
                              return (
                                <tr>
                                  <td>{f.screen}</td>
                                  <td>{f.pressed}</td>
                                  <td>
                                    {f.percentage}
                                    {"%"}
                                  </td>
                                  <td>{f.uniq}</td>
                                </tr>
                              );
                            })}
                        {this.state.screens_todo === "seven" &&
                          this.props.screens_todo_seven
                            .sort((a, b) => a.screen.localeCompare(b.screen))
                            .map((f) => {
                              return (
                                <tr>
                                  <td>{f.screen}</td>
                                  <td>{f.pressed}</td>
                                  <td>
                                    {f.percentage}
                                    {"%"}
                                  </td>
                                  <td>{f.uniq}</td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            }
            {this.renderDateSelector(false, "screens_todo")}
            {this.props.screens_todo.length !== 0 &&
              screens_todofiltered.map((e) => {
                return (
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 50,
                    }}
                  >
                    <h1>{e.date}</h1>
                    <div
                      style={{
                        background: "#F5F2EE",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>Screens</th>
                            <th>View</th>
                            <th>Percentage (view/all view)</th>
                            <th>Uniq</th>
                          </tr>
                        </thead>
                        <tbody>
                          {e.screens
                            .sort((a, b) => a.screen.localeCompare(b.screen))
                            .map((f) => {
                              return (
                                <tr>
                                  <td>{f.screen}</td>
                                  <td>{f.pressed}</td>
                                  <td>
                                    {f.percentage}
                                    {"%"}
                                  </td>
                                  <td>{f.uniq ? f.uniq : "0"}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  }

  renderScreensKnowledge() {
    let screens_knowfiltered = filterByDate(
      this.props.screens_know,
      this.state.startDate,
      this.state.endDate
    );
    return (
      <div className="mx-12">
        {this.state.analytics === 5 && (
          <div>
            {
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={() => {
                    this.setState({ screens_know: "seven" });
                  }}
                  style={{
                    height: 47,
                    borderRadius: 10,
                    margin: 10,
                    backgroundColor: "#F5F2EE",
                    border:
                      this.state.screens_know === "seven"
                        ? "2px solid #FCC27B"
                        : "none",
                  }}
                >
                  Last Week
                </button>
                <button
                  onClick={() => {
                    this.setState({ screens_know: "thirty" });
                  }}
                  style={{
                    height: 47,
                    borderRadius: 10,
                    margin: 10,
                    backgroundColor: "#F5F2EE",
                    border:
                      this.state.screens_know === "thirty"
                        ? "2px solid #FCC27B"
                        : "none",
                  }}
                >
                  Last 30 days
                </button>
                <button
                  onClick={() => {
                    this.setState({ screens_know: "full" });
                  }}
                  style={{
                    height: 47,
                    borderRadius: 10,
                    margin: 10,
                    backgroundColor: "#F5F2EE",
                    border:
                      this.state.screens_know === "full"
                        ? "2px solid #FCC27B"
                        : "none",
                  }}
                >
                  All
                </button>
              </div>
            }

            {this.props.screens_know.length === 0 && this.state.week !== 20 && (
              <Spinner style={{ marginTop: 50 }} progress={true} />
            )}
            {
              // SCREENS_SUM
              this.props.screens_know_sum.length !== 0 && (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 50,
                  }}
                >
                  <div>
                    {this.state.screens_know === "full"
                      ? "Összes"
                      : this.state.screens_know === "thirty"
                      ? "Előző 30 nap"
                      : "Előző hét"}
                  </div>
                  <div
                    style={{
                      background: "#F5F2EE",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th>Screens</th>
                          <th>View</th>
                          <th>Percentage (view/all view)</th>
                          <th>Uniq</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.screens_know === "full" &&
                          this.props.screens_know_sum
                            .sort((a, b) => a.screen.localeCompare(b.screen))
                            .map((f) => {
                              return (
                                <tr>
                                  <td>{f.screen}</td>
                                  <td>{f.pressed}</td>
                                  <td>
                                    {f.percentage}
                                    {"%"}
                                  </td>
                                  <td>{f.uniq}</td>
                                </tr>
                              );
                            })}
                        {this.state.screens_know === "thirty" &&
                          this.props.screens_know_thirty
                            .sort((a, b) => a.screen.localeCompare(b.screen))
                            .map((f) => {
                              return (
                                <tr>
                                  <td>{f.screen}</td>
                                  <td>{f.pressed}</td>
                                  <td>
                                    {f.percentage}
                                    {"%"}
                                  </td>
                                  <td>{f.uniq}</td>
                                </tr>
                              );
                            })}
                        {this.state.screens_know === "seven" &&
                          this.props.screens_know_seven
                            .sort((a, b) => a.screen.localeCompare(b.screen))
                            .map((f) => {
                              return (
                                <tr>
                                  <td>{f.screen}</td>
                                  <td>{f.pressed}</td>
                                  <td>
                                    {f.percentage}
                                    {"%"}
                                  </td>
                                  <td>{f.uniq}</td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            }
            {this.renderDateSelector(false, "screens_know")}
            <KnowLedgeAnalytics
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            {this.props.screens_know.length !== 0 &&
              screens_knowfiltered.map((e) => {
                return (
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 50,
                    }}
                  >
                    <h1>{e.date}</h1>
                    <div
                      style={{
                        background: "#F5F2EE",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>Screens</th>
                            <th>View</th>
                            <th>Percentage (view/all view)</th>
                            <th>Uniq</th>
                          </tr>
                        </thead>
                        <tbody>
                          {e.screens
                            .sort((a, b) => a.screen.localeCompare(b.screen))
                            .map((f) => {
                              return (
                                <tr>
                                  <td>{f.screen}</td>
                                  <td>{f.pressed}</td>
                                  <td>
                                    {f.percentage}
                                    {"%"}
                                  </td>
                                  <td>{f.uniq ? f.uniq : "0"}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  }

  renderLinks() {
    let linksfiltered = filterByDate(
      this.props.links,
      this.state.startDate,
      this.state.endDate
    );
    return (
      <div className="mx-12">
        {this.state.analytics === 7 && (
          <div>
            {this.renderDateSelector(false, "links")}
            {this.props.links.length !== 0 &&
              linksfiltered
                .sort(
                  (a, b) =>
                    a.article && b.article && a.article.localeCompare(b.article)
                )
                .map((e) => {
                  return (
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 50,
                      }}
                    >
                      <h1>{e.date}</h1>
                      <div
                        style={{
                          background: "#F5F2EE",
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <table>
                          <thead>
                            <tr>
                              <th>Article</th>
                              <th>Link pressed</th>
                              <th>Percentage (pressed/all pressed)</th>
                              <th>Uniq</th>
                            </tr>
                          </thead>
                          <tbody>
                            {e.articles.map((f) => {
                              return (
                                <tr>
                                  <td>{f.article}</td>
                                  <td>{f.pressed}</td>
                                  <td>
                                    {f.percentage}
                                    {"%"}
                                  </td>
                                  <td>{f.uniq ? f.uniq : "0"}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    );
  }

  renderDateSelectorr(data) {
    return (
      <div className="w-full flex flex-col items-center my-4">
        <div style={{ fontSize: 20, marginTop: 20, marginBottom: 10 }}>
          Dátumkezelő
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          <DatePicker
            className="border border-black"
            dateFormat="yyyy.MM.dd"
            selected={this.state.startDate}
            onChange={(date) => this.setState({ startDate: date })}
          />
          <div style={{ width: 20 }} />
          <DatePicker
            className="border border-black"
            dateFormat="yyyy.MM.dd"
            selected={this.state.endDate}
            onChange={(date) => this.setState({ endDate: date })}
          />
        </div>
        {data === "popups" && this.props.loading && <Spinner />}
      </div>
    );
  }

  renderPopupAnalytics() {
    let analytics_filtered = filterByDate(
      this.props.analyticspopups,
      this.state.startDate,
      this.state.endDate
    );
    function dateToNum(d) {
      d = d.split(".");
      return Number(d[0] + d[1] + d[2]);
    }
    return (
      <div className="mx-12">
        {this.renderDateSelectorr("popups")}
        {this.props.analyticspopups !== 0 &&
          analytics_filtered
            .sort(function (a, b) {
              return dateToNum(a.date) - dateToNum(b.date);
            })
            .reverse()
            .map((e) => {
              return (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 50,
                  }}
                >
                  <h1>{e.date}</h1>
                  <div
                    style={{
                      background: "#F5F2EE",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Appeared</th>
                          <th>Pressed</th>
                        </tr>
                      </thead>
                      <tbody>
                        {e.popups.map((f) => {
                          return (
                            <tr>
                              <td>{f.title}</td>
                              <td>{f.appeared}</td>
                              <td>{f.pressed}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
      </div>
    );
  }

  render() {
    function dateToNum(d) {
      d = d.split(".");
      return Number(d[0] + d[1] + d[2]);
    }
    return (
      <Layout active="oldanalytics">
        <div className="w-full">
          <div className="w-full flex flex-row flex-wrap justify-center">
            <MenuButton
              onClick={() => {
                this.setState({ analytics: 0 });
              }}
              active={this.state.analytics === 0}
              title="Users"
              visible="true"
            />
            <MenuButton
              onClick={() => {
                this.setState({ analytics: 1 });
              }}
              active={this.state.analytics === 1}
              title="User activity"
              visible="true"
            />
            <MenuButton
              onClick={() => {
                this.props.OLD_GETDeals();
                this.props.OLD_GETDealsSum();
                this.setState({ analytics: 2, startDate: "", endDate: "" });
              }}
              active={this.state.analytics === 2}
              title="Ads"
              visible="true"
            />
            <MenuButton
              onClick={() => {
                this.props.OLD_GETScreens();
                this.props.OLD_GETScreensSum();
                this.setState({ analytics: 3, startDate: "", endDate: "" });
              }}
              active={this.state.analytics === 3}
              title="Screens"
              visible="true"
            />
            <MenuButton
              onClick={() => {
                this.props.OLD_GETTodos();
                this.props.OLD_GETTodoSum();
                this.setState({ analytics: 4, startDate: "", endDate: "" });
              }}
              active={this.state.analytics === 4}
              title="Todo"
              visible="true"
            />
            <MenuButton
              onClick={() => {
                this.props.OLD_GETKnowledges();
                this.props.OLD_GETKnowledgeSum();
                this.setState({ analytics: 5, startDate: "", endDate: "" });
              }}
              active={this.state.analytics === 5}
              title="Knowledge"
              visible="true"
            />
            <MenuButton
              onClick={() => {
                // this.props.OLD_GETKnowledges();
                // this.props.OLD_GETKnowledgeSum();
                this.setState({ analytics: 6 });
              }}
              active={this.state.analytics === 6}
              title="Articles"
              visible="true"
            />
            <MenuButton
              onClick={() => {
                this.props.OLD_GETAnalyticsLinks();
                this.setState({ analytics: 7, startDate: "", endDate: "" });
              }}
              active={this.state.analytics === 7}
              title="Links"
              visible="true"
            />
            <MenuButton
              onClick={() => {
                this.props.OLD_GETAnalyticsPopups();
                this.setState({ analytics: 8, startDate: "", endDate: "" });
              }}
              active={this.state.analytics === 8}
              title="Popups"
              visible="true"
            />
          </div>
          {this.state.analytics === 0 && <OldUserAll />}
          {this.state.analytics === 1 && <OldUserActivity />}
          {this.state.analytics === 2 && <div>{this.renderDeals()}</div>}
          {this.renderScreens()}
          {this.renderScreensTodo()}
          {this.renderScreensKnowledge()}
          {this.renderLinks()}
          {this.state.analytics === 6 && <ArticleAnalytics />}
          {this.state.analytics === 8 && this.renderPopupAnalytics()}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state.state,
  activity: state.oldanalytics.activity,
  deals: state.oldanalytics.deals,
  deals_sum: state.oldanalytics.deals_sum,
  deals_seven: state.oldanalytics.deals_seven,
  deals_thirty: state.oldanalytics.deals_thirty,
  screens: state.oldanalytics.screens,
  screens_sum: state.oldanalytics.screens_sum,
  screens_seven: state.oldanalytics.screens_seven,
  screens_thirty: state.oldanalytics.screens_thirty,
  screens_todo: state.oldanalytics.screens_todo,
  screens_todo_sum: state.oldanalytics.screens_todo_sum,
  screens_todo_seven: state.oldanalytics.screens_todo_seven,
  screens_todo_thirty: state.oldanalytics.screens_todo_thirty,
  screens_know: state.oldanalytics.screens_know,
  screens_know_sum: state.oldanalytics.screens_know_sum,
  screens_know_seven: state.oldanalytics.screens_know_seven,
  screens_know_thirty: state.oldanalytics.screens_know_thirty,
  analyticsusers: state.oldanalytics.analyticsusers,
  analyticsuserweeks: state.oldanalytics.analyticsuserweeks,
  links: state.oldanalytics.links,
  loading: state.oldanalytics.loading,
  analyticspopups: state.oldanalytics.analyticspopups,
});

const mapDispatchToProps = {
  OLD_GETAnalyticsUsers,
  OLD_GETAnalyticsUserWeeks,
  setIntervals,
  OLD_GETAnalyticsUserCount,
  OLD_GETAnalyticsLinks,
  OLD_GETDeals,
  OLD_GETDealsSum,
  OLD_GETScreens,
  OLD_GETScreensSum,
  OLD_GETTodos,
  OLD_GETTodoSum,
  OLD_GETKnowledges,
  OLD_GETKnowledgeSum,
  OLD_GETAnalyticsPopups,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
