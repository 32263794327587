import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getVideos, create, update, remove } from "../../redux/actions/tools";
import { openDeleteModal } from "../../redux/actions/state";
import { sortByVisibilityAndOrder } from "../../utils/sort";
import ImageUp from "../ImageUp";
import Checkbox from "../Checkbox";
import MenuButton from "../MenuButton";
import NewButton from "../NewButton";
import CustomButton from "../CustomButton";
import Spinner from "../Spinner";
import TextField from "../TextField";
import IntroForm from "../forms/IntroForm";

const Videos = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.tools.videos);
  const deleteType = useSelector((state) => state.state.deleteType);
  const [selectedItem2, setSelectedItem2] = useState({});
  const [createtype, setCreateType] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data === [] || !data) {
      dispatch(getVideos());
    }
  }, []);

  useEffect(() => {
    if (deleteType === "videos") {
      setSelectedItem2({
        id: "",
        article: "",
        img: "",
        length: "",
        order: "",
        title: "",
        visible: false,
      });
      setLoading(false);
      setTimeout(() => {
        setLoading(true);
      }, 500);
      setOpenForm(false);
    }
  }, [data]);

  const createArticle = () => {
    if (createtype) {
      const selected = selectedItem2;
      selected.id = Date.now().toString();
      const url = "/videos";
      setSelectedItem2({
        id: "",
        article: "",
        img: "",
        length: "",
        order: "",
        title: "",
        visible: false,
      });
      setLoading(false);
      setTimeout(() => {
        setLoading(true);
      }, 500);
      dispatch(create(selected, url, "videos"));
    } else {
      const selected = selectedItem2;
      const url = "/videos/" + selected.id;
      dispatch(update(selected, url, "videos"));
    }
  };

  const deleteArticle = () => {
    const selected = selectedItem2;
    const url = "/videos/" + selected.id;
    const type = "videos";
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  const renderArticleMenu = () => {
    return (
      <div className="flex flex-row items-center wrap mx-12">
        <NewButton
          onClick={() => {
            setCreateType(true);
            setSelectedItem2({
              id: "",
              youtubelink: "",
              img: "",
              length: "",
              order: "",
              title: "",
              visible: "false",
            });
            setOpenForm(true);
            setLoading(false);
            setTimeout(() => {
              setLoading(true);
            }, 500);
          }}
          title="Add Video"
        />
        {data &&
          data.data.sort(sortByVisibilityAndOrder()).map((value, index) => (
            <MenuButton
              key={index}
              onClick={() => {
                const datatorender = {
                  id: value.id,

                  youtubelink: value.youtubelink ? value.youtubelink : "",
                  img: value.img ? value.img : "",
                  length: value.length ? value.length : "",
                  order: value.order ? value.order : "",

                  title: value.title ? value.title : "",
                  visible: value.visible ? value.visible : "true",
                };
                setCreateType(false);
                setSelectedItem2(datatorender);
                setOpenForm(true);
                setLoading(false);
                setTimeout(() => {
                  setLoading(true);
                }, 500);
              }}
              active={selectedItem2.id === value.id}
              title={value.title + " - " + value.order}
              visible={value.visible ? value.visible : "true"}
            />
          ))}
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="flex flex-row justiy-around wrap w-full">
        {!data && <Spinner />}
        <div className="w-full">
          {data && <IntroForm intro={data.intro} />}
          {renderArticleMenu()}
          {loading && openForm && (
            <div className="w-4/5 mx-12">
              <TextField
                type="text"
                label="Article Id"
                name="id"
                value={selectedItem2.id}
                disabled
              />
              <TextField
                type="text"
                label="Title"
                name="title"
                value={selectedItem2.title}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.title = event.target.value;
                  setSelectedItem2(selectedItemNew);
                }}
              />
              <TextField
                type="text"
                label="Order"
                name="order"
                value={selectedItem2.order}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.order = event.target.value;
                  setSelectedItem2(selectedItemNew);
                }}
              />
              <ImageUp
                title="Cover Image (ratio: 1 / 1)"
                value={selectedItem2.img}
                parentCallback={() => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.img = "";
                  setSelectedItem2(selectedItemNew);
                }}
                getImg={(value) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.img = value;
                  setSelectedItem2(selectedItemNew);
                }}
              />
              <TextField
                type="text"
                label="Length (should be formatted az 'X perc')"
                name="length"
                value={selectedItem2.length}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.length = event.target.value;
                  setSelectedItem2(selectedItemNew);
                }}
              />
              <Checkbox
                name="Visibility"
                selected={selectedItem2.visible}
                getValue={(data) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.visible = data;
                  setSelectedItem2(selectedItemNew);
                }}
              />
              <TextField
                type="text"
                label="Youtube Link"
                name="youtubelink"
                value={selectedItem2.youtubelink}
                onChange={(event) => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.youtubelink = event.target.value;
                  setSelectedItem2(selectedItemNew);
                }}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {createtype && (
                  <CustomButton
                    type="submit"
                    className="w-1/5"
                    onClick={() => setSelectedItem2({})}
                  >
                    Cancel
                  </CustomButton>
                )}
                <div style={{ width: 20, height: 1 }} />

                {!createtype && (
                  <CustomButton
                    red
                    type="submit"
                    className="w-1/5"
                    onClick={() => deleteArticle()}
                  >
                    Delete
                  </CustomButton>
                )}
                <div style={{ width: 20, height: 1 }} />
                <CustomButton
                  type="submit"
                  className="w-1/5"
                  onClick={() => createArticle()}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Videos;
