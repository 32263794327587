/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getScreenArticle,
  getScreenKnowledge,
} from "../../../redux/actions/analytics";
import { getAllData } from "../../../redux/actions/api";
import { formatDate, newPercentage } from "../helper";
import Dropdown from "../../Dropdown";
import Spinner from "../../Spinner";

const KnowledgeScreen = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const knowledgeloading = useSelector(
    (state) => state.analytics.screenknowledge_loading
  );
  const knowledge = useSelector((state) => state.analytics.screenknowledge);
  const knowledgedata = useSelector((state) => state.api.data.knowledge);

  const articleloading = useSelector(
    (state) => state.analytics.screenarticle_loading
  );
  const article = useSelector((state) => state.analytics.screenarticle);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryTitle, setSelectedCategoryTitle] = useState("");

  const [articleFilteredArray, setArticleFilteredArray] = useState([]);

  const KnowledgeCategoryOptions = () => {
    if (knowledgedata) {
      const articleselect = [{ value: "", label: "All" }];
      knowledgedata.forEach((item) => {
        articleselect.push({ value: item.id, label: item.title });
      });
      return articleselect;
    }
    return [];
  };

  useEffect(() => {
    if (!knowledgedata) {
      dispatch(getAllData());
    }
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getScreenKnowledge(
          formatDate(startDate),
          formatDate(endDate),
          limit,
          skip
        )
      );
    }
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getScreenKnowledge(
          formatDate(startDate),
          formatDate(endDate),
          limit,
          skip
        )
      );
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && selectedCategory !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getScreenArticle(
          formatDate(startDate),
          formatDate(endDate),
          limit,
          skip
        )
      );
    }
  }, [startDate, endDate, selectedCategory]);

  useEffect(() => {
    if (
      startDate !== "" &&
      endDate !== "" &&
      selectedCategory !== "" &&
      article &&
      article.screens
    ) {
      let filtered = article.screens.filter(
        (item) => item.categoryId === selectedCategory
      );
      newPercentage(filtered).then((element) =>
        setArticleFilteredArray(element)
      );
    }
  }, [startDate, endDate, selectedCategory, article]);

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-1/2 mt-8">
        <Dropdown
          value={selectedCategory}
          options={KnowledgeCategoryOptions()}
          onChange={(value) => {
            setSelectedCategory(value.value)
            setSelectedCategoryTitle(value.label)
          }}
        />
      </div>
      <div>
        {knowledgeloading && <Spinner />}
        {!knowledgeloading &&
          knowledge.length !== 0 &&
          selectedCategory === "" && (
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Screen</th>
                    <th>View</th>
                    <th>Percentage (view / all view)</th>
                    <th>Uniq</th>
                  </tr>
                </thead>
                <tbody>
                  {knowledge.screens.map((item, index) => {
                    // filter data out if something selected
                    return (
                      <tr key={index}>
                        <td>{item.screen}</td>
                        <td>{item.pressed}</td>
                        <td>{item.percentage} %</td>
                        <td>{item.userid.length}</td>
                      </tr>
                    );
                  })}
                  <tr className="font-bold">
                    <td>Total for knowledges</td>
                    <td>{knowledge.total}</td>
                    <td />
                    <td>{knowledge.uniq}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        {articleloading && <Spinner />}
        {!articleloading &&
          articleFilteredArray.length !== 0 &&
          selectedCategory !== "" && (
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Screen</th>
                    <th>View</th>
                    <th>Percentage (view / all view)</th>
                    <th>Uniq</th>
                  </tr>
                </thead>
                <tbody>
                  {articleFilteredArray.screens.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.screen}</td>
                        <td>{item.pressed}</td>
                        <td>{item.percentage} %</td>
                        <td>{item.userid.length}</td>
                      </tr>
                    );
                  })}
                  <tr className="font-bold">
                  <td>Total for {selectedCategoryTitle}</td>
                  <td>{articleFilteredArray.total}</td>
                  <td />
                  <td>{articleFilteredArray.uniq}</td>
              </tr>
                </tbody>
              </table>
            </div>
          )}
      </div>
    </div>
  );
};

export default KnowledgeScreen;
