/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllData, update, create } from "../../redux/actions/api";
import { openDeleteModal } from "../../redux/actions/state";
import FormForm from "../../components/forms/FormForm";
import Checkbox from "../../components/Checkbox";
import ImageUp from "../../components/ImageUp";
import Layout from "../../components/layout/Layout";
import TextField from "../../components/TextField";
import TextArea from "../../components/TextArea";
import NewButton from "../../components/NewButton";
import MenuButton from "../../components/MenuButton";
import CustomButton from "../../components/CustomButton";
import { sortByVisibilityAndOrder } from "../../utils/sort";

const Deals = () => {
  const dispatch = useDispatch();
  const [week, setWeek] = useState(0);
  const [newCreate, setNew] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [numberOfLargeAds, setNumberOfLargeAds] = useState(null);
  const [term, setTerm] = useState("");
  const [data, setData] = useState([]);
  const apidata = useSelector((state) => state.api.data);
  const deleteType = useSelector((state) => state.state.deleteType);

  useEffect(() => {
    dispatch(getAllData());
    setSelectedItem({});
    if (Object.keys(apidata).length > 0) {
      const sortedArray = apidata.deals.sort(sortByVisibilityAndOrder());
      setData(sortedArray);
      setNumberOfLargeAds(apidata.config[0].numberOfLargeAds);
    }
  }, []);

  useEffect(() => {
    // ha deals módosult
    if (Object.keys(apidata).length > 0) {
      const sortedArray = apidata.deals.sort(sortByVisibilityAndOrder());
      setData(sortedArray);
      setNumberOfLargeAds(apidata.config[0].numberOfLargeAds);
    }
    if (deleteType === "coupons") {
      setWeek(null);
      setNew(false);
      setSelectedItem({
        id: "",
        img: "",
        link: "",
        order: "",
        title: "",
        description: "",
        companyname: "",
        product: "",
        formdescription: "",
        formtext: "",
        formtext2: "",
        secondimg: "",
        secondtitle: "",
        seconddescription: "",
        visible: "false",
        abtest: "false",
        form: "false",
        formfields: {
          name: "false",
          phone: "false",
          email: "false",
          comment: "false",
          expectedDate: "false",
        },
      });
    }
  }, [apidata]);

  const save = () => {
    const selected = selectedItem;
    if (newCreate) {
      selected.id = Date.now().toString();
      const url = "/deals";
      dispatch(create(selected, url));
      setNew(false);
    } else {
      const url = "/deals/" + selectedItem.id;
      dispatch(update(selected, url));
    }
  };

  const numberoflarge = () => {
    const selected = { numberOfLargeAds: numberOfLargeAds };
    const url = "/config";
    dispatch(update(selected, url));
  };

  const deleteCategory = () => {
    const selected = selectedItem;
    const url = "/deals/" + selectedItem.id;
    const type = "coupons";
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  const renderDealForm = () => {
    return (
      <div className="mx-12">
        {(Object.keys(selectedItem).length > 0 || newCreate) && (
          <div>
            <TextField
              type="text"
              label="Id"
              name="id"
              value={selectedItem.id}
              disabled
            />
            <TextField
              type="text"
              label="Title"
              name="title"
              value={selectedItem.title}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.title = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Company name"
              name="companyname"
              value={selectedItem.companyname}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.companyname = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Product/Service"
              name="product"
              value={selectedItem.product}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.product = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextArea
              type="text"
              label="Description"
              name="description"
              value={selectedItem.description}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.description = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Link (should start with 'https://')"
              name="link"
              value={selectedItem.link}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.link = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <ImageUp
              title="Cover Image (ratio: 2 / 1.25)"
              parentCallback={() => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.img = "";
                setSelectedItem(selectedItemNew);
              }}
              value={selectedItem.img}
              getImg={(value) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.img = value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Order"
              name="order"
              value={selectedItem.order}
              onChange={(event) => {
                var selectedItemNew = { ...selectedItem };
                selectedItemNew.order = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <div className="flex items-start justify-start">
              <div className="w-1/3" />
              <Checkbox
                name="Visibility"
                selected={selectedItem.visible}
                getValue={(data) => {
                  var selectedItemNew = { ...selectedItem };
                  selectedItemNew.visible = data;
                  setSelectedItem(selectedItemNew);
                }}
              />
            </div>
            <div className="flex items-start justify-start">
              <div className="w-1/3" />
              <Checkbox
                name="AB Test"
                selected={selectedItem.abtest}
                getValue={(data) => {
                  var selectedItemNew = { ...selectedItem };
                  selectedItemNew.abtest = data;
                  setSelectedItem(selectedItemNew);
                }}
              />
            </div>
            {selectedItem.abtest === "true" && (
              <div>
                <TextField
                  type="text"
                  label="New (B) Title"
                  name="secondtitle"
                  value={selectedItem.secondtitle}
                  onChange={(event) => {
                    var selectedItemNew = { ...selectedItem };
                    selectedItemNew.secondtitle = event.target.value;
                    setSelectedItem(selectedItemNew);
                  }}
                />
                <TextArea
                  type="text"
                  label="New (B) Description"
                  name="seconddescription"
                  value={selectedItem.seconddescription}
                  onChange={(event) => {
                    var selectedItemNew = { ...selectedItem };
                    selectedItemNew.seconddescription = event.target.value;
                    setSelectedItem(selectedItemNew);
                  }}
                />
                <ImageUp
                  title="New (B) cover image (1120 x 744)"
                  parentCallback={() => {
                    var selectedItemNew = { ...selectedItem };
                    selectedItemNew.secondimg = "";
                    setSelectedItem(selectedItemNew);
                  }}
                  value={selectedItem.secondimg}
                  getImg={(value) => {
                    var selectedItemNew = { ...selectedItem };
                    selectedItemNew.secondimg = value;
                    setSelectedItem(selectedItemNew);
                  }}
                />
              </div>
            )}
            <div className="flex items-start justify-start">
              <div className="w-1/3" />
              <Checkbox
                name="Form visible"
                selected={selectedItem.form}
                getValue={(data) => {
                  var selectedItemNew = { ...selectedItem };
                  selectedItemNew.form = data;
                  setSelectedItem(selectedItemNew);
                }}
              />
            </div>
            {selectedItem.form === "true" && selectedItem.id && (
              <FormForm
                formtype="deals"
                sourceIdnow={selectedItem.id}
                categoryIdnow={null}
                parentCallback={() => setSelectedItem({})}
                selectedItem={selectedItem}
              />
            )}
            {selectedItem.form === "true" && !selectedItem.id && (
              <div>Please first save this item</div>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton
                red
                type="submit"
                className="w-1/4"
                onClick={() => deleteCategory()}
              >
                Delete
              </CustomButton>
              <div style={{ width: 20, height: 1 }} />
              <CustomButton
                type="submit"
                className="w-1/4"
                onClick={() => save()}
              >
                Save
              </CustomButton>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Layout active="deals">
      <div>
        <div className="w-2/3 mx-auto mt-12 ">
          <TextField
            type="text"
            label="Number of large Ads"
            name="numberoflargeads"
            value={numberOfLargeAds}
            onChange={(event) => {
              setNumberOfLargeAds(event.target.value);
            }}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomButton
              type="submit"
              className="w-1/4"
              onClick={() => numberoflarge()}
            >
              Save
            </CustomButton>
          </div>
        </div>
        <div className="w-2/3 mt-12 mx-auto mb-8 flex items-center justify-center">
          <TextField
            type="text"
            label="Search Company"
            name="companyname"
            value={term}
            onChange={(event) => setTerm(event.target.value)}
          />
        </div>
        <div className="flex justify-center w-full my-12">
          <div className="w-full mx-12 flex flex-wrap">
            <NewButton
              onClick={() => {
                setSelectedItem({
                  id: "",
                  img: "",
                  link: "",
                  order: "",
                  title: "",
                  description: "",
                  companyname: "",
                  product: "",
                  formdescription: "",
                  formtext: "",
                  formtext2: "",
                  secondimg: "",
                  secondtitle: "",
                  seconddescription: "",
                  visible: "false",
                  abtest: "false",
                  form: "false",
                  formfields: {
                    name: "false",
                    phone: "false",
                    email: "false",
                    comment: "false",
                    expectedDate: "false",
                  },
                });
                setNew(true);
              }}
              title="Add Deal"
            />
            {data &&
              data
                .filter(
                  (x) =>
                    (x.companyname &&
                      x.companyname
                        .toLowerCase()
                        .includes(term.toLowerCase())) ||
                    !term
                )
                .map((value, index) => (
                  <MenuButton
                    onClick={() => {
                      setWeek(value.id);
                      setSelectedItem({
                        id: value.id,
                        img: value.img ? value.img : "",
                        title: value.title ? value.title : "",
                        link: value.link ? value.link : "",
                        order: value.order ? value.order : "",
                        description: value.description ? value.description : "",
                        companyname: value.companyname ? value.companyname : "",
                        product: value.product ? value.product : "",
                        form: value.form ? value.form : "false",
                        formId: value.formId ? value.formId : "",
                        abtest: value.abtest ? value.abtest : "false",
                        visible: value.visible ? value.visible : "false",
                        secondimg: value.secondimg ? value.secondimg : "",
                        secondtitle: value.secondtitle ? value.secondtitle : "",
                        seconddescription: value.seconddescription
                          ? value.seconddescription
                          : "",
                      });
                    }}
                    active={week === value.id}
                    visible={value.visible ? value.visible : "false"}
                    title={
                      value.companyname +
                      " - " +
                      value.product +
                      " - " +
                      value.title +
                      " - " +
                      value.order
                    }
                  />
                ))}
          </div>
        </div>
        {renderDealForm()}
      </div>
    </Layout>
  );
};

export default Deals;
