import React from "react";
import plus from "../assets/plus.png";

const NewButton = ({ onClick, title }) => {
  return (
    <button
      onClick={() => onClick()}
      className="flex items-center m-2 px-2 rounded h-10 justify-center focus:outline-none bg-transparent"
    >
      <img alt="plus" style={{ height: 20, marginRight: 20 }} src={plus} />
      <p>{title}</p>
    </button>
  );
};

export default NewButton;
