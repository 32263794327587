import React, { Component } from "react";
import ImageUploader from "react-images-upload";
import firebase from "firebase/app";
import CustomButton from "./CustomButton";

import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage

export default class ImageUpUrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImageUrl: null,
      modal: "",
      imgToShow: null,
      imgHandle: "",
    };
    if (this.props.value !== "" && this.props.value !== null) {
      this.showImage(this.props.value);
    }
  }

  componentWillMount() {
    this.setState({ selectedImageUrl: null, imgToShow: null });
  }

  componentDidUpdate() {
    if (this.state.imgHandle !== "") {
      this.showImage(this.state.imgHandle);
    }
  }

  showImage(img) {
    firebase
      .storage()
      .ref()
      .child(img + ".jpg")
      .getDownloadURL()
      .then((res) => {
        this.setState({ selectedImageUrl: res });
        //alert(res)
      })
      .catch((err) => {
        //console.log(err);
        //alert(err)
        this.setState({ selectedImageUrl: "" });
      });
  }

  onImgSelect = (e, x, imgRole) => {
    if (e.length !== 0) {
      const fileToUpload = e[0];
      const newImgHandle = e[0].name.slice(0, -4);
      firebase
        .storage()
        .ref()
        .child(newImgHandle + ".jpg")
        .getDownloadURL()
        .then((res) => {
          alert(
            `This filename (${newImgHandle}) already exists in the online storage. If you upload it, it will overwrite the existing file. If you don't wish to overwrite anything, please rename your image to something unique and add it again with the 'Choose file' button before uploading.`
          );
        })
        //.catch(() /*console.log(err)*/)
        .finally(() => {
          this.setState({ imgToShow: fileToUpload, imgHandle: newImgHandle });
          //ezt a feltöltés után kell tenni!!!
          this.setState({
            selectedItem: {
              ...this.state.selectedItem,
              secondimg: newImgHandle,
            },
          });
        });
    }
  };

  onImgUpload = (imgRole) => {
    let meta = { contentType: "image/jpeg" };
    if (!this.state.imgToShow) {
      alert("No file is selected. Please select a file before uploading.");
      return;
    }
    let uploadTask;
    uploadTask = firebase
      .storage()
      .ref()
      .child(`${this.state.imgHandle}.jpg`)
      .put(this.state.imgToShow, meta);
    uploadTask.on(
      "state_changed",
      (snap) => {
        this.setState({
          modal: "Uploading image...",
        });
      },
      (error) => {
        this.setState({
          modal: "",
          imgToShow: null,
        });
        alert(
          `Upload failed. The database returned the following error: ${error}`
        );
        //console.log(error);
      },
      () => {
        this.setState({
          modal: "Upload successful!",
        });
        alert(this.state.selectedImageUrl);
        setTimeout(
          () =>
            this.setState(
              {
                modal: "",
                imgToShow: null,
              },
              this.showImage(this.state.imgHandle)
            ),
          2000
        );
      }
    );
  };

  render() {
    return (
      <div className="w-full flex flex-col items-center justify-center border border-grey rounded my-12 px-12">
        <div className="flex flex-row items-start justify-start w-full ">
          <div className="mt-12 text-lg w-1/4">Image uploader</div>
          <div className="w-2/3 flex flex-col items-center">
            {this.state.selectedImageUrl && (
              <img
                alt="img"
                className="w-1/3 h-auto mt-12"
                src={this.state.selectedImageUrl}
              />
            )}
            <div className="w-full">
              <ImageUploader
                withIcon={false}
                singleImage
                withPreview={this.state.imgToShow ? true : false}
                buttonText="Choose an Image"
                onChange={(e, x) => this.onImgSelect(e, x, "")}
                imgExtension={[".jpg"]}
                label="Max file size: 1MB, accepted: .jpg"
                maxFileSize={1242880}
              />
            </div>
            <p>{this.state.modal}</p>
            {this.state.imgToShow && (
              <CustomButton
                type="submit"
                className="w-1/4"
                onClick={() => this.onImgUpload()}
              >
                Upload
              </CustomButton>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center justify-center my-4 w-full">
          <div className="mr-4">Image URL: </div>
          <div className="border border-grey rounded p-4 w-full">
            {this.state.selectedImageUrl}
          </div>
        </div>
      </div>
    );
  }
}
