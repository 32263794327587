import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNotes, updateNotes } from "../redux/actions/settings";
import Layout from "../components/layout/Layout";
import CustomButton from "../components/CustomButton";
import HTMLEditor from "../components/HTMLEditor";

const AdminHelp = () => {
  const dispatch = useDispatch();
  const note = useSelector((state) => state.settings.note.text);
  const [newNote, setNewNote] = useState(note || "");

  useEffect(() => {
    if (note === "" || !note) {
      dispatch(getNotes());
    }
  }, []);

  useEffect(() => {
    setNewNote(note);
  }, [note]);

  return (
    <Layout active="help">
      <div className="mt-8 flex flex-col mx-12 items-end">
        <HTMLEditor
          dontshowImage
          defaultValue={note}
          onChange={(content) => setNewNote(content)}
          setContents={newNote}
        />
        <CustomButton
          type="submit"
          className="w-1/4"
          onClick={() => dispatch(updateNotes(newNote))}
        >
          Save
        </CustomButton>
      </div>
    </Layout>
  );
};

export default AdminHelp;
