/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getClickKnowledge,
  getClickArticle,
} from "../../../redux/actions/analytics";
import { getAllData } from "../../../redux/actions/api";
import { formatDate, newPercentage } from "../helper";
import Spinner from "../../Spinner";
import Dropdown from "../../Dropdown";

const KnowledgeClick = ({ startDate, endDate }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [allArray, setAllArray] = useState([]);
  const [articleFilteredArray, setArticleFilteredArray] = useState([]);

  const dispatch = useDispatch();
  const knowledgeloading = useSelector(
    (state) => state.analytics.clickknowledge_loading
  );
  const knowledge = useSelector((state) => state.analytics.clickknowledge);
  const knowledgedata = useSelector((state) => state.api.data.knowledge);

  const articleloading = useSelector(
    (state) => state.analytics.clickarticle_loading
  );
  const article = useSelector((state) => state.analytics.clickarticle);

  useEffect(() => {
    if (!knowledgedata) {
      dispatch(getAllData());
    }
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getClickKnowledge(
          formatDate(startDate),
          formatDate(endDate),
          limit,
          skip
        )
      );
    }
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getClickKnowledge(
          formatDate(startDate),
          formatDate(endDate),
          limit,
          skip
        )
      );
      dispatch(
        getClickArticle(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && selectedCategory !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getClickArticle(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, [startDate, endDate, selectedCategory]);

  useEffect(() => {
    if (knowledge && knowledge.screens && article && article.screens) {
      knowledge.screens.map((item) => {
        item.type = "intro";
      });
      article.screens.map((item) => {
        item.type = "article";
      });
      let filtered = knowledge.screens.concat(article.screens);
      newPercentage(filtered).then((element) => setAllArray(element));
    }
  }, [article, knowledge]);

  const KnowledgeCategoryOptions = () => {
    if (knowledgedata) {
      const articleselect = [{ value: "", label: "All" }];
      knowledgedata.forEach((item) => {
        articleselect.push({ value: item.id, label: item.title });
      });
      return articleselect;
    }
    return [];
  };

  useEffect(() => {
    if (
      startDate !== "" &&
      endDate !== "" &&
      selectedCategory !== "" &&
      article &&
      article.screens
    ) {
      let filtered = article.screens.filter(
        (item) => item.categoryId === selectedCategory
      );
      newPercentage(filtered).then((element) =>
        setArticleFilteredArray(element)
      );
    }
  }, [startDate, endDate, selectedCategory, article]);

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-1/2 mt-8">
        <Dropdown
          value={selectedCategory}
          options={KnowledgeCategoryOptions()}
          onChange={(value) => setSelectedCategory(value.value)}
        />
      </div>
      {knowledgeloading && <Spinner />}
      {!knowledgeloading && allArray.length !== 0 && selectedCategory === "" && (
        <div>
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Company</th>
                <th>Product/Service</th>
                <th>Screen</th>
                <th>Clicked</th>
                <th>Percentage (click / all click)</th>
                <th>Uniq</th>
              </tr>
            </thead>
            <tbody>
              {allArray.screens.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.type}</td>
                    <td>{item.companyname}</td>
                    <td>{item.product}</td>
                    <td>{item.screen}</td>
                    <td>{item.pressed}</td>
                    <td>{item.percentage} %</td>
                    <td>{item.userid.length}</td>
                  </tr>
                );
              })}
              <tr className="font-bold">
                <td>SUM</td>
                <td />
                <td />
                <td />
                <td>{allArray.total}</td>
                <td />
                <td>{allArray.uniq}</td>
            </tr>
            </tbody>
          </table>
        </div>
      )}
      {articleloading && <Spinner />}
      {!articleloading &&
        articleFilteredArray.length !== 0 &&
        selectedCategory !== "" && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Product/Service</th>
                  <th>Screen</th>
                  <th>Clicked</th>
                  <th>Percentage (click / all click)</th>
                  <th>Uniq</th>
                </tr>
              </thead>
              <tbody>
                {articleFilteredArray.screens.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.companyname}</td>
                      <td>{item.product}</td>
                      <td>{item.screen}</td>
                      <td>{item.pressed}</td>
                      <td>{item.percentage} %</td>
                      <td>{item.userid.length}</td>
                    </tr>
                  );
                })}
                <tr className="font-bold">
                <td>SUM</td>
                <td />
                <td />
                <td>{articleFilteredArray.total}</td>
                <td />
                <td>{articleFilteredArray.uniq}</td>
              </tr>
              </tbody>
            </table>
          </div>
        )}
    </div>
  );
};

export default KnowledgeClick;
