export const initialState = {
  token: "",
  error_401: false,
  interval: 0,
  deleteModalOpen: false,
  deleteType: "",
  deleteItem: "",
  deleteUrl: "",
  deleteTitle: "",
  dataupdated: 0,
};

const navigation = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_DELETEMODAL":
      return {
        ...state,
        deleteModalOpen: true,
        deleteType: action.data.type,
        deleteItem: action.data.item,
        deleteUrl: action.data.url,
        deleteTitle: action.data.title,
      };
    case "CLOSE_DELETEMODAL":
      return {
        ...state,
        deleteModalOpen: false,
        deleteType: "",
        deleteItem: "",
        deleteUrl: "",
        deleteTitle: "",
      };
    case "SET_INTERVAL":
      return {
        ...state,
        interval: action.data,
      };
    case "SAVE_TOKEN_FROM_COOKIE":
      return {
        ...state,
        token: action.data,
        error401: false,
      };
    case "LOGOUT":
      return {
        ...state,
        token: "",
      };
    case "401_ERROR":
      return {
        ...state,
        token: "",
        error401: true,
      };
    case "DATA_UPDATED":
      return {
        ...state,
        dataupdated: state.dataupdated + 1,
      };
    default:
      return state;
  }
};

export default navigation;
