/* eslint react/forbid-prop-types: 0 */
import React from "react";

const TextField = React.forwardRef(
  (
    {
      id,
      name,
      label,
      type,
      errors,
      groupClassName,
      fieldClassName,
      value,
      defaultValue,
      onChange,
      disabled,
    },
    ref
  ) => {
    const hasError = !!errors && !!errors[name];

    return (
      <div className="flex flex-col w-full my-1">
        <div
          className={`relative flex flex-row w-full items-center ${
            groupClassName || ""
          }`}
        >
          {label && (
            <label htmlFor={id || name} className={`w-1/3`}>
              {label}
            </label>
          )}
          <input
            name={name}
            id={id || name}
            type={type}
            ref={ref}
            disabled={disabled}
            onChange={onChange}
            value={value || ""}
            defaultValue={defaultValue || ""}
            className={`border-2 border-extralightorange border-solid rounded p-2 focus:border-2 focus:border-orange focus:outline-none 
            ${label ? "w-2/3" : "w-full"}
            ${disabled ? "bg-disabled" : "bg-white"}
            ${fieldClassName || ""}`}
          />
        </div>
        {hasError ? (
          <div className="text-red">{errors[name].message}</div>
        ) : null}
      </div>
    );
  }
);

export default TextField;
