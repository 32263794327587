import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRecipes, create, update, remove } from "../../redux/actions/tools";
import { openDeleteModal } from "../../redux/actions/state";
import { sortByVisibilityAndOrder } from "../../utils/sort";
import ImageUp from "../ImageUp";
import Checkbox from "../Checkbox";
import MenuButton from "../MenuButton";
import NewButton from "../NewButton";
import CustomButton from "../CustomButton";
import Spinner from "../Spinner";
import IntroForm from "../forms/IntroForm";
import TextField from "../TextField";
import HTMLEditor from "../HTMLEditor";

const Recipes = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.tools.recipes);
  const deleteType = useSelector((state) => state.state.deleteType);
  const [selectedItem2, setSelectedItem2] = useState({});
  const [htmlContent, setHtmlContent] = useState("");
  const [createtype, setCreateType] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data === [] || !data) {
      dispatch(getRecipes());
    }
  }, []);

  useEffect(() => {
    if (deleteType === "recipes") {
      setSelectedItem2({
        id: "",
        article: "",
        img: "",
        length: "",
        order: "",
        title: "",
        product: "",
        companyname: "",
        visible: false,
        abtest: false,
        secondtitle: "",
        secondimg: "",
      });
      setHtmlContent("");
      setOpenForm(false);
    }
  }, [data]);

  const createArticle = () => {
    if (createtype) {
      const selected = selectedItem2;
      selected.id = Date.now().toString();
      selected.article = htmlContent;
      const url = "/recipes";
      setSelectedItem2({
        id: "",
        article: "",
        img: "",
        length: "",
        order: "",
        title: "",
        product: "",
        companyname: "",
        visible: false,
        abtest: false,
        secondtitle: "",
        secondimg: "",
      });
      dispatch(create(selected, url, "recipes"));
      setHtmlContent("");
    } else {
      const selected = selectedItem2;
      const url = "/recipes/" + selected.id;
      selected.article = htmlContent;
      dispatch(update(selected, url, "recipes"));
    }
  };

  const deleteArticle = () => {
    const selected = selectedItem2;
    const url = "/recipes/" + selected.id;
    const type = "recipes";
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  const renderArticleMenu = () => {
    return (
      <div className="flex flex-row items-center wrap mx-12">
        <NewButton
          onClick={() => {
            setCreateType(true);
            setSelectedItem2({
              id: "",
              article: "",
              img: "",
              length: "",
              order: "",
              title: "",
              product: "",
              companyname: "",
              visible: false,
              abtest: false,
              secondtitle: "",
              secondimg: "",
            });
            setHtmlContent("");
            setOpenForm(true);
            setLoading(false);
            setTimeout(() => {
              setLoading(true);
            }, 500);
          }}
          title="Add Article"
        />
        {data &&
          data.data.sort(sortByVisibilityAndOrder()).map((value, index) => (
            <MenuButton
              key={index}
              onClick={() => {
                const datatorender = {
                  id: value.id,
                  article: value.article ? value.article : "",
                  img: value.img ? value.img : "",
                  length: value.length ? value.length : "",
                  order: value.order ? value.order : "",
                  product: value.product ? value.product : "",
                  companyname: value.companyname ? value.companyname : "",
                  title: value.title ? value.title : "",
                  visible: value.visible ? value.visible : "true",
                };
                setCreateType(false);
                setSelectedItem2(datatorender);
                setHtmlContent(value.article ? value.article : "");
                setOpenForm(true);
                setLoading(false);
                setTimeout(() => {
                  setLoading(true);
                }, 500);
              }}
              active={selectedItem2.id === value.id}
              title={value.title + " - " + value.order}
              visible={value.visible ? value.visible : "true"}
            />
          ))}
      </div>
    );
  };

  return (
    <div className="w-full">
      {data && <IntroForm intro={data.intro} />}
      <div className="flex flex-row justiy-around wrap w-full">
        {(!data || data.data.length === 0) && <Spinner />}
        {data.data.length !== 0 && (
          <div className="w-full">
            {renderArticleMenu()}
            {loading && openForm && (
              <div className="w-4/5 mx-12">
                <TextField
                  type="text"
                  label="Article Id"
                  name="id"
                  value={selectedItem2.id}
                  disabled
                />
                <TextField
                  type="text"
                  label="Title"
                  name="title"
                  value={selectedItem2.title}
                  onChange={(event) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.title = event.target.value;
                    setSelectedItem2(selectedItem);
                  }}
                />
                <TextField
                  type="text"
                  label="Order"
                  name="order"
                  value={selectedItem2.order}
                  onChange={(event) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.order = event.target.value;
                    setSelectedItem2(selectedItem);
                  }}
                />
                <ImageUp
                  title="Cover Image (ratio: 2 / 1)"
                  value={selectedItem2.img}
                  parentCallback={() => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.img = "";
                    setSelectedItem2(selectedItem);
                  }}
                  getImg={(value) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.img = value;
                    setSelectedItem2(selectedItem);
                  }}
                />
                <TextField
                  type="text"
                  label="Company name"
                  name="companyname"
                  value={selectedItem2.companyname}
                  onChange={(event) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.companyname = event.target.value;
                    setSelectedItem2(selectedItem);
                  }}
                />
                <TextField
                  type="text"
                  label="Product/Service"
                  name="product"
                  value={selectedItem2.product}
                  onChange={(event) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.product = event.target.value;
                    setSelectedItem2(selectedItem);
                  }}
                />
                <TextField
                  type="text"
                  label="Length (should be formatted az 'X perc')"
                  name="length"
                  value={selectedItem2.length}
                  onChange={(event) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.length = event.target.value;
                    setSelectedItem2(selectedItem);
                  }}
                />
                <Checkbox
                  name="Visibility"
                  selected={selectedItem2.visible}
                  getValue={(data) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.visible = data;
                    setSelectedItem2(selectedItem);
                  }}
                />
                <HTMLEditor
                  title="Article"
                  defaultValue={htmlContent}
                  onChange={(content) => {
                    setHtmlContent(content);
                  }}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {createtype && (
                    <CustomButton
                      type="submit"
                      className="w-1/5"
                      onClick={() => setSelectedItem2({})}
                    >
                      Cancel
                    </CustomButton>
                  )}
                  <div style={{ width: 20, height: 1 }} />

                  {!createtype && (
                    <CustomButton
                      red
                      type="submit"
                      className="w-1/5"
                      onClick={() => deleteArticle()}
                    >
                      Delete
                    </CustomButton>
                  )}
                  <div style={{ width: 20, height: 1 }} />
                  <CustomButton
                    type="submit"
                    className="w-1/5"
                    onClick={() => createArticle()}
                  >
                    Save
                  </CustomButton>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Recipes;
