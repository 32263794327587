import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserAll } from "../../../redux/actions/analytics";
import { formatDate } from "../helper";
import DateSelector from "../../DateSelector";
import Spinner from "../../Spinner";
import { CSVLink } from "react-csv";

const UserAll = () => {
  const dispatch = useDispatch();
  const allloading = useSelector((state) => state.analytics.userall_loading);
  const userall = useSelector((state) => state.analytics.userall);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getUserAll(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (data) {
      const data = userall.map((item) => item);
      data.forEach((item, index) => {
        item.registrations =
          index - 1 >= 0 && item.users - userall[index - 1].users;
      });
      setData(data);
    }
  }, [userall]);

  const headers = [
    { label: "Date", key: "date" },
    { label: "Number of all users", key: "users" },
    { label: "New users (registration)", key: "registrations" },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />
      {!allloading && data.length !== 0 && (
        <CSVLink
          className="bg-orange text-white p-2 rounded mb-4"
          filename={`UserAnalytics-ALL-${formatDate(startDate)}--${formatDate(
            endDate
          )}.csv`}
          data={data}
          headers={headers}
        >
          Download This table
        </CSVLink>
      )}
      <div className="mx-12">
        {allloading && <Spinner />}
        {!allloading && data.length !== 0 && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Number of all users</th>
                  <th>New users (registration)</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.date}</td>
                      <td>{item.users}</td>
                      <td>{item.registrations}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAll;
