import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeDeleteModal } from "../../redux/actions/state";
import { remove, update } from "../../redux/actions/api";

function DeleteModal() {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector((state) => state.state.deleteModalOpen);
  const item = useSelector((state) => state.state.deleteItem);
  const url = useSelector((state) => state.state.deleteUrl);
  const type = useSelector((state) => state.state.deleteType);
  const title = useSelector((state) => state.state.deleteTitle);

  return (
    <>
      {modalIsOpen && (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-opacity-80 bg-white">
          <div className="w-1/3 mx-auto bg-white mt-12 border border-orange rounded p-4">
            <h2 className="mt-4 text-xl text-red">Delete confirmation</h2>
            <div className="mt-4">
              Are you sure you want to delete this item?
            </div>
            <div className="mt-4">
              <strong>{title}</strong> from {type}
            </div>
            <div className="flex flex-row justify-around mt-6">
              <button
                className="py-1 px-2 bg-orange text-white rounded"
                onClick={() => dispatch(closeDeleteModal())}
              >
                Cancel
              </button>
              <button
                className="py-1 px-2 bg-red text-white rounded"
                onClick={() => {
                  dispatch(closeDeleteModal());
                  if (
                    type === "faq-item" ||
                    type === "foodsafety-article" ||
                    type === "todo-article" ||
                    type === "todo-item" ||
                    type === "knowledge-article"
                  ) {
                    dispatch(update(item, url));
                  } else {
                    dispatch(remove(item, url));
                  }
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DeleteModal;
