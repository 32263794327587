import React, { useState, useEffect } from "react";
import Select from "react-select";
import Dropdown from "../Dropdown";
import { filterByDate } from "../../utils/filter";

const AScreenName = ({ arr, startDate, endDate, loading }) => {
  const [condition, setCondition] = useState("");
  const [table, setTable] = useState([]);

  const options = [
    { value: "", label: "Not screen" },
    { value: "baby", label: "baby" },
    { value: "deals", label: "deals" },
    { value: "DealsFormscreen", label: "DealsFormscreen" },
    { value: "foodsafety", label: "foodsafety" },
    { value: "home", label: "home" },
    { value: "knowledge", label: "knowledge" },
    { value: "mum", label: "mum" },
    { value: "photographer", label: "photographer" },
    { value: "timer", label: "timer" },
    { value: "TimerScreen", label: "TimerScreen" },
    { value: "todo", label: "todo" },
    { value: "toolsscreen", label: "toolsscreen" },
    { value: "weightcurve", label: "weightcurve" },
  ];
  const defaultValue = () => {
    if (!condition) {
      return null;
    }
    let dValue;
    options.forEach((option) => {
      if (option.value === condition) {
        dValue = option;
      }
    });

    return dValue;
  };
  useEffect(() => {
    getTable();
  }, [condition]);

  useEffect(() => {
    getTable();
  }, [loading]);

  useEffect(() => {
    if (arr && arr.length !== 0) {
      getTable();
    }
  }, [arr]);

  const getTable = () => {
    if (arr && arr.length !== 0) {
      const filteredTable = [];
      arr.forEach((item) => {
        item.screens.forEach((e) => {
          if (e.screen === condition) {
            filteredTable.push({
              date: item.date,
              pressed: e.pressed,
              uniq: e.uniq,
              percentage: e.percentage,
            });
          }
        });
      });
      setTable(filteredTable);
    }
  };

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="mx-12">
        <div className="w-64">
          <Dropdown
            defaultValue={defaultValue()}
            placeholder="Choose one"
            options={options}
            onChange={(option) => {
              setCondition(option.value);
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        {table && condition !== "" && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>View</th>
                  <th>Percentage (view/all view)</th>
                  <th>Uniq</th>
                </tr>
              </thead>
              <tbody>
                {condition !== "" &&
                  table &&
                  filterByDate(table, startDate, endDate)
                    .sort((a, b) => a.date.localeCompare(b.date))
                    .reverse()
                    .map((f) => {
                      return (
                        <tr>
                          <td>{f.date}</td>
                          <td>{f.pressed}</td>
                          <td>
                            {f.percentage}
                            {"%"}
                          </td>
                          <td>{f.uniq}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
            {(startDate === "" || endDate === "") && condition !== "" && (
              <div>Please select a date range</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AScreenName;
