import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OLD_GETAnalyticsUserCount } from "../../redux/actions/oldanalytics";
import { formatDate } from "../analytics/helper";
import DateSelector from "../DateSelector";
import Spinner from "../Spinner";

const OldUserAll = () => {
  const dispatch = useDispatch();
  const allloading = useSelector((state) => state.oldanalytics.loading);  
  const userall = useSelector((state) => state.oldanalytics.analyticsusers);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        OLD_GETAnalyticsUserCount(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, [startDate, endDate]);

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />
      <div className="mx-12">
        {allloading && <Spinner />}
        {!allloading && userall.length !== 0 && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Number of all users</th>
                </tr>
              </thead>
              <tbody>
                {userall.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.date}</td>
                      <td>{item.users}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default OldUserAll;
