import axios from "axios";
import { oldApiUrl } from "../../config/settings";

// USER ANALYTICS
export const OLD_GETAnalyticsUserCount = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "OLD_SET_LOADING_TRUE" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
        .post(oldApiUrl + "/analyticsusers/query", 
          {
            from: from,
            to: to + 1,
            limit: limit,
            skip: i,
          },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          dispatch({ type: "OLD_GET_USER_COUNT", data: todos });
          dispatch({ type: "OLD_SET_LOADING_FALSE" });
          clearInterval(interval);
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const OLD_GETAnalyticsUserWeeks = () => (dispatch) => {
  dispatch({ type: "OLD_SET_LOADING_TRUE" });
  return axios
    .get(oldApiUrl + "/analyticsuserweeks", {
      headers: {
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      dispatch({ type: "OLD_GET_USER_WEEK_COUNT", data: response.data });
      dispatch({ type: "OLD_SET_LOADING_FALSE" });
    })
    .catch(function (error) {
      dispatch({ type: "OLD_SET_LOADING_FALSE" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

export const OLD_GETAnalyticsUsers = (from, to, limit, skip) => (dispatch) => {
  dispatch({ type: "OLD_SET_LOADING_TRUE" });
  let todos = [];
  let i = skip - limit;
  var interval = window.setInterval(async () => {
    i += limit;
    await axios
        .post(oldApiUrl + "/analyticsactives/query", 
          {
            from: from,
            to: to + 1,
            limit: limit,
            skip: i,
          },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length < limit) {
          dispatch({ type: "OLD_GET_ANALYTICS_ACTIVE", data: todos });
          dispatch({ type: "OLD_SET_LOADING_FALSE" });
          clearInterval(interval);
        }
      })
      .catch(function (error) {
        dispatch({ type: "ERROR", err: error });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

// LINKS
export const OLD_GETAnalyticsLinks = () => (dispatch) => {
  dispatch({ type: "OLD_SET_LOADING_TRUE" });
  return axios
    .get(oldApiUrl + "/analyticslinkknow", {
      headers: {
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      dispatch({ type: "OLD_GET_A_LINKS", data: response.data });
      dispatch({ type: "OLD_SET_LOADING_FALSE" });
    })
    .catch(function (error) {
      dispatch({ type: "OLD_GET_A_LINKS_ERROR" });
      dispatch({ type: "OLD_SET_LOADING_FALSE" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

// DEAL ANALYTICS
export const OLD_GETDeals = () => (dispatch) => {
  dispatch({ type: "OLD_SET_LOADING_TRUE" });
  let i = -20;
  let deals = [];
  var interval = window.setInterval(async () => {
    i = i + 20;
    await axios
      .post(
        oldApiUrl + "/analyticsdeals/query",
        {
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        deals.push(...response.data);
        if (response.data.length === 0) {
          dispatch({ type: "OLD_SET_LOADING_FALSE" });
          dispatch({ type: "OLD_GET_ANALYTICS_DEALS", data: deals });
          clearInterval(interval);
        }
      })
      .catch(function (error) {
        dispatch({ type: "OLD_SET_LOADING_FALSE" });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const OLD_GETDealsSum = () => (dispatch) => {
  axios
    .get(oldApiUrl + "/analyticsdealssum", {
      headers: {
        "Cache-Control": "no-cache",
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      response.data.forEach((item) => {
        if (item.type === "sum") {
          dispatch({ type: "OLD_GET_ANALYTICS_DEALS_SUM", data: item.deals });
        }
        if (item.type === "thirty") {
          dispatch({
            type: "OLD_GET_ANALYTICS_DEALS_THIRTY",
            data: item.deals,
          });
        }
        if (item.type === "seven") {
          dispatch({ type: "OLD_GET_ANALYTICS_DEALS_SEVEN", data: item.deals });
        }
      });
    })
    .catch(function (error) {
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

// SCREEN ANALYTICS
export const OLD_GETScreens = () => (dispatch) => {
  dispatch({ type: "OLD_SET_LOADING_TRUE" });
  let i = -20;
  let analytics = [];
  var interval = window.setInterval(async () => {
    i = i + 20;
    await axios
      .post(
        oldApiUrl + "/analyticsscreens/query",
        {
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        analytics.push(...response.data);
        if (response.data.length === 0) {
          dispatch({ type: "OLD_SET_LOADING_FALSE" });
          dispatch({ type: "OLD_GET_ANALYTICS_SCREENS", data: analytics });
          clearInterval(interval);
        }
      })
      .catch(function (error) {
        dispatch({ type: "OLD_SET_LOADING_FALSE" });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const OLD_GETScreensSum = () => (dispatch) => {
  axios
    .get(oldApiUrl + "/analyticsscreenssum", {
      headers: {
        "Cache-Control": "no-cache",
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      response.data.forEach((item) => {
        if (item.type === "sum") {
          dispatch({
            type: "OLD_GET_ANALYTICS_SCREENS_SUM",
            data: item.screens,
          });
        }
        if (item.type === "thirty") {
          dispatch({
            type: "OLD_GET_ANALYTICS_SCREENS_THIRTY",
            data: item.screens,
          });
        }
        if (item.type === "seven") {
          dispatch({
            type: "OLD_GET_ANALYTICS_SCREENS_SEVEN",
            data: item.screens,
          });
        }
      });
    })
    .catch(function (error) {
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

// TODO ANALYTICS
export const OLD_GETTodos = () => (dispatch) => {
  dispatch({ type: "OLD_SET_LOADING_TRUE" });
  let i = -20;
  let todos = [];
  var interval = window.setInterval(async () => {
    i = i + 20;
    await axios
      .post(
        oldApiUrl + "/analyticsscreenstodo/query",
        {
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        todos.push(...response.data);
        if (response.data.length === 0) {
          dispatch({ type: "OLD_SET_LOADING_FALSE" });
          dispatch({ type: "OLD_GET_ANALYTICS_TODO", data: todos });
          clearInterval(interval);
        }
      })
      .catch(function (error) {
        dispatch({ type: "OLD_SET_LOADING_FALSE" });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const OLD_GETTodoSum = () => (dispatch) => {
  axios
    .get(oldApiUrl + "/analyticsscreenstodosum", {
      headers: {
        "Cache-Control": "no-cache",
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      response.data.forEach((item) => {
        if (item.type === "sum") {
          dispatch({ type: "OLD_GET_ANALYTICS_TODO_SUM", data: item.screens });
        }
        if (item.type === "thirty") {
          dispatch({
            type: "OLD_GET_ANALYTICS_TODO_THIRTY",
            data: item.screens,
          });
        }
        if (item.type === "seven") {
          dispatch({
            type: "OLD_GET_ANALYTICS_TODO_SEVEN",
            data: item.screens,
          });
        }
      });
    })
    .catch(function (error) {
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

// KNOWLEDGE ANALYTICS
export const OLD_GETKnowledges = () => (dispatch) => {
  dispatch({ type: "OLD_SET_LOADING_TRUE" });
  let i = -20;
  let knowledges = [];
  var interval = window.setInterval(async () => {
    i = i + 20;
    await axios
      .post(
        oldApiUrl + "/analyticsscreensknowledge/query",
        {
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        knowledges.push(...response.data);
        if (response.data.length === 0) {
          dispatch({ type: "OLD_SET_LOADING_FALSE" });
          dispatch({ type: "OLD_GET_ANALYTICS_KNOW", data: knowledges });
          clearInterval(interval);
        }
      })
      .catch(function (error) {
        dispatch({ type: "OLD_SET_LOADING_FALSE" });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

export const OLD_GETKnowledgeSum = () => (dispatch) => {
  axios
    .get(oldApiUrl + "/analyticsscreensknowledgesum", {
      headers: {
        "Cache-Control": "no-cache",
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      response.data.forEach((item) => {
        if (item.type === "sum") {
          dispatch({ type: "OLD_GET_ANALYTICS_KNOW_SUM", data: item.screens });
        }
        if (item.type === "thirty") {
          dispatch({
            type: "OLD_GET_ANALYTICS_KNOW_THIRTY",
            data: item.screens,
          });
        }
        if (item.type === "seven") {
          dispatch({
            type: "OLD_GET_ANALYTICS_KNOW_SEVEN",
            data: item.screens,
          });
        }
      });
    })
    .catch(function (error) {
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

// ARTICLE ANALYTICS
export const OLD_GETArticles = () => (dispatch) => {
  dispatch({ type: "OLD_SET_ARTICLES_LOADING_TRUE" });
  let i = -20;
  let articles = [];
  var interval = window.setInterval(async () => {
    i = i + 20;
    await axios
      .post(
        oldApiUrl + "/analyticsarticles/query",
        {
          skip: i,
        },
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
          },
        }
      )
      .then((response) => {
        articles.push(...response.data);
        if (response.data.length === 0) {
          dispatch({ type: "OLD_SET_LOADING_FALSE" });
          dispatch({ type: "OLD_GET_ANALYTICS_ARTICLES", data: articles });
          clearInterval(interval);
        }
      })
      .catch(function (error) {
        dispatch({ type: "OLD_SET_LOADING_FALSE" });
        alert(error);
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: "401_ERROR" });
          }
        }
        return [];
      });
  }, 3000);
};

// POPUPS
export const OLD_GETAnalyticsPopups = () => (dispatch) => {
  dispatch({ type: "OLD_SET_LOADING_TRUE" });
  return axios
    .get(oldApiUrl + "/analyticspopups", {
      headers: {
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      dispatch({ type: "OLD_GET_POPUP_ANALYTICS", data: response.data });
      dispatch({ type: "OLD_SET_LOADING_FALSE" });
    })
    .catch(function (error) {
      dispatch({ type: "OLD_GET_POPUP_ANALYTIC_ERROR" });
      dispatch({ type: "OLD_SET_LOADING_FALSE" });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};

// AB TESTS
export const OLD_GETAbTests = () => (dispatch) => {
  return axios
    .OLD_GET(oldApiUrl + "/analyticsab", {
      headers: {
        Authorization: "Bearer G7LTL9FkkwFBOl7fSEjYQ5emxGcaNUY1",
      },
    })
    .then((response) => {
      dispatch({ type: "OLD_GET_AB_TESTS", data: response.data });
    })
    .catch(function (error) {
      dispatch({ type: "OLD_GET_AB_TESTS_ERROR" });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({ type: "401_ERROR" });
        }
      }
    });
};
