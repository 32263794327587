export const openDeleteModal =
  ({ selected, url, type, title }) =>
  (dispatch) => {
    dispatch({
      type: "OPEN_DELETEMODAL",
      data: { item: selected, url: url, type: type, title: title },
    });
  };

export const closeDeleteModal = () => (dispatch) => {
  dispatch({ type: "CLOSE_DELETEMODAL" });
};

export const setIntervals = (prop) => (dispatch) => {
  dispatch({ type: "SET_INTERVAL", data: prop });
};
export const saveTokenFromCookie = (token) => (dispatch) => {
  dispatch({ type: "SAVE_TOKEN_FROM_COOKIE", data: token });
};
export const logout = () => (dispatch) => {
  dispatch({ type: "LOGOUT" });
};

export const updatedData = () => (dispatch) => {
  dispatch({ type: "DATA_UPDATED" });
};
