import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserWeeks } from "../../../redux/actions/analytics";
import { formatDate } from "../helper";
import Spinner from "../../Spinner";
import { CSVLink } from "react-csv";

const UserWeeks = () => {
  const dispatch = useDispatch();
  const weeksloading = useSelector(
    (state) => state.analytics.userweeks_loading
  );
  const userweeks = useSelector((state) => state.analytics.userweeks);

  useEffect(() => {
    if (userweeks.length === 0) {
      dispatch(getUserWeeks());
    }
  }, []);

  const headers = [
    { label: "Week", key: "week" },
    { label: "Number of users", key: "users" },
    { label: "Birth", key: "birth" },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <div className="mx-12">
        {weeksloading && <Spinner />}
        {!weeksloading && (
          <div className="flex items-center justify-center w-full">
            <CSVLink
              className="bg-orange text-white p-2 rounded mb-4"
              filename={`UserAnalytics-WEEKS-${formatDate(new Date())}.csv`}
              data={userweeks}
              headers={headers}
            >
              Download This table
            </CSVLink>
          </div>
        )}
        {!weeksloading && userweeks.length !== 0 && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Week</th>
                  <th>Number of all users</th>
                  <th>Number of finished pregnancy</th>
                </tr>
              </thead>
              <tbody>
                {userweeks.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.week}</td>
                      <td>{item.users}</td>
                      <td>{item.birth ? item.birth : 0}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserWeeks;
