import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAppversion, updateAppversion } from "../redux/actions/settings";
import Layout from "../components/layout/Layout";
import TextField from "../components/TextField";
import CustomButton from "../components/CustomButton";

const AppVersion = () => {
  const dispatch = useDispatch();
  const appversion = useSelector((state) => state.settings.appversion);
  const [newAppversion, setNewAppversion] = useState(appversion || "");

  useEffect(() => {
    if (appversion === "" || !appversion) {
      dispatch(getAppversion());
    }
  }, []);

  useEffect(() => {
    setNewAppversion(appversion);
  }, [appversion]);

  return (
    <Layout active="appversion">
      <div className="mt-8 flex flex-col mx-12 items-end">
        <TextField
          type="text"
          label="App version"
          name="appversion"
          value={newAppversion}
          onChange={(e) => setNewAppversion(e.target.value)}
        />
        <CustomButton
          type="submit"
          className="w-1/4"
          onClick={() => dispatch(updateAppversion(newAppversion))}
        >
          Save
        </CustomButton>
      </div>
    </Layout>
  );
};

export default AppVersion;
