import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getCompliants,
  getPhotographers,
  getFaqs,
  getFoodsafeties,
  getRecipes,
  getVideos,
} from "../../redux/actions/tools";
import Layout from "../../components/layout/Layout";
import MenuButton from "../../components/MenuButton";
import FoodSafety from "../../components/tools/FoodSafety";
import Recipes from "../../components/tools/Recipes";
import Photographer from "../../components/tools/Photographer";
import Complaints from "../../components/tools/Complaints";
import FAQ from "../../components/tools/FAQ";
import Videos from "../../components/tools/Videos";

const Tools = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState("");

  useEffect(() => {
    setTab("");
    // dispatch(getAllData());
    dispatch(getCompliants());
    dispatch(getPhotographers());
    dispatch(getFaqs());
    dispatch(getFoodsafeties());
    dispatch(getRecipes());
    dispatch(getVideos());
  }, []);

  return (
    <Layout active="tools">
      <div className="flex flex-row mx-12 mt-8">
        <MenuButton
          onClick={() => {
            setTab("foodsafety");
          }}
          active={tab === "foodsafety"}
          title="Foodsafety"
          visible="true"
        />
        <MenuButton
          onClick={() => {
            setTab("photographer");
          }}
          active={tab === "photographer"}
          title="Photographer"
          visible="true"
        />
        <MenuButton
          onClick={() => {
            setTab("faq");
          }}
          active={tab === "faq"}
          title="FAQ"
          visible="true"
        />
        <MenuButton
          onClick={() => {
            setTab("complaints");
          }}
          active={tab === "complaints"}
          title="Complications"
          visible="true"
        />
        <MenuButton
          onClick={() => {
            setTab("recipes");
          }}
          active={tab === "recipes"}
          title="Recipes"
          visible="true"
        />
        <MenuButton
          onClick={() => {
            setTab("videos");
          }}
          active={tab === "videos"}
          title="Videos"
          visible="true"
        />
      </div>
      {tab === "foodsafety" && <FoodSafety />}
      {tab === "photographer" && <Photographer />}
      {tab === "complaints" && <Complaints />}
      {tab === "faq" && <FAQ />}
      {tab === "recipes" && <Recipes />}
      {tab === "videos" && <Videos />}
    </Layout>
  );
};

export default Tools;
