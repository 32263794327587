import React, { useEffect, useState } from "react";
import SunEditor from "suneditor-react";
import ImageUpUrl from "./ImageUpUrl";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  template,
  textStyle,
  image,
  video,
  link,
  audio,
  table,
  blockquote,
} from "suneditor/src/plugins";

const HTMLEditor = ({
  defaultValue,
  onChange,
  setContents,
  title,
  dontshowImage,
}) => {
  console.log("EDITOR" + defaultValue);
  //const [code, setCodeView] = useState(false);
  return (
    <div>
      {!dontshowImage && <ImageUpUrl />}
      <div>{title}</div>
      <SunEditor
        onChange={onChange}
        defaultValue={defaultValue}
        setDefaultStyle="font-family: Poppins-Regular;"
        //setContents={defaultValue}
        autoFocus={false}
        lang="en"
        width="100%"
        //toggleCodeView={setCodeView}
        setOptions={{
          showPathLabel: false,
          minHeight: "50vh",
          maxHeight: "50vh",
          placeholder: "Enter your text here!!!",
          plugins: [
            align,
            font,
            fontColor,
            fontSize,
            formatBlock,
            hiliteColor,
            horizontalRule,
            lineHeight,
            list,
            paragraphStyle,
            template,
            table,
            textStyle,
            image,
            video,
            link,
            audio,
            blockquote,
          ],
          buttonList: [
            ["undo", "redo", "preview", "codeView"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle", "blockquote"],
            ["underline", "italic", "strike", "subscript", "superscript"],
            ["fontColor", "hiliteColor"],
            ["removeFormat"],
            "/", // Line break
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["table", "link", "image", "video", "audio"],
          ],
          formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
          font: [
            "Poppins-Light",
            "Poppins-Regular",
            "Poppins-Bold",
            "Arial-Regular",
            "Arial-Bold",
            "Montserrat-Regular",
            "Montserrat-Bold",
          ],
          imageRotation: true,
          imageMultipleFile: false,
          colorList: [
            ["#ffffff", "#cccccc", "#999999", "#666666", "#333333", "#000000"],
            ["#F5F2EE", "#FEDDAE", "#FCC27B", "#666666", "#333333", "#000000"],
            ["#FF0000", "#800000", "#FFFF00", "#808000", "#00FF00", "#008000"],
            ["#00FFFF", "#008080", "#0000FF", "#000080", "#FF00FF", "#800080"],
          ],
        }}
      />
    </div>
  );
};

export default HTMLEditor;
