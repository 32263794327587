import React, { Component } from "react";
import ImageUploader from "react-images-upload";
import firebase from "firebase/app";
import CustomButton from "./CustomButton";

import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage

export default class ImageUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImageUrl: null,
      modal: "",
      imgToShow: null,
      imgHandle: this.props.value || "",
    };
    if (this.props.value !== "" && this.props.value !== null) {
      this.showImage(this.props.value);
    }
  }

  componentWillMount() {
    this.setState({ selectedImageUrl: null, imgToShow: null });
  }

  componentDidUpdate() {
    if (this.props.value !== "" && this.props.value !== null) {
      this.showImage(this.props.value);
    }
  }

  showImage(img) {
    firebase
      .storage()
      .ref()
      .child(img + ".jpg")
      .getDownloadURL()
      .then((res) => {
        this.setState({ selectedImageUrl: res });
      })
      .catch((err) => {
        //console.log(err);
        //alert(err)
        //this.setState({ selectedImageUrl: "" });
      });
  }

  onImgSelect = (e, x, imgRole) => {
    if (e.length !== 0) {
      const fileToUpload = e[0];
      const newImgHandle = e[0].name.slice(0, -4);
      firebase
        .storage()
        .ref()
        .child(newImgHandle + ".jpg")
        .getDownloadURL()
        .then((res) => {
          alert(
            `This filename (${newImgHandle}) already exists in the online storage. If you upload it, it will overwrite the existing file. If you don't wish to overwrite anything, please rename your image to something unique and add it again with the 'Choose file' button before uploading.`
          );
        })
        //.catch(() /*console.log(err)*/)
        .finally(() => {
          this.setState({ imgToShow: fileToUpload, imgHandle: newImgHandle });
          //ezt a feltöltés után kell tenni!!!
          this.setState({
            selectedItem: {
              ...this.state.selectedItem,
              secondimg: newImgHandle,
            },
          });
        });
    }
  };

  onImgUpload = (imgRole) => {
    let meta = { contentType: "image/jpeg" };
    if (!this.state.imgToShow) {
      alert("No file is selected. Please select a file before uploading.");
      return;
    }
    let uploadTask;
    uploadTask = firebase
      .storage()
      .ref()
      .child(`${this.state.imgHandle}.jpg`)
      .put(this.state.imgToShow, meta);
    uploadTask.on(
      "state_changed",
      (snap) => {
        this.setState({
          modal: "Uploading image...",
        });
      },
      (error) => {
        this.setState({
          modal: "",
          imgToShow: null,
        });
        alert(
          `Upload failed. The database returned the following error: ${error}`
        );
        //console.log(error);
      },
      () => {
        this.setState({
          modal: "Upload successful!",
        });
        this.props.getImg(this.state.imgHandle);
        setTimeout(
          () =>
            this.setState(
              {
                modal: "",
                imgToShow: null,
              },
              this.showImage(this.state.imgHandle)
            ),
          2000
        );
      }
    );
  };

  render() {
    return (
      <div className="w-full flex items-center justify-center">
        <div className="w-2/3 flex flex-col items-center">
          <div className="flex flex-row items-center justify-center my-4">
            <div>
              {this.props.title} {" - "}{" "}
            </div>
            <div>{this.props.value}</div>
          </div>
          {this.props.value && (
            <img
              alt="img"
              className="w-1/3 h-auto"
              src={this.state.selectedImageUrl}
            />
          )}
          <div className="w-full flex flex-row">
            <ImageUploader
              withIcon={false}
              singleImage
              withPreview={this.state.imgToShow ? true : false}
              buttonText="Choose an Image"
              onChange={(e, x) => this.onImgSelect(e, x, "")}
              imgExtension={[".jpg"]}
              label="Max file size: 1MB, accepted: .jpg"
              maxFileSize={1242880}
            />
            {this.props.value && (
              <div className="flex w-1/2 h-auto items-end justify-start mb-10 ml-2">
                <CustomButton
                  red
                  type="submit"
                  className="w-full"
                  onClick={() => this.props.parentCallback(this.props.title)}
                >
                  Delete Img
                </CustomButton>
              </div>
            )}
          </div>
          <p>{this.state.modal}</p>
          {this.state.imgToShow && (
            <CustomButton
              type="submit"
              className="w-1/4"
              onClick={() => this.onImgUpload()}
            >
              Upload
            </CustomButton>
          )}
        </div>
      </div>
    );
  }
}
