import React, { Component } from "react";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./navigators/routes";
import firebase from "firebase/app";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage
import rootReducer from "./redux/reducers/index";

const middlewares = [thunk];

const firebaseConfig = {
  apiKey: "AIzaSyD66QeIjlJa-9YmRm2fLCdSH4WGXWUFzWk",
  authDomain: "preg-app-1.firebaseapp.com",
  databaseURL: "https://preg-app-1.firebaseio.com",
  projectId: "preg-app-1",
  storageBucket: "preg-app-1.appspot.com",
  messagingSenderId: "575542549953",
  appId: "1:575542549953:web:777df1829a0326f8682340",
  measurementId: "G-FC8QS2Z62Y",
};

firebase.initializeApp(firebaseConfig);
const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
const store = createStoreWithMiddleware(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const script = document.createElement("script");
script.src = "https://apis.google.com/js/client.js";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Routes />
        </Router>
      </Provider>
    );
  }
}

export default App;
