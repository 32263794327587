/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getClickCoupons } from "../../../redux/actions/analytics";
import { formatDate } from "../helper";
import Spinner from "../../Spinner";

const CouponClick = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const couponloading = useSelector(
    (state) => state.analytics.clickcoupon_loading
  );
  const coupon = useSelector((state) => state.analytics.clickcoupon);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getClickCoupons(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      const skip = 0;
      const limit = 50;
      dispatch(
        getClickCoupons(formatDate(startDate), formatDate(endDate), limit, skip)
      );
    }
  }, [startDate, endDate]);

  return (
    <div className="w-full flex flex-col items-center">
      <div>
        {couponloading && <Spinner />}
        {!couponloading && coupon.length !== 0 && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Product/Service</th>
                  <th>Screen</th>
                  <th>Clicked</th>
                  <th>Percentage (click / all click)</th>
                  <th>Uniq</th>
                </tr>
              </thead>
              <tbody>
                {coupon.screens.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.companyname}</td>
                      <td>{item.product}</td>
                      <td>{item.screen}</td>
                      <td>{item.pressed}</td>
                      <td>{item.percentage} %</td>
                      <td>{item.userid.length}</td>
                    </tr>
                  );
                })}
                <tr className="font-bold">
                  <td>SUM</td>
                  <td />
                  <td />
                  <td>{coupon.total}</td>
                  <td />
                  <td>{coupon.uniq}</td>
              </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default CouponClick;
