import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { create, update, getFaqs } from "../../redux/actions/tools";
import { openDeleteModal } from "../../redux/actions/state";
import MenuButton from "../MenuButton";
import NewButton from "../NewButton";
import CustomButton from "../CustomButton";
import Spinner from "../Spinner";
import IntroForm from "../forms/IntroForm";
import TextField from "../TextField";
import TextArea from "../TextArea";
import { sortByOrder } from "../../utils/sort";

const FAQ = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.tools.faqs);
  const deleteType = useSelector((state) => state.state.deleteType);
  const [selectedItem3, setSelectedItem] = useState({});
  const [selectedItem2, setSelectedItem2] = useState({});
  const [createtype, setCreateType] = useState(false);
  const [type, setType] = useState("");

  useEffect(() => {
    if (data === [] || !data) {
      dispatch(getFaqs());
    }
  }, []);

  useEffect(() => {
    if (deleteType === "faq-header") {
      setSelectedItem2({
        id: "",
        title: "",
        summary: "",
        order: "",
      });
    }
    if (deleteType === "faq-item") {
      setSelectedItem({
        id: "",
        title: "",
        summary: "",
      });
      setSelectedItem2({
        id: "",
        title: "",
        summary: "",
        order: "",
      });
      setCreateType(true);
    }
  }, [data]);

  const createHeader = () => {
    const selected2 = selectedItem2;
    selected2.id = Date.now().toString();
    const url = "/faqs";
    setSelectedItem2({
      id: "",
      title: "",
      summary: "",
      order: "",
    });
    dispatch(create(selected2, url, "faq"));
  };

  const updateHeader = () => {
    const selected2 = selectedItem2;
    const url = "/faqs/" + selected2.id;
    dispatch(update(selected2, url, "faq"));
    setSelectedItem2({
      id: "",
      title: "",
      summary: "",
      order: "",
    });
  };

  const deleteHeader = () => {
    const selected = selectedItem2;
    const type = "faq-header";
    let url = `/faqs/${selectedItem2.id}`;
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  const updateItem = () => {
    const selected = selectedItem3;
    const selected2 = selectedItem2;
    selected2.data.forEach((e, i) => {
      if (e.id === selected.id) {
        selected2.data[i] = selected;
      }
    });
    const url = "/faqs/" + selected2.id;
    dispatch(update(selected2, url, "faq"));
    setSelectedItem({
      id: "",
      title: "",
      summary: "",
    });
    setSelectedItem2({
      id: "",
      title: "",
      summary: "",
      order: "",
    });
  };

  const createItems = () => {
    const selected = selectedItem3;
    const selected2 = selectedItem2;
    selected.id = Date.now().toString();
    selected2.data = [...selected2.data, selected];
    const url = "/faqs/" + selected2.id;
    dispatch(update(selected2, url, "faq"));
    setSelectedItem({
      id: "",
      title: "",
      summary: "",
    });
    setSelectedItem2({
      id: "",
      title: "",
      summary: "",
      order: "",
    });
  };

  const deleteItem = () => {
    const selected3 = selectedItem3;
    const selected = selectedItem2;
    selected.data = selected.data.filter((item) => item.id !== selected3.id);
    const url = "/faqs/" + selected.id;
    const type = "faq-item";
    const title = selected3.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  const renderHeaderMenu = () => {
    return (
      <div
        style={{
          width: "80%",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <NewButton
          onClick={() => {
            setCreateType(true);
            setSelectedItem2({
              id: "",
              title: "",
              summary: "",
              order: "",
              data: [],
            });
            setType("article");
          }}
          title="Add Category"
        />

        {data &&
          data.data.sort(sortByOrder()).map((value, index) => (
            <MenuButton
              key={index}
              onClick={() => {
                const datatorender = {
                  id: value.id,
                  title: value.title ? value.title : "",
                  order: value.order ? value.order : "",
                  data: value.data ? value.data : [],
                };
                setCreateType(false);
                setType("article");
                setSelectedItem2(datatorender);
              }}
              active={selectedItem2.id === value.id}
              title={value.title + " - " + value.order}
              visible="true"
            />
          ))}
      </div>
    );
  };

  const renderItemMenu = () => {
    return (
      <div
        style={{
          width: "80%",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <NewButton
          onClick={() => {
            setCreateType(true);
            setSelectedItem({
              id: "",
              title: "",
              summary: "",
            });
            setType("item");
          }}
          title="Add Article"
        />
        {selectedItem2 &&
          selectedItem2.data &&
          selectedItem2.data.length !== 0 &&
          selectedItem2.data.map((value, index) => (
            <MenuButton
              key={index}
              backgroundColor="blue"
              onClick={() => {
                const datatorender = {
                  id: selectedItem2.data[index].id,
                  title: selectedItem2.data[index].title
                    ? selectedItem2.data[index].title
                    : "",
                  summary: selectedItem2.data[index].summary
                    ? selectedItem2.data[index].summary
                    : "",
                };
                setCreateType(false);
                setType("item");
                setSelectedItem(datatorender);
              }}
              active={selectedItem3.id === value.id}
              title={value.title}
              visible="true"
            />
          ))}
      </div>
    );
  };

  return (
    <div>
      {data && <IntroForm intro={data.intro} />}
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "start",
        }}
      >
        {(!data || data.data.length === 0) && <Spinner />}
        {data.data.length !== 0 && (
          <div>
            <div
              style={{
                width: "100%",
                margin: 20,
                marginLeft: 100,
                marginRight: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {renderHeaderMenu()}
              {Object.keys(selectedItem2).length > 0 && renderItemMenu()}
            </div>
            {
              // HEADER
              (createtype || Object.keys(selectedItem2).length > 0) &&
                type === "article" && (
                  <div className="mx-12">
                    <TextField
                      type="text"
                      label="Category Id"
                      name="id"
                      value={selectedItem2.id}
                      disabled
                    />
                    <TextField
                      type="text"
                      label="Title"
                      name="title"
                      value={selectedItem2.title}
                      onChange={(event) => {
                        var selectedItem = { ...selectedItem2 };
                        selectedItem.title = event.target.value;
                        setSelectedItem2(selectedItem);
                      }}
                    />
                    <TextField
                      type="text"
                      label="Order"
                      name="order"
                      value={selectedItem2.order}
                      onChange={(event) => {
                        var selectedItem = { ...selectedItem2 };
                        selectedItem.order = event.target.value;
                        setSelectedItem2(selectedItem);
                      }}
                    />
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {!createtype && (
                        <CustomButton
                          red
                          type="submit"
                          className="w-1/5 mr-4"
                          onClick={() => {
                            deleteHeader();
                          }}
                        >
                          Delete
                        </CustomButton>
                      )}
                      <CustomButton
                        type="submit"
                        className="w-1/5"
                        onClick={() => {
                          if (createtype) {
                            createHeader();
                          } else {
                            updateHeader();
                          }
                        }}
                      >
                        Create
                      </CustomButton>
                    </div>
                  </div>
                )
            }
            {
              // ITEM
              (createtype || Object.keys(selectedItem3).length > 0) &&
                type === "item" && (
                  <div className="mx-12">
                    <TextField
                      type="text"
                      label="Article Id"
                      name="id"
                      value={selectedItem3.id}
                      disabled
                    />
                    <TextField
                      type="text"
                      label="Title"
                      name="title"
                      value={selectedItem3.title}
                      onChange={(event) => {
                        var selectedItem = { ...selectedItem3 };
                        selectedItem.title = event.target.value;
                        setSelectedItem(selectedItem);
                      }}
                    />
                    <TextArea
                      type="text"
                      label="Summary"
                      name="summary"
                      value={selectedItem3.summary}
                      onChange={(event) => {
                        var selectedItem = { ...selectedItem3 };
                        selectedItem.summary = event.target.value;
                        setSelectedItem(selectedItem);
                      }}
                    />
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {!createtype && (
                        <CustomButton
                          red
                          type="submit"
                          className="w-1/5 mr-4"
                          onClick={() => {
                            deleteItem();
                          }}
                        >
                          Delete
                        </CustomButton>
                      )}
                      <CustomButton
                        type="submit"
                        className="w-1/5"
                        onClick={() => {
                          if (createtype) {
                            createItems();
                          } else {
                            updateItem();
                          }
                        }}
                      >
                        Create
                      </CustomButton>
                    </div>
                  </div>
                )
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default FAQ;
