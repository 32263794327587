/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllData, getPopups, create, update } from "../../redux/actions/api";
import { openDeleteModal } from "../../redux/actions/state";
import ImageUp from "../../components/ImageUp";
import Checkbox from "../../components/Checkbox";
import MenuButton from "../../components/MenuButton";
import NewButton from "../../components/NewButton";
import CustomButton from "../../components/CustomButton";
import Spinner from "../../components/Spinner";
import TextField from "../../components/TextField";
import HTMLEditor from "../../components/HTMLEditor";
import Layout from "../../components/layout/Layout";
import Dropdown from "../../components/Dropdown";

const Popup = () => {
  const dispatch = useDispatch();
  const apidata = useSelector((state) => state.api.data);
  const data = useSelector((state) => state.api.popups);
  const deleteType = useSelector((state) => state.state.deleteType);
  const [selectedItem2, setSelectedItem2] = useState({});
  const [htmlContent, setHtmlContent] = useState("");
  const [html2Content, setHtml2Content] = useState("");
  const [createtype, setCreateType] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getPopups());
    dispatch(getAllData());
  }, []);

  useEffect(() => {
    if (deleteType === "popups") {
      console.log(deleteType);
      setSelectedItem2({
        id: "",
        img: "",
        url: "",
        buttonText: "",
        fromWeek: "",
        toWeek: "",
        startDate: "",
        endDate: "",
        seconds: "",
        companyname: "",
        product: "",
        placements: [],
        abtest: "",
        buttonText2: "",
        img2: "",
      });
      setHtmlContent("");
      setHtml2Content("");
      setOpenForm(false);
    }
  }, [data]);

  const createArticle = () => {
    if (createtype) {
      const selected = selectedItem2;
      selected.id = Date.now().toString();
      selected.html = htmlContent;
      selected.html2 = html2Content;
      const url = "/popups";
      setSelectedItem2({
        id: "",
        img: "",
        url: "",
        buttonText: "",
        fromWeek: "",
        toWeek: "",
        startDate: "",
        endDate: "",
        seconds: "",
        companyname: "",
        product: "",
        placements: [],
        abtest: "",
        buttonText2: "",
        img2: "",
      });
      setHtmlContent("");
      setHtml2Content("");
      dispatch(create(selected, url, "recipes"));
      setOpenForm(false);
    } else {
      const selected = selectedItem2;
      const url = "/popups/" + selected.id;
      selected.html = htmlContent;
      selected.html2 = html2Content;
      dispatch(update(selected, url, "recipes"));
      setOpenForm(false);
    }
  };

  const deleteArticle = () => {
    const selected = selectedItem2;
    const url = "/popups/" + selected.id;
    const type = "popups";
    const title = `${selected.companyname}-${selected.startDate}-${selected.endDate}`;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  const WeekOptions = () => {
    const weekselect = [];
    for (let index = 0; index < 42; index++) {
      weekselect.push({ value: index.toString(), label: index.toString() });
    }
    return weekselect;
  };

  const KnowledgeOptions = () => {
    const articleselect = [];
    apidata.knowledge.forEach((item) => {
      articleselect.push({ value: item.id, label: item.title });
    });
    return articleselect;
  };

  const ArticleOptions = () => {
    const articleselect = [];
    apidata.knowledge.forEach((item) => {
      item.articles.forEach((e) => {
        articleselect.push({ value: e.id, label: e.title });
      });
    });
    return articleselect;
  };

  const renderArticleMenu = () => {
    return (
      <div className="flex flex-row items-center flex-wrap mx-12">
        <NewButton
          onClick={() => {
            setCreateType(true);
            setSelectedItem2({
              id: "",
              img: "",
              buttonText: "",
              fromWeek: "",
              toWeek: "",
              startDate: "",
              endDate: "",
              seconds: "",
              companyname: "",
              product: "",
              placements: [],
              abtest: "",
              buttonText2: "",
              img2: "",
            });
            setHtmlContent("");
            setHtml2Content("");
            setOpenForm(true);
            setLoading(false);
            setTimeout(() => {
              setLoading(true);
            }, 500);
          }}
          title="Add Popup"
        />
        {data &&
          data.map((value, index) => (
            <MenuButton
              key={index}
              onClick={() => {
                const datatorender = {
                  id: value.id,
                  img: value.img ? value.img : "",
                  buttonText: value.buttonText ? value.buttonText : "",
                  fromWeek: value.fromWeek ? value.fromWeek : "",
                  toWeek: value.toWeek ? value.toWeek : "",
                  startDate: value.startDate ? value.startDate : "",
                  endDate: value.endDate ? value.endDate : "",
                  seconds: value.seconds ? value.seconds : "",
                  companyname: value.companyname ? value.companyname : "",
                  product: value.product ? value.product : "",
                  placements: value.placements ? value.placements : "",
                  abtest: value.abtest ? value.abtest : false,
                  buttonText2: value.buttonText2 ? value.buttonText2 : "",
                  img2: value.img2 ? value.img2 : "",
                };
                setCreateType(false);
                setSelectedItem2(datatorender);
                setHtmlContent(value.html ? value.html : "");
                setHtml2Content(value.html2 ? value.html2 : "");
                setOpenForm(true);
                setLoading(false);
                setTimeout(() => {
                  setLoading(true);
                }, 500);
              }}
              active={selectedItem2.id === value.id}
              title={
                value.companyname +
                " - " +
                value.startDate +
                " - " +
                value.endDate
              }
              visible="true"
            />
          ))}
      </div>
    );
  };

  const renderPlacements = () => {
    return (
      <div className="mt-8">
        <div className="text-xl">Placements</div>
        {selectedItem2.placements &&
          selectedItem2.placements.map((item, index) => {
            return (
              <div className="mb-8">
                <div className="w-full flex flex-row items-center justify-center mb-2">
                  <div className="w-1/3">Placement type</div>
                  <div className="w-2/3 flex flex-row items-center">
                    <div className="w-full">
                      <Dropdown
                        value={item.type}
                        options={[
                          { value: "home", label: "Home Main" },
                          {
                            value: "home_baby",
                            label: "Home - Calendar / Baby",
                          },
                          { value: "home_mum", label: "Home - Calendar / Mum" },
                          { value: "knowledge", label: "Knowledge Main" },
                          {
                            value: "knowledge_category",
                            label: "Knowledge Category",
                          },
                          {
                            value: "knowledge_article",
                            label: "Knowledge Article",
                          },
                          { value: "tools", label: "Tools Main" },
                          { value: "tools_category", label: "Tools Category" },
                          { value: "todo", label: "Todo Main" },
                        ]}
                        onChange={(value) => {
                          var selectedItem = { ...selectedItem2 };
                          selectedItem.placements[index].type = value.value;
                          setSelectedItem2(selectedItem);
                        }}
                      />
                    </div>
                    <CustomButton
                      red
                      type="submit"
                      className="w-full"
                      onClick={() => {
                        var selectedItem = { ...selectedItem2 };
                        selectedItem.placements.splice(index, 1);
                        setSelectedItem2(selectedItem);
                      }}
                    >
                      Remove
                    </CustomButton>
                  </div>
                </div>
                {(item.type === "home_baby" || item.type === "home_mum") && (
                  <div className="w-full flex flex-row items-center justify-center mb-2">
                    <div className="w-1/3">Placement Week</div>
                    <div className="w-2/3">
                      <Dropdown
                        value={item.placeId}
                        options={WeekOptions()}
                        onChange={(value) => {
                          var selectedItem = { ...selectedItem2 };
                          selectedItem.placements[index].placeId = value.value;
                          setSelectedItem2(selectedItem);
                        }}
                      />
                    </div>
                  </div>
                )}
                {(item.type === "knowledge_article" ||
                  item.type === "knowledge_category") && (
                  <div className="w-full flex flex-row items-center justify-center my-2">
                    <div className="w-1/3">Placement ID</div>
                    <div className="w-2/3">
                      <Dropdown
                        value={item.placeId}
                        options={
                          item.type === "knowledge_article"
                            ? ArticleOptions()
                            : KnowledgeOptions()
                        }
                        onChange={(value) => {
                          var selectedItem = { ...selectedItem2 };
                          selectedItem.placements[index].placeId = value.value;
                          setSelectedItem2(selectedItem);
                        }}
                      />
                    </div>
                  </div>
                )}
                {item.type === "tools_category" && (
                  <div className="w-full flex flex-row items-center justify-center mb-2">
                    <div className="w-1/3">Tool placement</div>
                    <div className="w-2/3">
                      <Dropdown
                        value={item.placeId}
                        options={[
                          { value: "compliants", label: "Complications" },
                          { value: "diary", label: "Diary" },
                          { value: "faq", label: "FAQ" },
                          { value: "foodsafety", label: "Food safety" },
                          { value: "photographers", label: "Photographers" },
                          { value: "recipes", label: "Recipes" },
                          { value: "timer", label: "Timer" },
                          { value: "videos", label: "Videos" },
                          { value: "weightcurve", label: "Weight Curve" },
                        ]}
                        onChange={(value) => {
                          var selectedItem = { ...selectedItem2 };
                          selectedItem.placements[index].placeId = value.value;
                          setSelectedItem2(selectedItem);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        <CustomButton
          type="submit"
          className="w-full"
          onClick={() => {
            var selectedItem = { ...selectedItem2 };
            if (selectedItem.placements) {
              selectedItem.placements.push({ type: "", placeId: "" });
            } else {
              selectedItem.placements = [{ type: "", placeId: "" }];
            }
            setSelectedItem2(selectedItem);
          }}
        >
          Add new placement
        </CustomButton>
      </div>
    );
  };

  return (
    <Layout>
      <div className="flex flex-row justiy-around wrap w-full">
        {(!data || data.length === 0) && <Spinner />}
        {data && (
          <div className="w-full">
            {renderArticleMenu()}
            {loading && openForm && (
              <div className="w-4/5 mx-12">
                <TextField
                  type="text"
                  label="Popup Id"
                  name="id"
                  value={selectedItem2.id}
                  disabled
                />
                <ImageUp
                  title="Popup Image (ratio: 2 / 1.5)"
                  value={selectedItem2.img}
                  parentCallback={() => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.img = "";
                    setSelectedItem2(selectedItem);
                  }}
                  getImg={(value) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.img = value;
                    setSelectedItem2(selectedItem);
                  }}
                />
                <HTMLEditor
                  title="Html"
                  defaultValue={htmlContent}
                  onChange={(content) => {
                    setHtmlContent(content);
                  }}
                />
                <TextField
                  type="text"
                  label="Link"
                  name="url"
                  value={selectedItem2.url}
                  onChange={(event) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.url = event.target.value;
                    setSelectedItem2(selectedItem);
                  }}
                />
                <TextField
                  type="text"
                  label="Button Text"
                  name="buttonText"
                  value={selectedItem2.buttonText}
                  onChange={(event) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.buttonText = event.target.value;
                    setSelectedItem2(selectedItem);
                  }}
                />
                <div className="w-full flex flex-row items-center justify-center mb-2">
                  <div className="w-1/3">From (Week)</div>
                  <div className="w-2/3">
                    <Dropdown
                      value={selectedItem2.fromWeek}
                      options={WeekOptions()}
                      onChange={(value) => {
                        var selectedItem = { ...selectedItem2 };
                        selectedItem.fromWeek = value.value;
                        setSelectedItem2(selectedItem);
                      }}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-row items-center justify-center mb-2">
                  <div className="w-1/3">To (Week)</div>
                  <div className="w-2/3">
                    <Dropdown
                      value={selectedItem2.toWeek}
                      options={WeekOptions()}
                      onChange={(value) => {
                        var selectedItem = { ...selectedItem2 };
                        selectedItem.toWeek = value.value;
                        setSelectedItem2(selectedItem);
                      }}
                    />
                  </div>
                </div>
                <TextField
                  type="text"
                  label="Start Date (fe. 2021-01-01)"
                  name="startDate"
                  value={selectedItem2.startDate}
                  onChange={(event) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.startDate = event.target.value;
                    setSelectedItem2(selectedItem);
                  }}
                />
                <TextField
                  type="text"
                  label="End Date (fe. 2021-01-01)"
                  name="endDate"
                  value={selectedItem2.endDate}
                  onChange={(event) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.endDate = event.target.value;
                    setSelectedItem2(selectedItem);
                  }}
                />
                <TextField
                  type="number"
                  label="Seconds (in number)"
                  name="seconds"
                  value={selectedItem2.seconds}
                  onChange={(event) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.seconds = event.target.value;
                    setSelectedItem2(selectedItem);
                  }}
                />
                <TextField
                  type="text"
                  label="Company name"
                  name="companyname"
                  value={selectedItem2.companyname}
                  onChange={(event) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.companyname = event.target.value;
                    setSelectedItem2(selectedItem);
                  }}
                />
                <TextField
                  type="text"
                  label="Product/Service"
                  name="product"
                  value={selectedItem2.product}
                  onChange={(event) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.product = event.target.value;
                    setSelectedItem2(selectedItem);
                  }}
                />
                {renderPlacements()}
                <Checkbox
                  name="Abtest"
                  selected={selectedItem2.abtest}
                  getValue={(data) => {
                    var selectedItem = { ...selectedItem2 };
                    selectedItem.abtest = data;
                    setSelectedItem2(selectedItem);
                  }}
                />
                {selectedItem2.abtest && (
                  <div>
                    <ImageUp
                      title="B - Image (820 x 460)"
                      value={selectedItem2.img2}
                      parentCallback={() => {
                        var selectedItem = { ...selectedItem2 };
                        selectedItem.img2 = "";
                        setSelectedItem2(selectedItem);
                      }}
                      getImg={(value) => {
                        var selectedItem = { ...selectedItem2 };
                        selectedItem.img2 = value;
                        setSelectedItem2(selectedItem);
                      }}
                    />
                    <HTMLEditor
                      title="B - Html"
                      defaultValue={html2Content}
                      onChange={(content) => {
                        setHtml2Content(content);
                      }}
                    />
                    <TextField
                      type="text"
                      label="B - Button Text"
                      name="buttonText2"
                      value={selectedItem2.buttonText2}
                      onChange={(event) => {
                        var selectedItem = { ...selectedItem2 };
                        selectedItem.buttonText2 = event.target.value;
                        setSelectedItem2(selectedItem);
                      }}
                    />
                  </div>
                )}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {createtype && (
                    <CustomButton
                      type="submit"
                      className="w-1/5"
                      onClick={() => setSelectedItem2({})}
                    >
                      Cancel
                    </CustomButton>
                  )}
                  <div style={{ width: 20, height: 1 }} />

                  {!createtype && (
                    <CustomButton
                      red
                      type="submit"
                      className="w-1/5"
                      onClick={() => deleteArticle()}
                    >
                      Delete
                    </CustomButton>
                  )}
                  <div style={{ width: 20, height: 1 }} />
                  <CustomButton
                    type="submit"
                    className="w-1/5"
                    onClick={() => createArticle()}
                  >
                    Save
                  </CustomButton>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Popup;
