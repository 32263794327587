import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const Dropdown = ({ value, options, onChange, disabled }) => {
  return (
    <Select
      value={options.filter((option) => option.value === value)}
      onChange={onChange}
      options={options}
      isDisabled={disabled}
      styles={customStyles}
    />
  );
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "black",
    backgroundColor: !state.isSelected ? "white" : "#edcc9f",
    "&:hover": {
      backgroundColor: "#F5F2EE",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    height: "2.5rem",
    "&:hover": {
      borderColor: "#FCC27B",
    }, // border style on hover
    border: "2px solid lightgray",
    boxShadow: "none",
  }),
  menu: (provided, state) => ({
    ...provided,
    flex: 1,
    padding: 0,
    marginTop: "0.25rem",
    borderRadius: "0.25rem",
    boxShadow: `0 0 1px rgba(0, 0, 0, 0.25), 0 2px 10px rgba(0, 0, 0, 0.25)`,
    color: "orange",
    borderColor: !state.isFocused ? `grey` : `#F5F2EE`,
  }),
};

Dropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  value: "",
  onChange: () => {},
  options: [],
  disabled: false,
};

export default Dropdown;
