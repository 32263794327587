import React from "react";
import DatePicker from "react-datepicker";

const DateSelector = ({
  startDate,
  endDate,
  startDateChanged,
  endDateChanged,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginBottom: 20,
        marginTop: 50,
      }}
    >
      <DatePicker
        className="border border-black"
        dateFormat="yyyy.MM.dd"
        selected={startDate}
        onChange={(date) => startDateChanged(date)}
      />
      <div style={{ width: 20 }} />
      <DatePicker
        className="border border-black"
        dateFormat="yyyy.MM.dd"
        selected={endDate}
        onChange={(date) => {
          date.setHours(23, 59, 59, 999);
          endDateChanged(date);
        }}
      />
    </div>
  );
};

export default DateSelector;
